import React/*, { Component }*/ from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import { /*ToastContainer,*/ toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import filterFactory from "react-bootstrap-table2-filter";
import TableSearch from "../../../Common/Components/TableSearch";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Button, Dropdown, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import chevronRight from "../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import { FiChevronDown } from "react-icons/fi";
import { resolve, reject } from "q";
import { InvoiceApprovalS } from "../../../Common/Services/InvoiceApprovals";
import caught from "../../../Common/Assets/Images/icons/all-caught.png";
import invoice_inv from "../../../Common/Assets/Images/invoice_inv.png";
import { Utils } from "../../../Common/Utilis";
import { InvoiceEntrySlideout } from "../../Register/InvoiceEntrySlideout";
// import { CSVLink, CSVDownload } from "react-csv";
import { ConfirmationModal } from "../../../Common/Components/ConfirmationModal";
import { LaborPerformance as laborPerformance } from "../../../Common/Services/LaborPerfomance";
import _ from "lodash";
import { confirmAlert } from "react-confirm-alert";
import { User } from "../../../Common/Services/User";
// import moment from "moment";
import OutsideClickHandler from "react-outside-click-handler";
import { VendorDetailSlideOut } from "../Vendor/components/VendorDetails";


// let exportDataSet: any = [];
// let openRow: any = {}
// let isAlert: any;
export class PendingVendorApproval extends React.Component<any, any> {
    private child: any;
    private wom_vendorWidth: any;
    private scrollArea: any;

    iconFormatter = (column, colIndex) => {
        return (
            <div className="wom_hasGearIcon">
                <Dropdown className="more-action bg-blue rolePermissiondropdown dropdown wom_dropdown" onToggle={this.handleToggle}>
                    <Dropdown.Toggle id="dropdown-ptoType" className="pl-1 pr-1" disabled={this.state.tableData.length === 0}>
                        <div className="d-flex align-items-center wom_svg">
                            <div className="d-flex align-items-center wom_svg">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.3914 2H3.60861C2.72146 2 2 2.79733 2 3.77778V16.2222C2 17.2027 2.72146 18 3.60861 18H16.3914C17.2785 18 18 17.2027 18 16.2222V6.44356V5.55467V4.66578V3.77778V3.77689C17.9992 2.79644 17.2769 2 16.3914 2ZM6.82582 16.2222H3.60861V6.44356H6.82582V16.2222ZM11.6516 16.2222H8.43442V6.44356H11.6516V16.2222ZM13.2602 16.2222V6.44356H16.3914L16.3922 16.2222H13.2602Z" />
                                </svg>
                                <div className="drop-arrow d-flex">
                                    <FiChevronDown />
                                </div>
                            </div>
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="wom_tableColumnListSelector" id="colList3">
                        <div className="header">Columns</div>
                        <div className="dropdownItems">
                            {this.state.tableColumnList.map((items, index) => (
                                <div className="dropdown-item" key={index}>
                                    <EllipsisWithTooltip placement="bottom">
                                        <div className="wom_formCheck wom_formCheckHasPadd">
                                            <Form.Check
                                                type="checkbox"
                                                id={`list_${items.id}`}
                                                name={items.name.toLowerCase()}
                                                label={items.name === "AP" ? "Accounting Period" : items.name}
                                                value={items.id}
                                                onChange={(event) =>
                                                    this.onChangeStatus(event, items, index)
                                                }
                                                checked={items.isChecked}
                                                disabled={items.disabled}
                                            />
                                        </div>
                                    </EllipsisWithTooltip>
                                </div>
                            ))}
                        </div>
                        {/* <div className="m-0 dropdown-divider"></div> */}
                        {/* <Dropdown.Item className="wom_saveDefaultBtn" onClick={this.handleSaveColumn}>Save as Default View</Dropdown.Item> */}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    }
    handleToggle = (event) => {
        if (event === false) {
            let defaultWidth = this.scrollArea.current.getBoundingClientRect().width;
            let currentWidth = this.scrollArea.current.childNodes[0].childNodes[0].childNodes[0].getBoundingClientRect().width;
            if (defaultWidth < currentWidth) {
                confirmAlert({
                    title: "Horizontal Scroll Alert",
                    message: "Column selection has caused horizontal scrolling.",
                    buttons: [{
                        label: "Ok", onClick: () => {
                            //this.setActiveColumn(index, true)
                        }
                    }],
                })
            }
        }
    }

    renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            <div className="rowAiApprovals">
                <div className="rowAiHeader">Approvals:</div>
                <ul>{props.isHoverd.map((items, idx) => (<li key={idx}>{items.role}</li>))}</ul>
            </div>
        </Tooltip>
    );

    hoverOutEllipsis = () => {
        $(".tooltip").removeClass("show");
    };

    // columnsHeader1 = [{
    //     dataField: "vendor",
    //     text: "Vendor",
    //     headerFormatter: (column, colIndex) => {
    //         return (
    //             <div>{column.text}</div>
    //         );
    //     },
    //     formatter: (cell: any, row: any, rowIndex: any) => {
    //         return (<div onClick={() => { this.rowClickEvents(row) }}>
    //             <EllipsisWithTooltip placement="bottom">{row.vendor}</EllipsisWithTooltip>
    //         </div>)
    //     },
    //     headerClasses: "wom_colVendor",
    //     classes: "stickey-vendor",
    // }, {
    //     dataField: "lettercode",
    //     text: "EHID",
    //     hidden: this.props.hidValue.toString().toLowerCase() === "select" || this.props.hidValue.toString().toLowerCase() === "all" ? false : true,
    //     formatter: (cell: any, row: any, rowIndex: any) => {
    //         return (
    //             <div onClick={() => { this.rowClickEvents(row) }}>                   
    //                 <EllipsisWithTooltip placement="bottom">{row.lettercode}</EllipsisWithTooltip>
    //             </div>)
    //     },
    //     headerClasses: "common-width ehid",
    // }, {
    //     dataField: "createdBy",
    //     text: "CreatedBy",
    //     formatter: (cell: any, row: any, rowIndex: any) => {
    //         return (
    //             <div onClick={() => { this.rowClickEvents(row) }}>
    //                 <EllipsisWithTooltip placement="bottom">{row.createdBy}</EllipsisWithTooltip>
    //             </div>)
    //     },
    //     headerClasses: "common-width",
    // }, {
    //     dataField: "createdDate",
    //     text: "Created Date",
    //     formatter: (cell: any, row: any, rowIndex: any) => {
    //         return (
    //             <div onClick={() => { this.rowClickEvents(row) }}>
    //                 <EllipsisWithTooltip placement="bottom">{row.createdDate}</EllipsisWithTooltip>
    //             </div>
    //         )
    //     },
    //     headerClasses: "common-width",
    // },

    // {
    //     dataField: "",
    //     text: "",
    //     //classes: (cell: any, row: any, rowIndex: any) => `wom_colBtn text-right ${row.isSpinnerLoader ? "setZindex" : ""}`,
    //     //headerClasses: "wom_colBtn text-right",
    //     // headerFormatter: this.iconFormatter,
    //     formatter: (cell: any, row: any, rowIndex: any) => {

    //         return (
    //             <div className='d-flex justify-content-end'>
    //                 <Dropdown className="more-action wom_hasDropShadow" alignRight>
    //                     <Dropdown.Toggle
    //                         className="isNoBtn stickyBand"
    //                         variant="success" id="dropdown-split-basic">
    //                         <Button disabled={row.isSpinnerLoader} onClick={(e: any) => this.showCheckApprovalModal(row, "row")}> Approve</Button>
    //                     </Dropdown.Toggle>

    //                 </Dropdown>
    //                 <div className='confirmation'>
    //                     <Dropdown className="more-action deny-approval" alignRight>
    //                         <Dropdown.Toggle
    //                             className="btn-outline-primary btn btn-primary more"
    //                             id="dropdown-more"
    //                         >
    //                             <svg
    //                                 width="20"
    //                                 height="20"
    //                                 viewBox="0 0 20 20"
    //                                 fill="none"
    //                                 xmlns="http://www.w3.org/2000/svg"
    //                             >
    //                                 <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
    //                                 <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
    //                                 <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
    //                             </svg>
    //                         </Dropdown.Toggle>
    //                         <Dropdown.Menu>
    //                             <Dropdown.Item onClick={(e: any) => this.denyApproval(row, "row")} > Deny </Dropdown.Item>
    //                         </Dropdown.Menu>
    //                     </Dropdown>
    //                     {row.isDeny && (
    //                         <ConfirmationModal
    //                             confirmModalContent={this.state.confirmModalContent}
    //                             ishandleDeletePosition={this.denyVendor.bind(this, row)}
    //                         />
    //                     )}
    //                 </div>
    //             </div>
    //         );
    //     },
    // }];

    constructor(props: any) {
        super(props);
        this.child = React.createRef();
        this.wom_vendorWidth = React.createRef();
        this.scrollArea = React.createRef();
        this.state = {
            isDropDown: false,
            confirmModalContent: [
                {
                    title: "Pending vendor",
                    desc: "Are you sure you want to deny this vendor?",
                    cancleAction: "Cancel",
                    ActionTrue: "Yes",
                }
            ],
            wom_vendorWidthCol: 0,
            hidValue: "",
            hotelName: "",
            bulkRowCount: [],
            bulkSelect: false,
            bulkSelectRow: false,
            expandedList: [],
            expList: [],
            tableData: [],
            searchResult: [],
            resetTableData: [],
            isSpinner: false,
            allCaughtUP: false,
            iasModalData: [],
            modalTypeBulk: false,
            isBulkSelected: false,
            isSlideOut: false,
            uniqueNo: 0,
            sildeoutCode: '',
            sildeoutValue: '',

            disableExport: false,
            selectedRows: [],
            densityView: [
                { id: 1, densityType: "densityView_Wide", name: "WIDE" },
                { id: 2, densityType: "densityView_Narrow", name: "NARROW" }
            ],
            defaultDensityView: "",
            tableColumnList: [
                { id: "company", name: "Vendor", isChecked: true, disabled: true },
                { id: "invoiceNo", name: "Number", isChecked: true, disabled: true },
                { id: "letterCode", name: "EHID", isChecked: true, disabled: false },
                { id: "accper", name: "AP", isChecked: true, disabled: false },
                { id: "billDate", name: "Invoice Date", isChecked: true, disabled: false },
                { id: "dueDate", name: "Due Date", isChecked: false, disabled: false },
                { id: "transTotal", name: "Amount", isChecked: true, disabled: false },
                { id: "etype", name: "Method", isChecked: true, disabled: false },
                { id: "tabStatus", name: "Status", isChecked: false, disabled: false },
            ],
            // columnsHeader: this.columnsHeader,
            columnSortingList: [
                { id: "company", name: "Vendor", isActive: true, isHidden: false },
                { id: "invoiceNo", name: "Number", isActive: false, isHidden: false },
                { id: "letterCode", name: "EHID", isActive: false, isHidden: false },
                { id: "accper", name: "Accounting Period", isActive: false, isHidden: false },
                { id: "billDate", name: "Invoice Date", isActive: false, isHidden: false },
                { id: "dueDate", name: "Due Date", isActive: false, isHidden: true },
                { id: "transTotal", name: "Amount", isActive: false, isHidden: false },
                { id: "etype", name: "Method", isActive: false, isHidden: false },
                { id: "tabStatus", name: "Status", isActive: false, isHidden: true }
            ],
            SortBy: "Vendor",
            isVendorDetailsSlideOut: false,
            clickedVendorRowData: {},
        };
    }
    componentDidMount() {
        this.GetUnApproveVendors();
    }

    onOutsideClick = (e) => {
        //debugger;
        let { tableData } = this.state;

        tableData = tableData.map((items) => {
            if (items.viewType === 1) {
                return { ...items, isDeny: false };
            } else {
                return items;
            }
        });

        this.setState((curr: any) => ({ ...curr, tableData, }));

    }


    denyApproval = (row, type) => {

        let { tableData } = this.state;

        tableData = tableData.map((items) => {
            if (items.vid === row.vid && items.viewType === 1) {
                return { ...items, isDeny: true };
            } else {
                return items;
            }
        });

        this.setState((curr: any) => ({ ...curr, tableData }));
    }
    denyVendor = (row: any, mode: any) => {
        if (mode) {

            let vendors = row.vid;
            this.denyUnApproveVendor(vendors);

        }
        else {
            let { tableData } = this.state;

            tableData = tableData.map((items) => {
                if (items.vid === row.vid && items.viewType === 1) {
                    return { ...items, isDeny: false };
                } else {
                    return items;
                }
            });

            this.setState((curr: any) => ({ ...curr, tableData }));
        }


    }

    denyBulkVendor = (row: any, mode: any) => {
        if (mode) {

            let vendorArray: any = [];
            row.forEach(item => {
                let isId = this.state.selectedRows.filter(itm => itm === item.id);
                if (isId.length > 0) {
                    vendorArray.push(item.vid);
                }
            })

            let vendorlist = vendorArray.join(",");
            this.denyUnApproveVendor(vendorlist);


        }



    }

    GetUnApproveVendors = () => {
        // let { expList, nonSelectedRows } = this.state;
        // nonSelectedRows = [];

        let request: any = {};
        this.setState({
            isSpinner: true, allCaughtUP: false, tableData: [], expList: [], expandedList: [],
            resetTableData: [], selectedRows: [], bulkSelect: false
        });
        request.HID = this.props.hidValue === "Select" ? "-1" : this.props.hidValue.toString().toLowerCase() === "all" ? '-1' : this.props.hidValue;
        InvoiceApprovalS.GetUnApproveVendors(request)
            .then(async (result: any | null) => {
                if (result.length > 0) {
                    console.log(result);

                    result.forEach(element => {
                        element.isDeny = false;
                    });


                    this.setState({ tableData: result, resetTableData: result, isSpinner: false }, () => {
                        this.getTotalUnApproveVendor();
                        this.getDefaultView();
                    })



                } else {
                    this.setState({ tableData: [], isSpinner: false, allCaughtUP: true, disableExport: true }, () => {
                        this.getTotalUnApproveVendor();
                    })
                }
                resolve();
            })
            .catch((err: any) => {
                Utils.toastError(`Server Error ${err}`);
                reject();
            });
    }

    getTotalUnApproveVendor = () => {
        this.props?.handleTotalCount(this.state.tableData.filter(x => x.viewType === 1).length);
    }

    CleanSearch() {
        this.child.current.CleanSearch();
    };




    rowEvents = {
        onClick: (e: any, row: any, rowIndex: any) => {
            this.setState({ uniqueNo: row.uniqueno, sildeoutCode: row.letterCode, sildeoutValue: row.hid }, () => {
                this.setState({ isSlideOut: true })
            });
        },
    };



    ////New Code---------------

    handleDensityView = (viewName) => {
        this.setState({ defaultDensityView: viewName })
    }

    updateColumns = () => {
        let { tableData } = this.state;
        tableData = tableData.map((items) => {
            if (items.viewType === 1) {
                return { ...items, wom_vendorWidthCol: (Number(this.wom_vendorWidth?.current?.getBoundingClientRect().width + 80 + 14).toFixed()).toString() + "px" };
            } else {
                return items;
            }
        });
        this.setState((curr: any) => ({
            ...curr,
            tableData: tableData,
            wom_vendorWidthCol: (Number(this.wom_vendorWidth?.current?.getBoundingClientRect().width + 80 + 14).toFixed()).toString() + "px"
        }));
    }

    getDefaultView() {
        laborPerformance
            .getDefaultViewProperties("UnApproveVendor")
            .then(async (response: any | null) => {
                if (response?.length > 0) {
                    this.setState({
                        defaultDensityView: response[0].fieldValue,
                        isSpinner: false,
                    }, () => {
                        setTimeout(() => { this.updateColumns(); }, 200);
                    });
                } else {
                    this.setState({ defaultDensityView: this.state.densityView[1].densityType, isSpinner: false }, () => {
                        setTimeout(() => { this.updateColumns(); }, 200);
                    })
                }
            })
            .catch((error) => {
                this.setState({ defaultDensityView: this.state.densityView[1].densityType, isSpinner: false })
                Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
            });
    }

    saveDefaultView = (): void => {
        const laborSaveAsDefaultdtos: any[] = [];
        laborSaveAsDefaultdtos.push({
            fieldName: "sort",
            fieldValue: this.state.defaultDensityView,
            pageName: "UnApproveVendor",
        });
        laborPerformance
            .saveDefaultViewProperties(laborSaveAsDefaultdtos)
            .then(async (result: any | null) => {
                if (result != null) {
                    if (result.message === "Success") {
                        toast.success(result?.result?.message, { position: toast.POSITION.BOTTOM_RIGHT });
                    }
                }
            })
            .catch((error) => {
                Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
                this.setState({ showLoader: false });
            });
    };


    onChangeStatus = (e: any, items: any, index: any) => {
        let data = Object.assign([], JSON.parse(JSON.stringify(this.state.tableColumnList)));
        data[index].isChecked = e.target.checked;
        let { columnsHeader, columnSortingList, SortBy } = this.state;
        columnSortingList[index].isHidden = data[index].isChecked ? false : true;
        if (columnSortingList[index].isActive === true && columnSortingList[index].isHidden === true) {
            columnSortingList[index].isActive = false;
            if (columnSortingList[index + 1] !== undefined && columnSortingList[index + 1].isHidden !== true) {
                columnSortingList[index + 1].isActive = true
                SortBy = columnSortingList[index + 1].name;
            } else {
                columnSortingList[0].isActive = true;
                SortBy = "Vendor";
            }
        }
        columnsHeader[index].hidden = data[index].isChecked ? false : true;
        this.setState({ columnsHeader: [] }, () => {
            this.setState({ tableColumnList: data, columnsHeader, columnSortingList, SortBy }, () => {

                this.updateColumns();
            })
        });
    }

    handleOnSelect = (row, isSelect) => {
        let { tableData, selectedRows, bulkSelectRow, bulkSelect } = this.state;
        let tableLen = tableData.filter(item => item.viewType === 1);
        if (isSelect) {
            selectedRows = [...this.state.selectedRows, row.id]
        } else {
            selectedRows = this.state.selectedRows.filter(x => x !== row.id);
        }
        if (selectedRows.length === 0) {
            bulkSelectRow = false;
            bulkSelect = false;
            $("#footerPendingBulkSelect").prop("checked", false).prop("indeterminate", false);
        } else if (selectedRows.length > 0 && selectedRows.length < tableLen.length) {
            bulkSelectRow = false;
            bulkSelect = true;
            $("#footerPendingBulkSelect").prop("checked", false).prop("indeterminate", true);
        } else if (selectedRows.length === tableLen.length) {
            bulkSelectRow = true;
            bulkSelect = true;
            $("#footerPendingBulkSelect").prop("checked", true).prop("indeterminate", false);
        }
        this.setState(() => ({ selectedRows, bulkSelectRow, bulkSelect }));

    }

    handleOnSelectAll = (isSelect, rows) => {
        let { selectedRows } = this.state;
        if (rows.length > 0) {
            if (isSelect) {
                rows.forEach(item => selectedRows.push(item.id));
                $("#footerPendingBulkSelect").prop("checked", true).prop("indeterminate", false);
            } else {
                selectedRows = [];
                this.footerBulkClose();
            }
            this.setState(() => ({
                selectedRows, bulkSelectRow: isSelect, bulkSelect: isSelect
            }));
        }
    }

    footerBulkSelectAll = (event) => {
        let { tableData, selectedRows, searchResult } = this.state;
        selectedRows = [];
        let tableLen = tableData.filter(item => item.viewType === 1);
        let checked = event.target.checked;
        if (checked) {
            searchResult.filter((item, i) => item.viewType === 1 && (selectedRows.push(item.id)));
        } else {
            selectedRows = [];
            this.footerBulkClose();
        }
        if (selectedRows.length > 0 && selectedRows.length < tableLen.length) {
            $("#footerPendingBulkSelect").prop("checked", false).prop("indeterminate", true);
        } else if (selectedRows.length === tableLen.length) {
            $("#footerPendingBulkSelect").prop("checked", true).prop("indeterminate", false);
        }
        this.setState({ bulkSelectRow: checked, selectedRows, bulkSelect: checked });
    }

    footerBulkClose = () => {
        this.setState({ selectedRows: [], bulkSelect: false, bulkSelectRow: false }, () => {
            $("#footerPendingBulkSelect").prop("checked", false).prop("indeterminate", false);
        })
    }

    handleToggleRows = (type) => {
        //  let { expList } = this.state;
        let expList: any = [];
        this.state.tableData.forEach(element => {

            if (element.viewType === 1) {
                expList.push(element.id)
            }

        });



        if (type === "Collapse_All") {
            this.setState(() => ({
                expandedList: []
            }));
        } else {
            this.setState(() => ({
                expandedList: expList
            }));
        }
    }

    searchResult = (resultAfterSearch: any) => {
        let { disableExport, searchResult, tableData } = this.state;
        searchResult = [];
        let afterSearchUniqueno: any = [];
        resultAfterSearch.forEach(item => afterSearchUniqueno.push(item.uniqueno));
        afterSearchUniqueno.forEach(uNo => {
            tableData.filter(item => {
                if (item.uniqueno === uNo) {
                    searchResult.push(item);
                }
                return item
            })
        });
        disableExport = resultAfterSearch.length > 0 ? false : true;
        this.setState({ bulkSelectRow: false, disableExport, selectedRows: [], searchResult }, () => {
            this.footerBulkClose();

            this.updateColumns();
        });
    }

    hideCheckApprovalModal = (isTrue) => {
        let { resetTableData } = this.state;
        this.setState({ tableData: resetTableData, isBulkSelected: false, iasModalData: [], modalTypeBulk: false, bulkSelectRow: false }, () => {
            this.footerBulkClose();
            this.props.handleBothReload(isTrue)
            $("#footerPendingBulkSelect").prop("checked", false).prop("indeterminate", false);
            $("body").trigger("click");
        })
    }

    showCheckApprovalModal = (row, type) => {
        if (type === "row") {
            let vendorlist = row.vid;
            this.updateVendorApproval(vendorlist);
        }
        else {
            let vendorArray: any = [];
            row.forEach(item => {
                let isId = this.state.selectedRows.filter(itm => itm === item.id);
                if (isId.length > 0) {
                    vendorArray.push(item.vid);
                }
            })

            let vendorlist = vendorArray.join(",");
            this.updateVendorApproval(vendorlist);

        }
    }


    headerFormatterDef = (column: any, colIndex: any) => {
        return (<>
            <div className='wom_Tabheader'>
                <Form.Check type="checkbox" label="" id="headerBulkSelect" checked={this.state.bulkSelectRow} onClick={(e: any) => this.footerBulkSelectAll(e)} />
            </div>
        </>);
    }

    loadBackData() {
        this.GetUnApproveVendors();
        this.hideCheckApprovalModal(true);
    }


    hideSlideOut(isSave) {
        if (isSave) {
            this.setState({ isSlideOut: false }, () => {
                this.GetUnApproveVendors();
                this.props?.handleBothReload();
                this.props?.reloadAllTab();
            });
        } else {
            this.setState({ isSlideOut: false });
        }
    }

    handleSortTable = (eventKey: any) => {
        let { columnSortingList, tableData, SortBy } = this.state;
        columnSortingList.map(item => item.id === eventKey ? (item.isActive = true) : (item.isActive = false))
        let isRow = columnSortingList.filter(item => item.id === eventKey)
        SortBy = isRow[0].name;
        tableData = _.sortBy(tableData, eventKey);
        this.setState({ SortBy, tableData, columnSortingList });
    }

    updateVendorApproval = (vendors) => {
        let request: any = {};
        request.VendorList = vendors;
        InvoiceApprovalS.VendorApprove(request)
            .then(async (result: any | null) => {
                console.log(result);
                if (result.result.saveStatus === "Success") {
                    Utils.toastError(result.result.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    // this.GetUnApproveVendors();
                    this.props?.reloadAllTab();
                    this.props?.handleBothReload();
                }
                else {
                    Utils.toastError("Something went wrong.", { position: toast.POSITION.BOTTOM_RIGHT });
                }

                resolve();
            })
            .catch((err: any) => {
                Utils.toastError(`Server Error ${err}`);
                reject();
            });
    }

    denyUnApproveVendor = (vendors) => {
        let request: any = {};
        request.VendorList = vendors;
        InvoiceApprovalS.denyUnApproveVendor(request)
            .then(async (result: any | null) => {
                console.log(result);
                if (result.result.saveStatus === "Success") {
                    Utils.toastError(result.result.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    // this.GetUnApproveVendors();
                    this.props?.reloadAllTab();
                    this.props?.handleBothReload();
                }
                else {
                    Utils.toastError("Something went wrong.", { position: toast.POSITION.BOTTOM_RIGHT });
                }

                resolve();
            })
            .catch((err: any) => {
                Utils.toastError(`Server Error ${err}`);
                reject();
            });
    }


    rowClickEvents = (row: any) => {
        this.setState({clickedVendorRowData: row},() => {
            this.setState({isVendorDetailsSlideOut: true});
        });
        // let vid = row.vid;
        // this.OpenVendorApproval(vid);
    }

    closeVendorSlideOut = (isReload) => {
        this.setState({isVendorDetailsSlideOut: false},() => {
            if(isReload) {
                this.props?.reloadAllTab();
                this.props?.handleBothReload();
            }
        });
    }

    OpenVendorApproval = (vid) => {


        User.GenerateToken()
            .then(async (result: any | null) => {
                //debugger;
                if (result) {
                    let mergeURL = result.result.mergeURL;
                    // let token = result.result.token;
                    let tokenID = result.result.tokenID;
                    let pageURL = '',
                        queryStringKey,
                        queryStringValue/*,
                        functionName = ""*/;
                    queryStringKey = "PageName";
                    queryStringValue = "VendorApproval";

                    let storage = JSON.parse(localStorage.getItem("storage")!);
                    let userName = storage.userName;
                    const currentTimeUTC = new Date().toISOString(); // ISO format UTC time
                    window.open(
                        mergeURL +
                        // "http://localhost:50491/login.aspx" +
                        "?logInUser=" +
                        Utils.encryptString(`${userName}|${currentTimeUTC}`) +
                        "&tokenID=" +
                        btoa(tokenID) +
                        "&module=" +
                        btoa("Accounting") +
                        "&logInType=2&PageUrl=" +
                        pageURL +
                        "&Key=" +
                        btoa(queryStringKey) +
                        "&Value=" +
                        btoa(queryStringValue) +
                        "&Vid=" +
                        btoa(vid)

                    );
                }


                resolve();
            })
            .catch((error) => {
                reject();
            });

    };



    ////New Code---------------

    render() {

        const columnsHeader = [{
            dataField: "vendor",
            text: "Vendor",
            headerFormatter: (column, colIndex) => {
                return (
                    <div>{column.text}</div>
                );
            },
            formatter: (cell: any, row: any, rowIndex: any) => {
                if (row.vendor.length > 20) {
                    return (<div onClick={() => { this.rowClickEvents(row) }} onWheel={() => this.hoverOutEllipsis()}>
                        <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">{row.vendor}</Tooltip>}>
                            <span>{row.vendor.toString().substring(0, 17)}...</span>
                        </OverlayTrigger>
                    </div>)
                } else {
                    return (<div onClick={() => { this.rowClickEvents(row) }}>
                        <EllipsisWithTooltip placement="bottom">{row.vendor}</EllipsisWithTooltip>
                    </div>)
                }
                // return (<div onClick={() => { this.rowClickEvents(row) }}>
                //     <EllipsisWithTooltip placement="bottom">{row.vendor}</EllipsisWithTooltip>
                // </div>)
            },
            headerClasses: "wom_colVendor",
            classes: "stickey-vendor",
        }, {
            dataField: "lettercode",
            text: "EHID",
            hidden: this.props.hidValue.toString().toLowerCase() === "select" || this.props.hidValue.toString().toLowerCase() === "all" ? false : true,
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <div onClick={() => { this.rowClickEvents(row) }}>
                        {/* <EllipsisWithTooltip placement="bottom">{row.lettercode}</EllipsisWithTooltip> */}
                        {row.lettercode.toString().includes('+') && (
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip id={"EHID-more"}>
                                        {row.lettercodetitle}
                                    </Tooltip>
                                }
                            >
                                <span>{row.lettercode}</span>
                            </OverlayTrigger>
                        )}
                        {!row.lettercode.toString().includes('+') && (
                            <>
                                {row.lettercode}
                            </>

                        )}
                    </div>)
            },
            headerClasses: "common-width ehid",
        }, {
            dataField: "createdBy",
            text: "CreatedBy",
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <div onClick={() => { this.rowClickEvents(row) }}>
                        <EllipsisWithTooltip placement="bottom">{row.createdBy}</EllipsisWithTooltip>
                    </div>)
            },
            headerClasses: "common-width",
        }, {
            dataField: "createdDate",
            text: "Created Date",
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <div onClick={() => { this.rowClickEvents(row) }}>
                        <EllipsisWithTooltip placement="bottom">{row.createdDate}</EllipsisWithTooltip>
                    </div>
                )
            },
            headerClasses: "common-width",
        },

        {
            dataField: "",
            text: "",
            //classes: (cell: any, row: any, rowIndex: any) => `wom_colBtn text-right ${row.isSpinnerLoader ? "setZindex" : ""}`,
            //headerClasses: "wom_colBtn text-right",
            // headerFormatter: this.iconFormatter,
            formatter: (cell: any, row: any, rowIndex: any) => {

                return (
                    <>
                        {row.appPermission === 'Yes' && (
                            <div className='d-flex justify-content-end'>
                                <Dropdown className="more-action wom_hasDropShadow" alignRight>
                                    <Dropdown.Toggle
                                        className="isNoBtn stickyBand"
                                        variant="success" id="dropdown-split-basic">
                                        <Button disabled={row.isSpinnerLoader} onClick={(e: any) => this.showCheckApprovalModal(row, "row")}> Approve</Button>
                                    </Dropdown.Toggle>

                                </Dropdown>
                                <div className='confirmation'>
                                    <Dropdown className="more-action deny-approval" alignRight>
                                        <Dropdown.Toggle
                                            className="btn-outline-primary btn btn-primary more"
                                            id="dropdown-more"
                                        >
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                            </svg>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>

                                            <Dropdown.Item onClick={(e: any) => this.denyApproval(row, "row")} > Deny </Dropdown.Item>

                                        </Dropdown.Menu>

                                    </Dropdown>
                                    {row.isDeny && (
                                        <OutsideClickHandler
                                            onOutsideClick={(e) => { this.onOutsideClick(e) }}>
                                            <ConfirmationModal
                                                confirmModalContent={this.state.confirmModalContent}
                                                ishandleDeletePosition={this.denyVendor.bind(this, row)}
                                            />
                                        </OutsideClickHandler>
                                    )}
                                </div>
                            </div>
                        )}
                    </>
                );
            },
        }];

        const columnsChild = [
            {
                dataField: "invoiceNo",
                text: "Number",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (<EllipsisWithTooltip placement="bottom">{row.invoiceNo}</EllipsisWithTooltip>)
                },
                //headerClasses: "wom_colcoa",
            },
            {
                dataField: "lettercode",
                text: "EHID",
                hidden: this.props.hidValue.toString().toLowerCase() === "select" || this.props.hidValue.toString().toLowerCase() === "all" ? false : true,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (<EllipsisWithTooltip placement="bottom">{row.lettercode}</EllipsisWithTooltip>)
                },
                //headerClasses: "wom_colcoa",
            }
            , {
                dataField: "accper",
                text: "AP",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (<EllipsisWithTooltip placement="bottom">{row.accper}</EllipsisWithTooltip>)
                },
                //headerClasses: "wom_colDesc",
            },
            {
                dataField: "billDate",
                text: "Invoice Date",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (<EllipsisWithTooltip placement="bottom">{row.billDate}</EllipsisWithTooltip>)
                },

                //headerClasses: "wom_colDesc",

            },
            {
                dataField: "dueDate",
                text: "Due Date",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (<EllipsisWithTooltip placement="bottom">{row.dueDate}</EllipsisWithTooltip>)
                },
                //headerClasses: "wom_colDesc",
            }, {


                dataField: "transTotal",
                text: "Total Amount",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">{Utils.amountFormatted(Number(row.transTotal).toFixed(2))}</EllipsisWithTooltip>
                    )
                },
                headerClasses: "textAlign-right",
                classes: 'textAlign-right',
            },
            {
                dataField: "etype",
                text: "Method",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (<EllipsisWithTooltip placement="bottom">{row.etype}</EllipsisWithTooltip>)
                },
                headerClasses: "textAlign-center",
                classes: 'textAlign-center',
            }
        ];

        const renderChild = (row) => {
            let uniqueID = Math.floor(Math.random() * 90 + 10);
            let splitIRowData = this.state.tableData.filter(x => x.vid === row.vid && (x.viewType === 2))
            // openRow = row;
            return (
                <div className="wom_nestedChildTable">
                    <BootstrapTable
                        key={uniqueID}
                        id={uniqueID}
                        keyField='uniqueno'
                        data={splitIRowData}
                        columns={columnsChild}
                        rowEvents={this.rowEvents}
                        noDataIndication={'No record found.'}
                    />
                </div>
            );
        };

        const handleOnExpand = (row, isExpand, rowIndex, e) => {
            if (isExpand) {
                this.setState(() => ({
                    expandedList: [...this.state.expandedList, row.id]
                }));
            } else {
                this.setState(() => ({
                    expandedList: this.state.expandedList.filter(x => x !== row.id)
                }));
            }
        }

        const expandMainRow = {
            renderer: (row: any, rowIndex: any) => renderChild(row),
            expanded: this.state.expandedList,
            showExpandColumn: true,
            expandByColumnOnly: true,
            onExpand: handleOnExpand,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                if (isAnyExpands) {
                    return <div></div>;
                }
                return <div></div>;
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (
                        <span className="chevronExpended">
                            <img src={chevronRight} alt="chevron Right" />
                        </span>
                    );
                }
                return (
                    <span className="chevronCollpsed">
                        <img src={chevronRight} alt="chevron Right" />
                    </span>
                );
            },
        };

        const bulkResponsiveList = () => {
            return (
                <div className="loader-responsive register">
                    <div className="performanceList">
                        {/* <div className="tableHeader height-40 loadingAnimation"></div> */}
                        <div className="tableList">
                            <ul>
                                <li className="d-flex">
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )
        }

        // const sortSvg = () => {
        //     return (
        //         <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        //             <path d="M4.5 15L7.5 12H5.25V3H3.75V12H1.5L4.5 15ZM8.25 6H15V7.5H8.25V6ZM8.25 9H13.5V10.5H8.25V9ZM8.25 3H16.5V4.5H8.25V3ZM8.25 12H12V13.5H8.25V12Z" />
        //         </svg>
        //     )
        // }
        
        const gearSvg = () => {
            return (
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.56286 10.1808L2.89619 12.4875C3.08019 12.8062 3.48819 12.9155 3.80686 12.7308L4.73752 12.1935C5.12419 12.4982 5.55152 12.7482 6.00086 12.9348V14.0002C6.00086 14.3682 6.29886 14.6668 6.66752 14.6668H9.33419C9.70286 14.6668 10.0009 14.3682 10.0009 14.0002V12.9348C10.4502 12.7482 10.8769 12.4982 11.2642 12.1935L12.1949 12.7308C12.5129 12.9142 12.9222 12.8042 13.1055 12.4875L14.4389 10.1808C14.6222 9.8635 14.5122 9.4535 14.1955 9.27016L13.2809 8.7415C13.3155 8.4955 13.3335 8.2475 13.3335 8.00016C13.3335 7.75283 13.3155 7.50483 13.2795 7.25883L14.1942 6.73016C14.5115 6.54683 14.6215 6.13683 14.4375 5.8195L13.1042 3.51283C12.9202 3.19416 12.5122 3.08483 12.1935 3.2695L11.2629 3.80683C10.8762 3.50216 10.4495 3.2515 10.0002 3.0655V2.00016C10.0002 1.63216 9.70219 1.3335 9.33352 1.3335H6.66686C6.29819 1.3335 6.00019 1.63216 6.00019 2.00016V3.0655C5.55086 3.25216 5.12419 3.50216 4.73686 3.80683L3.80686 3.2695C3.48752 3.08483 3.08019 3.19416 2.89619 3.51283L1.56286 5.8195C1.37953 6.13683 1.48953 6.54683 1.80619 6.73016L2.72086 7.25883C2.68486 7.50483 2.66686 7.75283 2.66686 8.00016C2.66686 8.2475 2.68486 8.4955 2.72086 8.7415L1.80619 9.27016C1.48886 9.4535 1.37886 9.8635 1.56286 10.1808ZM8.00019 5.3335C9.47086 5.3335 10.6669 6.5295 10.6669 8.00016C10.6669 9.47083 9.47086 10.6668 8.00019 10.6668C6.52952 10.6668 5.33352 9.47083 5.33352 8.00016C5.33352 6.5295 6.52952 5.3335 8.00019 5.3335Z" />
                </svg>)
        }
        const checkSvg = () => {
            return (
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z" fill="#2CAF92" />
                </svg>
            )
        }
        let { selectedRows, tableData, defaultDensityView } = this.state;
        let densityView = defaultDensityView;
        let className;
        let tableLen = tableData.filter(item => item.viewType === 1);
        let isLenLess = selectedRows.length > 0 && selectedRows.length < tableLen.length;
        className = isLenLess ? "showTip" : "";

        const selectRow = {
            mode: 'checkbox',
            // clickToSelect: true,
            selected: this.state.selectedRows,
            headerClasses: "selectionHeader",
            onSelect: this.handleOnSelect,
            onSelectAll: this.handleOnSelectAll,
        };
        return (
            <>
                {this.state.isSlideOut && (
                    <InvoiceEntrySlideout
                        mainHidValue={this.props.hidValue}
                        hidValue={this.state.sildeoutValue}
                        hotelName={this.state.sildeoutCode}
                        uniqueNo={this.state.uniqueNo}
                        pageType={"Invoice"}
                        saveAction={"VendorApproval"}
                        oprId={this.state.oprId}
                        vid={this.state.vid}
                        hideSlideOut={this.hideSlideOut.bind(this)}
                        loadBackData={this.loadBackData.bind(this)}
                    //OpenInvoiceSlideOut={this.OpenInvoiceSlideOut.bind(this)}
                    //selectedRow={this.state.selectedRow}
                    />
                )}
                {(this.state.isVendorDetailsSlideOut &&
                    <VendorDetailSlideOut
                        vendorData={this.state.clickedVendorRowData}
                        selectedTab={"general"}
                        closeSlideOut={this.closeVendorSlideOut}
                        // isDachSetupEnterPrise={""}
                        // isMergeContracts={}
                    ></VendorDetailSlideOut>
                )}
                {this.state.allCaughtUP ? (<>
                    <div className="vt5allCaughtUp d-flex align-items-center justify-content-center">
                        <img src={invoice_inv} alt="You’re all caught up!" />
                        <div className="label-sec"> You’re all caught up on your vendor approvals. <br />Nice Work <img src={caught} alt="You’re all caught up!" /></div>
                    </div>
                </>) : (
                    <ToolkitProvider
                        keyField="id"
                        data={tableData.filter(x => x.viewType === 1)}
                        columns={columnsHeader}
                        search={{ afterSearch: (newResult) => this.searchResult(newResult) }}
                    >
                        {(props: {
                            searchProps: JSX.IntrinsicAttributes;
                            baseProps: JSX.IntrinsicAttributes;
                        }) => (
                            <>
                                <div className="vt5InnerTabPanel">
                                    <div className='wom_TabBody'>
                                        <div className="search-header d-flex">
                                            <div className={tableData.length === 0 ? "search-filter disabled-area" : "search-filter"}>
                                                <label id="labeltableSearch" className="search-label">
                                                    <TableSearch id="tableSearch" key="tableSearch" ref={this.child}
                                                        {...props.searchProps} CleanSearch={this.CleanSearch.bind(this)}
                                                        placeholder={"Filter Vendor"}
                                                    />
                                                </label>
                                            </div>
                                            <div className="d-flex ml-auto">
                                                {/* <div className={`${tableData.length === 0 || tableLen.length < 2 ? "disabled-area" : ""} hasShortingBtn wom_hasGearIcon mr-2`}>
                                                    <Dropdown className="pos-static more-action bg-blue dropdown wom_dropdown" alignRight onSelect={(e) => this.handleSortTable(e)}>
                                                        <Dropdown.Toggle id="dropdown-ptoType" disabled={tableData.length === 0 || tableLen.length < 2}>
                                                            <div className="d-flex align-items-center wom_svg">
                                                                <div className="d-flex align-items-center wom_svg">
                                                                    {sortSvg()}
                                                                    {this.state.SortBy}
                                                                    <div className="drop-arrow d-flex ml-1">
                                                                        <FiChevronDown />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="menuWidth200">
                                                            {this.state.columnSortingList.map((item, index) => {
                                                                if (item.isHidden || item.id === "tabStatus") {
                                                                    return (<></>)
                                                                } else {
                                                                    return (
                                                                        <Dropdown.Item key={index} className={item.isActive ? "active" : ""} eventKey={item.id}>
                                                                            <div className="d-flex">
                                                                                <div className="mr-auto">{item.name}</div>{checkSvg()}
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                    )
                                                                }
                                                            })}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div> */}


                                                <div className="wom_hasToggleBtn">
                                                    <Button type="button"
                                                        onClick={(e: any) => this.handleToggleRows(this.state.expandedList.length > 0 ? "Collapse_All" : "Expend_All")}
                                                        className="btn-outline-primary btnHeight"
                                                        disabled={tableData.length === 0}
                                                    >{this.state.expandedList.length > 0 ? "Collapse All" : "Expand All"}</Button>
                                                </div>

                                                <div className="ml-1 wom_hasGearIcon">
                                                    <Dropdown className="more-action bg-blue dropdown wom_dropdown">
                                                        <Dropdown.Toggle id="dropdown-ptoType" disabled={tableData.length === 0}>
                                                            <div className="d-flex align-items-center wom_svg">
                                                                {gearSvg()}
                                                                <div className="drop-arrow d-flex">
                                                                    <FiChevronDown />
                                                                </div>
                                                            </div>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="wom_DencityMenu">
                                                            <>
                                                                <div className="wom_gearIconHeader wom_headerTop"><strong>Density View Control</strong></div>
                                                                {this.state.densityView.map((items, index) => (
                                                                    <div className={densityView === items.densityType ? 'wom_dropdownItem wom_ddactive' : 'wom_dropdownItem'}
                                                                        key={items.id}
                                                                        onClick={(e: any) => this.handleDensityView(items.densityType)}>{items.name}
                                                                        {densityView === items.densityType && (<>{checkSvg()}</>)}
                                                                    </div>
                                                                ))}
                                                                <div className="m-0 dropdown-divider"></div>
                                                                <Dropdown.Item className="wom_saveDefaultBtn" onClick={() => this.saveDefaultView()} >Save as Default View</Dropdown.Item>
                                                            </>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="wom_CombinedTable" className={`${this.state.bulkSelect ? "wom_CombinedTable hasFooter" : "wom_CombinedTable noFooter"} ${className}`} ref={this.scrollArea}>
                                            {(this.state.isSpinner) ? (
                                                <div className="bulkImportList mt-0">
                                                    <div className="bulkImportList1 d-flex">
                                                        <div className="single-loader loadingAnimation"></div>
                                                    </div>
                                                    <>{bulkResponsiveList()}</>
                                                    <>{bulkResponsiveList()}</>
                                                    <>{bulkResponsiveList()}</>
                                                </div>
                                            ) : (
                                                <div className={this.state.isBulkSelected ? `${densityView} disabled-area stickey pending-approval` : `${densityView} stickey pending-approval`}>
                                                    <BootstrapTable
                                                        {...props.baseProps}
                                                        keyField="id"
                                                        hover
                                                        noDataIndication={'No record found.'}
                                                        selectRow={selectRow}
                                                        expandRow={expandMainRow}
                                                        filter={filterFactory()}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        {tableData.filter(x => x.viewType === 1 && x.appPermission=== 'Yes').length>0 && (
                                        <div className={this.state.bulkSelect ? "wom_TabFooter" : "d-none wom_TabFooter"}>
                                            <div className="bulkSelectFooter">
                                                <Form.Check type="checkbox" label=""
                                                    id="footerPendingBulkSelect"
                                                    checked={this.state.bulkSelectRow}
                                                    onClick={(e: any) => this.footerBulkSelectAll(e)} />
                                                <span className="shift-selected-count">{this.state.selectedRows.length} Invoice
                                                    {this.state.selectedRows.length > 1 && ("s")} Selected</span>
                                                <div className="action-group d-flex flex-row mr-auto">
                                                    <Dropdown className="more-action" alignRight>
                                                        <Dropdown.Toggle
                                                            className="btn-outline-primary btn btn-primary"
                                                            id="dropdown-more"
                                                        >Deny</Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <ConfirmationModal
                                                                confirmModalContent={this.state.confirmModalContent}
                                                                ishandleDeletePosition={this.denyBulkVendor.bind(this, this.state.tableData)}
                                                            />
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <Dropdown className="wom_hasDropShadow" alignRight>
                                                        <Dropdown.Toggle
                                                            className="isNoBtn"
                                                            variant="success" id="dropdown-split-basic">
                                                            <Button onClick={(e: any) => this.showCheckApprovalModal(this.state.tableData, "bulk")}> Approve</Button>
                                                        </Dropdown.Toggle>

                                                    </Dropdown>
                                                </div>
                                                <Button className="closeButton btn-ghost-light" onClick={() => this.footerBulkClose()}
                                                >&times;</Button>
                                            </div>
                                        </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </ToolkitProvider>
                )}

            </>


        )
    }
}