import Instense from "./Axios-config";
import {
  ILabourScheduleRequestDto,
  ILabourScheduleResponseDto,
  ILaborScheduleDeptPositionRequestDto,
  ILaborScheduleDetailsDeptPositionResponseDto,
  ILaborScheduleEmployeeRequestDto,
  ILaborScheduleEmployeeResponseDto,
  ILabourScheduleDepartmentViewRequestDto,
  ILaborScheduleReassignEmployeeListDto,
  ILaborScheduleDepartmentViewScheduleDto,
  ILaborSaveAsDefaultViewRequestDto,
  ILaborSaveAsDefaultViewResponseDto,
  ILaborPublishSceduleRequestDto,
  ISaveEditPTORequest,
  IBudgetVsActualRequest,
  ILaborPTOUTODeleteReq,
  ILaborEditPTOUTO,
  IBufferTimeAndMissedPunchOutAlertEditRequest,
  IBufferTimeAndMissedPunchOutAlertResponse,
  IBufferTimeAndMissedPunchOutAlertGetRequest,
  SaveTemplateNameSaveRequestDTO,
  GetLaborScheduleTemplateRequest,
  IUpdateDeleteScheduleTemplateRequest,
} from "../Contracts/ILaborSchedule";
import axios from "axios";
export class LaborSchedule {
  private static ajaxRequestMissedPunchOut: any = null;
  public static GetLaborScheduleData = async (
    request: ILabourScheduleRequestDto
  ): Promise<ILabourScheduleResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantID = tenantID;
    const url = `/LaborSchedule/Get_LaborEmpScheduleDetailsUserView`;

    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as ILabourScheduleResponseDto;
      if (result.message === "Success") {
        return result.result;
      }
    });
  };

  public static updateBufferTimeAndMissedPunchOutAlert = async (
    request: IBufferTimeAndMissedPunchOutAlertEditRequest
  ): Promise<any | null> => {
    if (LaborSchedule.ajaxRequestMissedPunchOut) {
      LaborSchedule.ajaxRequestMissedPunchOut.cancel("Cancel");
    }
    LaborSchedule.ajaxRequestMissedPunchOut = axios.CancelToken.source();

    const storage = JSON.parse(localStorage.getItem("storage")!);
    const tenantId = storage?.tenantID;
    request.tenantId = tenantId;
    const url = `/LaborSchedule/UpdateBufferTimeAndMissedPunchOutAlert`;
    const response = await Instense.put(url, request, {
      cancelToken: LaborSchedule.ajaxRequestMissedPunchOut.token,
    });
    let result = response.data as any;
    return result;
  };

  public static GetLaborPositionDept = async (
    request: ILaborScheduleDeptPositionRequestDto
  ): Promise<ILaborScheduleDetailsDeptPositionResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let user_UniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    request.tenantID = tenantID;
    request.userUniqueno = user_UniqueID;
    const url = `/LaborSchedule/GetLaborScheduleDeptPosition`;
    return Instense.get(url, { params: request }).then((response) => {
      let result =
        response.data as ILaborScheduleDetailsDeptPositionResponseDto;
      return result;
    });
  };

  public static GetLaborScheduleEmployee = async (
    request: ILaborScheduleEmployeeRequestDto
  ): Promise<ILaborScheduleEmployeeResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborSchedule/Get_LaborScheduleEmployeeList`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as ILaborScheduleEmployeeResponseDto;
      return result;
    });
  };

  public static getBufferTimeAndMissedPunchOutAlert = async (
    hotelId: number
  ): Promise<IBufferTimeAndMissedPunchOutAlertResponse | null> => {
    const request: IBufferTimeAndMissedPunchOutAlertGetRequest =
      {} as IBufferTimeAndMissedPunchOutAlertGetRequest;
    const storage = JSON.parse(localStorage.getItem("storage")!);
    request.tenantId = storage?.tenantID;
    request.hotelId = hotelId;
    const url = `/LaborSchedule/GetBufferTimeAndMissedPunchOutAlert`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as IBufferTimeAndMissedPunchOutAlertResponse;
      return result;
    });
  };

  public static SaveLaborScheduleDetails = async (
    request: any
  ): Promise<any | null> => {
    const url = `/LaborSchedule/SaveLaborScheduleDetails`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result.result;
  };

  public static SaveLaborScheduleDragAndDropDetails = async (
    request: any
  ): Promise<any | null> => {
    const url = `/LaborSchedule/SaveScheduleDragAndDrop`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result.result;
  };

  public static ReAssignAllEmployeeShift = async (
    request: any
  ): Promise<any | null> => {
    const url = `/LaborSchedule/ReAssignAllEmployeeShift`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result.result;
  };

  public static RemoveAllEmployeeShift = async (
    request: any
  ): Promise<any | null> => {
    const url = `/LaborSchedule/RemoveAllEmployeeShift`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result.result;
  };

  public static RemoveShiftFromSchedule = async (
    request: any
  ): Promise<any | null> => {
    const url = `/LaborSchedule/RemoveAllEmployeeShift`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result.result;
  };

  public static ReassignShiftFromSchedule = async (
    request: any
  ): Promise<any | null> => {
    const url = `/LaborSchedule/ReAssignAllEmployeeShift`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result.result;
  };

  public static GetLaborReassignScheduleEmployee = async (
    request: ILaborScheduleReassignEmployeeListDto
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborSchedule/Get_LaborScheduleAssignUser`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static DeleteSchedule = async (request: any): Promise<any | null> => {
    const url = `/LaborSchedule/DeleteScheduleEmployeeShift`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result.result;
  };

  public static GetLaborScheduleDeptViewData = async (
    request: ILabourScheduleDepartmentViewRequestDto
  ): Promise<ILabourScheduleResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantID = tenantID;
    const url = `/LaborSchedule/Get_LaborEmpScheduleDetailsDepartmentView`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as ILabourScheduleResponseDto;
      return result;
    });
  };

  public static GetLaborDepartmentScheduleData = async (
    request: ILaborScheduleDepartmentViewScheduleDto
  ): Promise<ILabourScheduleResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborSchedule/Get_LaborEmpScheduleDepartmentViewSubDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as ILabourScheduleResponseDto;
      return result;
    });
  };

  public static LaborScheduleBulkSelect = async (
    request: any
  ): Promise<any | null> => {
    const url = `/LaborSchedule/BulkEditEmployeeShift`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result.result;
  };

  public static LaborSaveAsDefaultView = async (
    request: any
  ): Promise<any | null> => {
    const url = `/LaborSchedule/LaborSaveAsDefaultView`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result.result;
  };

  public static GetLaborSaveAsDefaultViewData = async (
    request: ILaborSaveAsDefaultViewRequestDto
  ): Promise<ILaborSaveAsDefaultViewResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let user_UniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    request.tenantID = tenantID;
    request.UserUniqueID = user_UniqueID;
    const url = `/LaborSchedule/GetLaborSaveAsDefaultView`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as ILaborSaveAsDefaultViewResponseDto;
      return result;
    });
  };

  public static LaborSchedulePublishSchedule = async (
    request: ILaborPublishSceduleRequestDto
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let user_UniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    request.tenantID = tenantID;
    request.loginUser = user_UniqueID;
    const url = `/LaborSchedule/LaborPublishScheduleShifts`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as ILabourScheduleResponseDto;
      return result;
    });
  };

  public static LaborSchedulePublishEmailNotification = async (
    request: ILaborPublishSceduleRequestDto
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let user_UniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    request.tenantID = tenantID;
    request.loginUser = user_UniqueID;
    const url = `/LaborSchedule/LaborSendPublishScheduleMails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as ILabourScheduleResponseDto;
      return result;
    });
  };

  public static getPTOBalance = async (
    hotelID: number
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let request: any = {};
    let todayDate = new Date();
    if (
      JSON.parse(localStorage.storage).currentUtcTime !== "" &&
      JSON.parse(localStorage.storage).currentUtcTime !== null &&
      JSON.parse(localStorage.storage).currentUtcTime !== undefined
    ) {
      todayDate = new Date(JSON.parse(localStorage.storage).currentUtcTime);
    }
    request.startDate = todayDate;
    request.hotelID = hotelID;
    request.userName = storage?.userName;
    const url = `/LaborPTO/GetPTOBalance`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as ILabourScheduleResponseDto;
      return result;
    });
  };

  public static RaiseTimeOff  = async (
    request: ISaveEditPTORequest[]
  ): Promise<any | null> => {
    const storage = JSON.parse(localStorage.getItem("storage")!);
    const tenantID = storage?.tenantID;
    const user_UniqueID = storage?.user_UniqueID;
    request = request.map((item) => {
      item.TenantID = tenantID;
      item.UserUniqueno = user_UniqueID;
      //item.IsRequestEntry = "no";
      return item;
    });
    const url = `LaborPTO/RaiseTimeOff`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    //debugger
    return result;
  };

  public static MyPtoRaiseTimeOff  = async (
    request: any[]
  ): Promise<any | null> => {
    const storage = JSON.parse(localStorage.getItem("storage")!);
    const tenantID = storage?.tenantID;
    const user_UniqueID = storage?.user_UniqueID;
    request = request.map((item) => {
      item.TenantID = tenantID;
      // item.UserUniqueno = user_UniqueID;
      //item.IsRequestEntry = "no";
      return item;
    });
    const url = `LaborPTO/MyPtoRaiseTimeOff`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    //debugger
    return result;
  };

  public static getEmployeeTypeByUserName = async (
    userName: string
  ): Promise<any | null> => {
    const request: any = {};
    const storage = JSON.parse(localStorage.getItem("storage")!);
    request.tenantID = storage?.tenantID;
    request.userName = userName;
    const url = `/LaborSchedule/GetEmployeeTypeByTenantIdAndUserName`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static GetBudgetVsActualHours = async (
    request: IBudgetVsActualRequest
  ): Promise<IBudgetVsActualRequest | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborSchedule/GetBudgetVsActualHours`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static LaborDefaultDepartMentName = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let user_UniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    request.tenantID = tenantID;
    request.userID = user_UniqueID;
    const url = `/LaborSchedule/LaborDefaultDepartMentName`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static LaborPrintMySchedule = async (
    request: any
  ): Promise<any | null> => {
    const storage = JSON.parse(localStorage.getItem("storage")!);
    request.tenantID = storage?.tenantID;
    request.userName = storage?.userName;
    const url = `/LaborSchedule/LaborPrintMySchedule`;
    // 
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static DeletechedulePTOUTOFF = async (
    request: ILaborPTOUTODeleteReq
  ): Promise<any | null> => {
    const storage = JSON.parse(localStorage.getItem("storage")!);
    const tenantId = storage?.tenantID;
    const userName = storage?.userName;
    request.tenantID = tenantId;
    request.username = userName;
    const url = `/LaborSchedule/DeletechedulePTOUTOFF`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };

  public static EditSchedulePTOUTO = async (
    request: ILaborEditPTOUTO
  ): Promise<any | null> => {
    const storage = JSON.parse(localStorage.getItem("storage")!);
    const tenantId = storage?.tenantID;
    request.tenantID = tenantId;
    const url = `/LaborSchedule/EditSchedulePTOUTO`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };

  public static GetShortenURL = async (request: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborPTO/GetShortURL`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as any;

      return result;
    });
  };

  public static SaveScheduleTemplateName = async (
    request: SaveTemplateNameSaveRequestDTO
  ): Promise<any | null> => {
    const storage = JSON.parse(localStorage.getItem("storage")!);
    request.TenantID = storage?.tenantID;
    request.LogInUser = storage?.userName;

    const url = `/LaborSchedule/SaveLaborScheduleTemplateMasterDetails`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };

  public static GetLaborScheduleTemplates = async (
    request: GetLaborScheduleTemplateRequest
  ): Promise<any | null> => {
    const storage = JSON.parse(localStorage.getItem("storage")!);
    request.tenantID = storage?.tenantID;
    const url = `/LaborSchedule/GetLaborScheduletTemplate`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static UpdateDeleteScheduleTemplate = async (
    request: IUpdateDeleteScheduleTemplateRequest
  ): Promise<any | null> => {
    const storage = JSON.parse(localStorage.getItem("storage")!);
    request.TenantID = storage?.tenantID;
    request.LogInUser = storage?.userName;

    const url = `/LaborSchedule/UpdateDeleteScheduletTemplate`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };

  public static GetLaborAccountMailLogData = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantId = tenantID;
    const url = `/LaborSchedule/GetLaborAccountMailLogData` ;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result?.result as any;
      return result;
    });
  };
  
  public static DownloadLaborAccountMailLogData = async (
    request: any, fileName: string
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantId = tenantID;
    const url =  `/LaborSchedule/DownloadLaborAccountMailLogData`;
    return Instense.post(url, request, { responseType: "blob" }).then(
      (response: any) => {
        let csvURL = window.URL.createObjectURL(response.data);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", fileName + ".xlsx");
        tempLink.click();
      }
    );
  };

 

  public static GetLaborTimeoffSummaryCount = async (request: any): Promise<any | null> => {
    //debugger
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantid = tenantID;
    const url = `/LaborPTO/LaborTimeoffSummaryCount`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as any;
      //debugger
      return result;
    });
  };

  public static LaborTimeoffSummaryDetails = async (request: any): Promise<any | null> => {
    //debugger
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantid = tenantID;
    //request.tenantid = 237;
    const url = `/LaborPTO/LaborTimeoffSummaryDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as any;
    
      return result;
    });
  };

  public static GetLaborTimeoffSummary = async (request: any): Promise<any | null> => {
    //debugger
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantid = tenantID;
    const url = `/LaborPTO/LaborTimeoffSummary`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as any;
      //debugger
      return result;
    });
  };

  public static LaborTimeoffPTOHistoryDetails = async (request: any): Promise<any | null> => {
   // debugger
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantid = tenantID;
    const url = `/LaborPTO/LaborTimeoffPTOHistoryDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as any;
      //debugger
      return result;
    });
  };


  public static SendRequestDemoEmail = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantID = tenantID;
    const url = `/LaborSchedule/SendEmailForMaketingNotification`;

    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as any;
      //debugger
      return result;
    });
  };

}
