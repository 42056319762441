import React from "react";
import { Redirect } from "react-router-dom";
import { Container, Dropdown, Tabs, Tab, Form, Row } from "react-bootstrap";
import { Col, Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import OutsideClickHandler from "react-outside-click-handler";
import scrollIntoView from "scroll-into-view";
import moment from "moment";
import $ from "jquery";
import _ from "lodash";
import { resolve, reject } from "q";
import { Utils } from "../../../Common/Utilis";
import chevronRight from "../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import { ConfirmationModal } from "../../../Common/Components/ConfirmationModal";
import { MoonIcon } from "../../../Common/Components/MoonIcon";
import { InfoTooltip } from "../../../Common/Components/InfoTooltip";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import { MyImage } from "../../../Common/Components/MyImage";
import profile from "../../../Common/Assets/Images/profile.png";
import ForecastLock from "../../../Common/Assets/Images/icons/forecast-lock.svg";
import { schConversions } from "../Labor/Schedule/commonFunctions/conversions";
import { LaborTimesheetDispute } from "../../LaborManagement/TimeSheet/LaborTimesheetDispute";
import { LaborTimesheetResolvedDispute } from "../../LaborManagement/TimeSheet/LaborTimesheetResolvedDispute";
import { LaborDeptPosition } from "../../../Common/Services/LaborDeptPosition";
import { LaborTimeSheet } from "../../../Common/Services/LaborTimeSheet";
import { LaborPerformance } from "../../../Common/Services/LaborPerfomance";
import { LaborSmileID } from "../../../Common/Services/LaborSmileId";
import { TimesheetLogHistory } from "./TimesheetLogHistory";
import { confirmAlert } from "react-confirm-alert";
import "./Labor-timesheet.scss";

let openRow: any = {}; // variable to maintain the row object
export class LaborTimesheetDetail extends React.Component<any, any> {
  private breakpositionRef: any;
  private btnhHandleSave: any;
  private btnSaveNewEntry: any;
  private btnSaveBreak: any;
  private btnSplitShift: any;
  private btnChangeShift: any;
  private btnUpdateBreak: any;
  private rowScheduleID: any;
  private btnAssociateSave: any;
  private ddlNoShow: any;
  private ddlParentLog: any;
  private refIn: any;
  private refOut: any;
  private closeModal: any;



  constructor(props: any) {
    super(props);
    this.breakpositionRef = React.createRef();
    this.btnhHandleSave = React.createRef();
    this.btnSaveNewEntry = React.createRef();
    this.btnSaveBreak = React.createRef();
    this.btnChangeShift = React.createRef();
    this.btnSplitShift = React.createRef();
    this.btnUpdateBreak = React.createRef();
    this.btnAssociateSave = React.createRef();
    this.ddlNoShow = React.createRef();
    this.ddlParentLog = React.createRef();
    this.refIn = React.createRef();
    this.refOut = React.createRef();
    this.closeModal = null;
    this.state = {
      pageLoader: false,
      activetab: "details",
      hotelID: this.props.data.hotelID,
      hotelName: this.props.data.hotelName,
      userUniqueno: this.props.data.userUniqueno,
      userName: this.props.data.userName,
      payPeriod: this.props.data.payPeriod,
      date: this.props.data.date,
      activeClass: -1,
      allData: [],
      total: [],
      data: [],
      positionWiseList: [],
      positionList: [],
      dayDetails: [],
      alldepartmentlist: [],
      allpositionlist: [],
      breakPositionlist: [],
      actionText: "",
      adjustments: "0:00",
      dispute: 0,
      isDiputeOpen: "",
      condition: "All",
      exportedDate: "",
      forseApprovalModal: false,
      addEntry: {
        newInTime: "00:00",
        newOutTime: "00:00",
        breakHH: "",
        breakMM: "",
        newDepartmentId: 0,
        newDepartment: "Select",
        newPositionId: 0,
        newPosition: "Select",
        newNotes: "",
      },
      forseApprovalValue: "",
      forseApprovalError: "",
      isDeepLinking: false,
      shiftID: 0,
      startdate: "",
      enddate: "",
      forceapproval: "No",
      managedispute: "No",
      isRedirect: false,
      payPeriodDates: [],
      smileIdIMG: "",
      showChangeLogModal: false,
      scheduleNo: 0,
      isSlatChangeLog: false,
      // associateDateList: []
      noShowomment: "",
      noShowCountList: [],
      isRemoveDNS: "No",
      isPayrollExported : this.props.parentState.isPayrollExported,
      payrollExportPopup : false,
      netchexWarningPopup : false
    };
  }

  componentDidMount() {

    let currentURL = window.location.href;
    if (currentURL.includes("?")) this.setState({ isDeepLinking: true });
    else this.setState({ isDeepLinking: false });
    this.loadLaborTimesheetDetails();
    this.loadTimesheetDateWise("componentMount");
    this.getlaborPositionDeptByemployee();
    this.getDisputeCount();
    this.getHidPayPeriod(this.props.data.date, this.props.data.hotelID);

  }

  scrollIntoViewDate = () => {

    let compDate = this.state.date;
   
    const ref = this.state.dayDetails.find((r) =>
      r.timesheet
        .map((x) => {
          let date = new Date(x.date);
          const _date =
            (date.getMonth() + 1).toString().padStart(2, "0") +
            "/" +
            date.getDate().toString().padStart(2, "0") +
            "/" +
            date.getFullYear().toString().substr(2, 2);
          return _date;
        })
        .includes(compDate)
    )?.ref?.current;
    if (ref) {
      ref.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
    // }
  }
  refreshTimesheetDetails = () => {
    this.setState({ condition: "Dispute" }, () => {
      this.loadLaborTimesheetDetails();
      this.loadTimesheetDateWise("refreshTimeSheet");
      this.props.slideOutRefresh();
      this.getDisputeCount();
    });
  };

  getHidPayPeriod = (selectedDate: any, hotelID: Number) => {
    LaborPerformance.getPayPeriodDates(hotelID, selectedDate, "Weekly")
      .then(async (result: any[] | null) => {
        if (result != null) {
          this.setState({
            payPeriodDates: [result[0].paystartdate, result[0].payenddate],
            exportedDate: result[0].exporteddateMMMMDDYYYY,
            startdate: result[0].startdate,
            enddate: result[0].enddate,
          });
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error: ${err}`);
        reject();
      });
  };

  getDisputeCount = () => {
    LaborTimeSheet.LaborDisputeCount(
      this.props.data.hotelID,
      this.props.data.date,
      this.props.data.userName,
      "TimeSheet"
    )
      .then(async (result: any | null) => {
        if (result != null) {
          this.setState({
            dispute: result.totalDispute !== null ? result.totalDispute : 0,
            isDiputeOpen: result.openBedge,
          });
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error: ${err}`);
        reject();
      });
  };

  closeSlideOut = () => {
    openRow = {};
    this.props.hideSlideOut();
  };

  hideExportedDate = () => {
    this.setState({ exportedDate: "" });
    this.props.hideExportedDate();
  };

  handleAddEntry = (e: any, index: any, type: any = "") => {

    let newAddEntry = { ...this.state.addEntry };
    let newAllPositionList = [...this.state.allpositionlist];
    newAllPositionList = [];
    newAddEntry = {
      newInTime: "00:00",
      newOutTime: "00:00",
      breakHH: "",
      breakMM: "",
      newDepartmentId: 0,
      newDepartment: "Select",
      newPositionId: 0,
      newPosition: "Select",
      newNotes: "",
    };
    if (type !== "shift") {

      let dataArr: any[] = _.cloneDeep(this.state.dayDetails[index].timesheet);
      let tempUniqueNo = openRow[index]?.actualUniqueno
      let tempObj = dataArr.find(item => item.actualUniqueno === tempUniqueNo)
      openRow[index] = tempObj !== undefined && tempObj;
    }
    this.setState({
      addEntry: newAddEntry,
      allpositionlist: newAllPositionList,
    });
  };

  updateSmileIdStatus = (type: string, row: any, mode: number) => {
    let request = {} as any;
    request.hotelID = this.state.hotelID;
    request.Uniqueno = mode === 1 ? row.inUniqueno : row.outUniqueno;
    request.isFalsePunches = type;
    LaborSmileID.SimeIdAction(request)
      .then(async (result: any | null) => {
        if (result.saveStatus === "Success") {
          this.loadTimesheetDateWise("updateSmileId");
          this.props.slideOutRefresh();
        } else {
          Utils.toastError(result.message);
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error: ${err}`);
        reject();
      });
  };

  intimeChange = (e: any, row: any, rowIndex: any, index: number) => {
    let data = _.cloneDeep(this.state.dayDetails);

    let childIndex = this.getCurrentChildRow(row, index);

    data[index].timesheet[childIndex].inTimedup = e.target.value;
    this.setState({ dayDetails: data });
  };

  intimeBlur = (e: any, row: any, rowIndex: any, index: number) => {
    let data = _.cloneDeep(this.state.dayDetails);

    let childIndex = this.getCurrentChildRow(row, index);

    data[index].timesheet[childIndex].inTimedup = e.target.value;
    this.setState({ dayDetails: data }, () => {
      this.changePunchOutTime(row, rowIndex, index);
    });
  };

  outtimeChange = (e: any, row: any, rowIndex: any, index: number) => {
    let data = _.cloneDeep(this.state.dayDetails);

    let childIndex = this.getCurrentChildRow(row, index);

    data[index].timesheet[childIndex].outTimedup = e.target.value;
    this.setState({ dayDetails: data });
  };

  outtimeBlur = (e: any, row: any, rowIndex: any, index: number) => {
    let data = _.cloneDeep(this.state.dayDetails);

    let childIndex = this.getCurrentChildRow(row, index);

    data[index].timesheet[childIndex].outTimedup = e.target.value;
    this.setState({ dayDetails: data }, () => {
      this.changePunchOutTime(row, rowIndex, index);
    });
  };

  getDateList = (row: any, rowIndex: number, index: number) => {
    let punchInDateList: Array<string> = [];
    let currentDate = moment(row.date).format("MM/DD/YY");
    let previousDate = moment(row.date).subtract(1, "days").format("MM/DD/YY");
    let nextdate = moment(row.date).add(1, "days").format("MM/DD/YY");
    punchInDateList.push(previousDate);
    punchInDateList.push(currentDate);
    punchInDateList.push(nextdate);
    return (
      <div>
        {punchInDateList.map((item, idx) => (
          <Dropdown.Item
            key={idx}
            eventKey={item}
            onClick={() => this.ddlInDateSelection(item, row, rowIndex, index)}
          >
            {item}
          </Dropdown.Item>
        ))}
      </div>
    );
  };

  ddlInDateSelection = (item: any, row: any, rowIndex: any, index: number) => {
    let data = _.cloneDeep(this.state.dayDetails);

    let childIndex = this.getCurrentChildRow(row, index);

    data[index].timesheet[childIndex].ddlInDate = item;
    this.setState({ dayDetails: data }, () => {
      this.changePunchOutTime(row, rowIndex, index);
    });
  };

  changePunchOutTime = (row: any, rowIndex: any, index: number) => {
    let data = _.cloneDeep(this.state.dayDetails);

    let childIndex = this.getCurrentChildRow(row, index);

    let inMomentTime = moment(
      `${data[index].timesheet[childIndex].ddlInDate} ${data[index].timesheet[childIndex].inTimedup}`
    );
    let outMomentTime = moment(
      `${data[index].timesheet[childIndex].ddlInDate} ${data[index].timesheet[childIndex].outTimedup}`
    );
    let differenceInTime = outMomentTime.diff(inMomentTime);

    let punchOutDate = moment(
      data[index].timesheet[childIndex].ddlInDate
    ).format("MM/DD/YY");
    if (differenceInTime < 0) {
      punchOutDate = moment(data[index].timesheet[childIndex].ddlInDate)
        .add(1, "days")
        .format("MM/DD/YY");
    }
    data[index].timesheet[childIndex].ddlOutDate = punchOutDate;

    this.setState({
      dayDetails: data,
    });
  };

  noteChange = (e: any, row: any, rowIndex: any, index: number) => {
    let data = _.cloneDeep(this.state.dayDetails);

    let childIndex = this.getCurrentChildRow(row, index);

    data[index].timesheet[childIndex].lastInNotesdup = e.target.value;
    this.setState({ dayDetails: data });
  };

  noteOutChange = (e: any, row: any, rowIndex: any, index: number) => {
    let data = _.cloneDeep(this.state.dayDetails);

    let childIndex = this.getCurrentChildRow(row, index);

    data[index].timesheet[childIndex].lastOutNotesdup = e.target.value;
    this.setState({ dayDetails: data });
  };

  getCurrentChildRow = (row: any, index: number) => {
// debugger;
    let data = _.cloneDeep(this.state.dayDetails);

    let returnIndex: number = -1;

    let timeSheetData = data[index].timesheet;
    if (timeSheetData.length <= 0) {
      returnIndex = -1;
    } else if (timeSheetData.length === 1) {
      returnIndex = 0;
    } else {
      for (let i = 0; i < timeSheetData.length; i++) {
        if (row.entryType === "Break") {
          if (timeSheetData[i].scheduleNo === row.scheduleNo) {
            returnIndex = i;
            break;
          }
        } else {
          if (timeSheetData[i].actualUniqueno === row.actualUniqueno) {
            returnIndex = i;
            break;
          }
        }
      }
    }

    return returnIndex;
  };

  resetPopupData = (row: any, rowIndex: any, mode: any, index: any) => {
    let data = _.cloneDeep(this.state.dayDetails);

    let childIndex = this.getCurrentChildRow(row, index);

    if (mode === "In") {
      data[index].timesheet[childIndex].lastInNotesdup = "";
      data[index].timesheet[childIndex].inTimedup = row.inTime;
      data[index].timesheet[childIndex].ddlInDate = row.inDate;
    } else {
      data[index].timesheet[childIndex].lastOutNotesdup = "";
      data[index].timesheet[childIndex].outTimedup = row.outTime;
      data[index].timesheet[childIndex].ddlOutDate = row.outDate;
    }

    this.setState({ dayDetails: data });
    if (mode === "In" || mode === "Out") {
      this.getSimleIDProfileImage(mode, row);
    }
    $(
      "#punch" + mode + "-tab-" + rowIndex + "-tab-details" + rowIndex + index
    ).removeClass("active");
    $(
      "#punch" +
      mode +
      "-tab-" +
      rowIndex +
      "-tab-edit-punch" +
      rowIndex +
      index
    ).removeClass("active");
    $(
      "#punch" + mode + "-tab-" + rowIndex + "-tab-smile-id" + rowIndex + index
    ).removeClass("active");
    $(
      "#punch" +
      mode +
      "-tab-" +
      rowIndex +
      "-tabpane-details" +
      rowIndex +
      index
    ).removeClass("active show");
    $(
      "#punch" +
      mode +
      "-tab-" +
      rowIndex +
      "-tabpane-edit-punch" +
      rowIndex +
      index
    ).removeClass("active show");
    $(
      "#punch" +
      mode +
      "-tab-" +
      rowIndex +
      "-tabpane-smile-id" +
      rowIndex +
      index
    ).removeClass("active show");

    if (
      $(
        "#punch" +
        mode +
        "-tab-" +
        rowIndex +
        "-tabpane-details" +
        rowIndex +
        index
      ).length > 0
    ) {
      $(
        "#punch" +
        mode +
        "-tab-" +
        rowIndex +
        "-tabpane-details" +
        rowIndex +
        index
      ).addClass("active show");
      $(
        "#punch" + mode + "-tab-" + rowIndex + "-tab-details" + rowIndex + index
      ).addClass("active");
    } else if (
      $(
        "#punch" +
        mode +
        "-tab-" +
        rowIndex +
        "-tabpane-edit-punch" +
        rowIndex +
        index
      ).length > 0
    ) {
      $(
        "#punch" +
        mode +
        "-tab-" +
        rowIndex +
        "-tabpane-edit-punch" +
        rowIndex +
        index
      ).addClass("active show");
      $(
        "#punch" +
        mode +
        "-tab-" +
        rowIndex +
        "-tab-edit-punch" +
        rowIndex +
        index
      ).addClass("active");
    } else if (
      $(
        "#punch" +
        mode +
        "-tab-" +
        rowIndex +
        "-tabpane-smile-id" +
        rowIndex +
        index
      ).length > 0
    ) {
      $(
        "#punch" +
        mode +
        "-tab-" +
        rowIndex +
        "-tabpane-smile-id" +
        rowIndex +
        index
      ).addClass("active show");
      $(
        "#punch" +
        mode +
        "-tab-" +
        rowIndex +
        "-tab-smile-id" +
        rowIndex +
        index
      ).addClass("active");
    }
  };

  resetActiveTab = (rowIndex, selectedtab, index, mode, row) => {
    if (
      $(
        "#punch" +
        mode +
        "-tab-" +
        rowIndex +
        "-tabpane-details" +
        rowIndex +
        index
      ).length > 0
    ) {
      $(
        "#punch" + mode + "-tab-" + rowIndex + "-tab-details" + rowIndex + index
      ).removeClass("active");
      $(
        "#punch" +
        mode +
        "-tab-" +
        rowIndex +
        "-tabpane-details" +
        rowIndex +
        index
      ).removeClass("active show");
    } else if (
      $(
        "#punch" +
        mode +
        "-tab-" +
        rowIndex +
        "-tabpane-edit-punch" +
        rowIndex +
        index
      ).length > 0
    ) {
      $(
        "#punch" +
        mode +
        "-tab-" +
        rowIndex +
        "-tab-edit-punch" +
        rowIndex +
        index
      ).removeClass("active");
      $(
        "#punch" +
        mode +
        "-tab-" +
        rowIndex +
        "-tabpane-edit-punch" +
        rowIndex +
        index
      ).removeClass("active show");
    } else if (
      $(
        "#punch" +
        mode +
        "-tab-" +
        rowIndex +
        "-tabpane-smile-id" +
        rowIndex +
        index
      ).length > 0
    ) {
      $(
        "#punch" +
        mode +
        "-tab-" +
        rowIndex +
        "-tab-smile-id" +
        rowIndex +
        index
      ).removeClass("active");
      $(
        "#punch" +
        mode +
        "-tab-" +
        rowIndex +
        "-tabpane-smile-id" +
        rowIndex +
        index
      ).removeClass("active show");
    }

    if (selectedtab === "details" + rowIndex + index) {
      $(
        "#punch" +
        mode +
        "-tab-" +
        rowIndex +
        "-tabpane-details" +
        rowIndex +
        index
      ).addClass("active show");
      $(
        "#punch" + mode + "-tab-" + rowIndex + "-tab-details" + rowIndex
      ).addClass("active");
    } else if (selectedtab === "edit-punch" + rowIndex + index) {
      $(
        "#punch" +
        mode +
        "-tab-" +
        rowIndex +
        "-tabpane-edit-punch" +
        rowIndex +
        index
      ).addClass("active show");
      $(
        "#punch" +
        mode +
        "-tab-" +
        rowIndex +
        "-tab-edit-punch" +
        rowIndex +
        index
      ).addClass("active");
    } else if (selectedtab === "smile-id" + rowIndex + index) {
      $(
        "#punch" +
        mode +
        "-tab-" +
        rowIndex +
        "-tabpane-smile-id" +
        rowIndex +
        index
      ).addClass("active show");
      $(
        "#punch" +
        mode +
        "-tab-" +
        rowIndex +
        "-tab-smile-id" +
        rowIndex +
        index
      ).addClass("active");
    }
  };

  getSimleIDProfileImage = (mode: any, row: any) => {
    let request = {
      hotelID: this.state.hotelID,
      uniqueID: mode === "In" ? row.inUniqueno : row.outUniqueno,
    };

    LaborSmileID.GetSimleIDProfile(request)
      .then(async (result: any | null) => {
        let staticPath = "";
        let basePath;
        if (result.success) {
          if (result.result !== "") {
            staticPath = "data:image/jpeg;base64,";
            basePath = result.result;
          } else {
            staticPath = "";
            basePath = profile;
          }
        } else {
          staticPath = "";
          basePath = profile;
        }
        let smileId = mode === "In" ? row.inUniqueno : row.outUniqueno;
        $("#" + smileId).attr("src", staticPath + basePath);
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error: ${err}`);
        reject();
      });
  };

  handleSave = (e: any, row: any, rowIndex: any, punchtype: string, index: any,confirmType:any,type:any) => {
    
    let currentPunchInMins = 0;
    let totalDayMin = 24 * 60;
    if (punchtype === "In") {
      if (row.lastInNotesdup.trim().length === 0) {
        Utils.toastError("Please enter explanation");
        return false;
      }
      if (row.lastInNotesdup.trim().length > 200) {
        Utils.toastError("Notes length can not exceed 200 characters");
        return false;
      }

      let punchHrs =
        Number(row.outTime.split(":")[0]) - Number(row.inTimedup.split(":")[0]);
      currentPunchInMins =
        punchHrs * 60 +
        Number(row.outTime.split(":")[1]) -
        Number(row.inTimedup.split(":")[1]);
    } else if (punchtype === "Out") {
      if (row.lastOutNotesdup.trim().length === 0) {
        Utils.toastError("Please enter explanation");
        return false;
      }
      if (row.lastOutNotesdup.trim().length > 200) {
        Utils.toastError("Notes length can not exceed 200 characters");
        return false;
      }

      let punchHrs = 0;
      if (
        Number(row.outTimedup.split(":")[0]) < Number(row.inTime.split(":")[0])
      ) {
        punchHrs =
          24 -
          Number(row.inTime.split(":")[0]) +
          Number(row.outTimedup.split(":")[0]);
      } else {
        punchHrs =
          Number(row.outTimedup.split(":")[0]) -
          Number(row.inTime.split(":")[0]);
      }
      currentPunchInMins =
        punchHrs * 60 +
        Number(row.outTimedup.split(":")[1]) -
        Number(row.inTime.split(":")[1]);
    }

    if (currentPunchInMins < 0) {
      currentPunchInMins = currentPunchInMins + totalDayMin;
    }
    let dataArr = _.cloneDeep(this.state.dayDetails[index].timesheet);
    let totalbreakDetail = dataArr.filter(
      (element: { scheduleNo: any }) => element.scheduleNo === row.scheduleNo
    );
    let total = 0;
    totalbreakDetail.map((arr: any) => {
      if (arr.total.length > 0) {
        let subTotal = 0;
        if (arr.total.includes(":")) {
          const dataArray = arr.total.split(":");
          let HH = Number(dataArray[0]);
          let MM = Number(dataArray[1]);
          if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
            HH = HH < 0 ? HH * -1 : HH;
            MM = MM < 0 ? MM * -1 : MM;
            subTotal = (HH * 60 + MM) * -1;
          } else {
            subTotal = HH * 60 + MM;
          }
          total = total + subTotal;
        }
      }
      return false;
    });

    let breaktotal = 0;
    totalbreakDetail.map((arr: any) => {
      if (arr.breakTime.length > 0) {
        let subTotal = 0;
        if (arr.breakTime.includes(":")) {
          const dataArray = arr.breakTime.split(":");
          let HH = Number(dataArray[0]);
          let MM = Number(dataArray[1]);
          if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
            HH = HH < 0 ? HH * -1 : HH;
            MM = MM < 0 ? MM * -1 : MM;
            subTotal = (HH * 60 + MM) * -1;
          } else {
            subTotal = HH * 60 + MM;
          }
          breaktotal = breaktotal + subTotal;
        }
      }
      return false;
    });

    let currentrowOldTotal = row.total;
    const dataArray = row.total.split(":");
    let HH = Number(dataArray[0]);
    let MM = Number(dataArray[1]);
    if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
      HH = HH < 0 ? HH * -1 : HH;
      MM = MM < 0 ? MM * -1 : MM;
      currentrowOldTotal = (HH * 60 + MM) * -1;
    } else {
      currentrowOldTotal = HH * 60 + MM;
    }

    let isBreakExist = false;
    totalbreakDetail.map((arr: any) => {
      if (arr.entryType === "Break") {
        isBreakExist = true;
        return true;
      }
      return false;
    });

    if (isBreakExist) {
      if (row.entryType !== "TimeOff") {
        if (total - currentrowOldTotal + currentPunchInMins < breaktotal) {
          Utils.toastError(
            "Actual hours should be greater than Break duration.",
            {}
          );
          return false;
        }

        if (Number(total) <= Number(row.breakHH) * 60 + Number(row.breakMM)) {
          Utils.toastError("Break duration should be less than Actual hours.");
          return false;
        }
      }
    }
    if (row.manuals === "UTO" || row.manuals === "PTO") {
      let saveActualDetail: any[] = [];
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID as any);
      let newItem: any = {};
      newItem.tenantID = tenantID;
      newItem.hotelID = this.state.hotelID;
      newItem.userName = row.employee;
      newItem.inTime = row.inTimedup;
      newItem.outTime = row.outTimedup;
      newItem.inDate = row.ddlInDate;
      newItem.outDate = row.ddlOutDate;
      if (punchtype === "In") {
        newItem.Memo = row.lastInNotesdup;
        if (row.manuals === "UTO") {
          newItem.pageSource = "EditUTOIntimeTimesheet";
        } else {
          newItem.pageSource = "EditPTOIntimeTimesheet";
        }
      } else {
        newItem.Memo = row.lastOutNotesdup;
        if (row.manuals === "UTO") {
          newItem.pageSource = "EditUTOOuttimeTimesheet";
        } else {
          newItem.pageSource = "EditPTOOuttimeTimesheet";
        }
      }
      newItem.scheduleId = row.scheduleNo;
      newItem.StartDate = row.inDate;
      newItem.outDate = row.outDate;
      newItem.entryType = punchtype;
      newItem.WeekStartDate = this.state.startdate;
      newItem.WeekEndDate = this.state.enddate;
      newItem.userUniqueno = 0;
      newItem.PTOUniqueno = row.ptouniqueno;
      newItem.isRequestEntry = "No";
      newItem.IsRemoveDNS = this.state.isRemoveDNS
      saveActualDetail.push(newItem);
    if(this.props.parentState.exportedDate !== "" && confirmType === "Yes")
    {
      if(this.props.parentState.exportFormat === "NETCHEX" && this.props.parentState.isPayrollIntegration === "Yes")
      {
        this.NetChexWarning(e,row,rowIndex,punchtype,index,confirmType,type,"");
      }
      else
      {
        this.ConfirmPayrollExport(e,row,rowIndex,punchtype,index,confirmType,type,"");
      }
      
    }
    else{
      this.updatePTOUTODetails(saveActualDetail);
    }
    } else {
      let saveActualDetail: any[] = [];
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID as any);
      let newItem: any = {};
      newItem.tenantID = tenantID;
      newItem.hotelID = this.state.hotelID;
      newItem.positionID = Number(row.positionID);
      newItem.selectDate = row.date;
      newItem.employeeName = row.employee;
      newItem.departmentID = Number(row.departmentID);
      newItem.inTime = this.formatDate(row.inTimedup);
      newItem.outTime = this.formatDate(row.outTimedup);
      newItem.inDate = row.ddlInDate;
      newItem.outDate = row.ddlOutDate;
      newItem.actualId = row.actualUniqueno;
      newItem.punchtype = punchtype;
      if (punchtype === "In") {
        newItem.notes = row.lastInNotesdup;
        newItem.ddlSelectedDate = row.ddlInDate;
        newItem.actionType = "EditPunchIn";
      } else {
        newItem.notes = row.lastOutNotesdup;
        newItem.ddlSelectedDate = row.ddlOutDate;

        newItem.actionType = "EditPunchOut";
      }
      newItem.entryType = row.entryType;
      newItem.flag = row.flag;
      newItem.scheduleId = row.scheduleNo;
      newItem.scheduleBreaktime =
        row.outTime !== ""
          ? "0.00"
          : row.breakTime === ""
            ? "0.00"
            : row.breakTime.toString().replace(":", ".");
      newItem.entryType = row.entryType;
      newItem.inUniqueNo = row.inUniqueno === null ? 0 : row.inUniqueno;
      newItem.outUniqueNo = row.outUniqueno === null ? 0 : row.outUniqueno;
      newItem.actualChildId = row.actualUniqueno;
      newItem.moduleName = "TimeSheet";
      newItem.pageSource = "TimeSheet";
      newItem.IsRemoveDNS = this.state.isRemoveDNS;
      saveActualDetail.push(newItem);
    if(this.props.parentState.exportedDate !== "" && confirmType === "Yes")
    {
      if(this.props.parentState.exportFormat === "NETCHEX" && this.props.parentState.isPayrollIntegration === "Yes")
        {
          this.NetChexWarning(e,row,rowIndex,punchtype,index,confirmType,type,"");
        }
        else{
      this.ConfirmPayrollExport(e,row,rowIndex,punchtype,index,confirmType,type,"");
        }
    }
    else{
      this.updateActualDetails(saveActualDetail, row, rowIndex, punchtype, index);

    }
  }
  };

  updatePTOUTODetails = (saveActualDetail: any) => {
    if (this.btnhHandleSave) {
      this.btnhHandleSave.current.disabled = true;
    }
    LaborPerformance.SaveLaborPTOUTO(saveActualDetail)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.success) {
            if (this.btnhHandleSave) {
              this.btnhHandleSave.current.disabled = false;
            }
            this.setState({ isDataChange: false });
            toast.success("Time Off edited successfully.");
            this.loadLaborTimesheetDetails();
            this.loadTimesheetDateWise("uploadPtoUto");
            this.props.slideOutRefresh();
            this.hideExportedDate();
          } else {
            if (this.btnhHandleSave) {
              this.btnhHandleSave.current.disabled = false;
            }
            Utils.toastError(result.message);
          }
        } else {
          if (this.btnhHandleSave) {
            this.btnhHandleSave.current.disabled = false;
          }
        }

        resolve();
      })
      .catch((err: any) => {
        if (this.btnhHandleSave) {
          this.btnhHandleSave.current.disabled = false;
        }
        Utils.toastError(`Server Error: ${err}`);
        reject();
      });
  };

  updateActualDetails = (saveActualDetail: any, row: any, rowIndex: any, punchtype: any, index: any) => {
    if (this.btnhHandleSave) {
      this.btnhHandleSave.current.disabled = true;
    }
    LaborPerformance.SaveLaborActual(saveActualDetail)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.result.messageCode === "Success") {
            if (this.btnhHandleSave) {
              this.btnhHandleSave.current.disabled = false;
            }
            this.setState({ isDataChange: false });
            toast.success("Manual entry successfully edited.");
            this.loadLaborTimesheetDetails();
            this.loadTimesheetDateWise("updateActualDetails");
            this.props.slideOutRefresh();
            this.hideExportedDate();
          } else {
            if (this.btnhHandleSave) {
              this.btnhHandleSave.current.disabled = false;
            }

            if (result.result.messageCode === "Scheduleoverlapped" && result.result.entryType === "8") {
              this.DNSConfirmationforUpdate(saveActualDetail[0], row, rowIndex, punchtype, index)
            }
            else {
              Utils.toastError(result.result.message, {
              });
            }
          }
        } else {
          if (this.btnhHandleSave) {
            this.btnhHandleSave.current.disabled = false;
          }
        }

        resolve();
      })
      .catch((err) => {
        if (this.btnhHandleSave) {
          this.btnhHandleSave.current.disabled = false;
        }
        Utils.toastError(`Server Error: ${err}`);
        reject();
      });

      if (this.closeModal) {
        this.closeModal();
      }
  };

  IsFormValid = (newActualDetails: any) => {
    let isValid = true;
    for (let x = 0; x < newActualDetails.length; x++) {
      if (newActualDetails[x].notes.trim().length === 0) {
        return false;
      } else {
        isValid = true;
      }
    }
    return isValid;
  };

  formatDate = (time: string) => {
    let replacement = "";
    if (time === "") {
      return replacement;
    }
    const dataArray = time.split(":");
    let hh = Number(dataArray[0]);
    let m = Number(dataArray[1]);
    let dd = "AM";
    let h = hh;
    if (h >= 12) {
      h = hh - 12;
      dd = "PM";
    }
    if (h === 0) {
      h = 12;
    }
    let min = m < 10 ? "0" + m : m;
    replacement = h + ":" + min;
    replacement += " " + dd;
    return replacement;
  };

  formatOutDate = (time: string) => {
    let replacement = "";
    if (time === "") {
      return replacement;
    }

    const dataArray = time.split(":");
    let hh = Number(dataArray[0]);
    let m = Number(dataArray[1]);
    let dd = "AM";
    let h = hh;
    if (h >= 12) {
      h = hh - 12;
      dd = "PM";
    }
    if (h === 0) {
      h = 12;
    }
    let min = m < 10 ? "0" + m : m;
    replacement = h + ":" + min;
    replacement += " " + dd;
    return replacement;
  };

  convertHourintoMinute = (total, paidPtoTotal) => {
    let a = total.split(":");
    let minutes = +a[0] * 60 + +a[1];
    let b = paidPtoTotal.split(":");
    let minutes1 = +b[0] * 60 + +b[1];
    let totalMinute = minutes + minutes1;

    let Totalhours = Math.floor(totalMinute / 60);
    let Totalminutes = totalMinute % 60;
    let TMinute: any;
    let THour: any;

    if (Number(Totalhours) < 10) {
      THour = `0${Totalhours}`;
    } else {
      THour = Totalhours;
    }
    if (Number(Totalminutes) < 10) {
      TMinute = `0${Totalminutes}`;
    } else {
      TMinute = Totalminutes;
    }

    let totalPtoPaid = THour + ":" + TMinute;
    return totalPtoPaid;
  };

  padLeadingZeros = (num, size) => {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  };

  loadLaborTimesheetDetails = () => {
    LaborTimeSheet.getLaborTimesheetDetails(
      this.props.data.hotelID,
      this.props.data.date,
      this.props.data.userName,
      "TimeSheet"
    )
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.data != null) {
            this.setState({
              total: result.total,
              positionWiseList: result.positionWiseList,
              positionList: result.total.positionList,
            });
            let grandTotal = this.convertHourintoMinute(
              this.state.total.totlhrs,
              result.data[4].total
            );

            let new_state = Object.assign({}, this.state);
            let fieldData = new_state.total;
            fieldData.totlhrs = grandTotal;
            let filterData: any[] = [];
            let utoData: any[] = [];
            filterData = _.cloneDeep(result.data);
            if (this.props.data.ruleType === "Standard Rule") {
              filterData = filterData.filter(
                (item) => item.title.toUpperCase() !== "DOT"
              );
            }
            if (result.total.isHolidayshow === false) {
              filterData = filterData.filter(
                (item) => item.title.toUpperCase() !== "HOLIDAY"
              );
            }
            utoData = filterData.filter(
              (item) => item.title.toUpperCase() === "UTO"
            );
            if (utoData[0].total === "00:00") {
              filterData = filterData.filter(
                (item) => item.title.toUpperCase() !== "UTO"
              );
            }
            this.setState({ data: filterData, allData: filterData });
          } else {
            this.setState({ total: { totlhrs: "0:00" } });
          }
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error: ${err}`);
      });
  };

  loadTimesheetDateWise = (callFrom: string, index: any = -1) => {
    if (callFrom === "componentMount") {
      this.setState({ pageLoader: true });
    }
    LaborTimeSheet.dayWiseTimeSheetDetails(
      this.props.data.hotelID,
      this.props.data.date,
      this.props.data.userName,
      "TimeSheet"
    )
      .then(async (result: any | null) => {
        if (result != null) {

          if (result.length > 0) {
            result = result.map((r) => {
              r.ref = React.createRef();
              r.refAddEntry = React.createRef();
              r.refAddShift = React.createRef();
              return r;
            });
          }
          let requestArray: any = [];

          // let objmatchDeptId={} as any;
          // objmatchDeptId.comments= "";
          // objmatchDeptId.imagepath= "";
          // objmatchDeptId.username= "";
          //  objmatchDeptId.commentdate="";
          // requestArray.push(objmatchDeptId);            



          for (let i = 0; i < result.length; i++) {
            for (let j = 0; j < result[i].timesheet.length; j++) {
              let pushObject = result[i].timesheet[j];
              pushObject.assocaiteedDate = moment(
                result[i].timesheet[j].date
              ).format("MM/DD/YY");
              pushObject.assocaiteedDateNotes = "";
              pushObject.ddlInDate = result[i].timesheet[j].inDate;
              pushObject.ddlOutDate = result[i].timesheet[j].outDate;
              result[i].timesheet[j] = pushObject;


              result[i].timesheet[j].ddlNoShowRef = React.createRef();
              result[i].timesheet[j].ddlChangeLogRef = React.createRef();
              result[i].timesheet[j].ddlShowCommentRef = React.createRef();
              result[i].timesheet[j].ddlChieldommentRef = React.createRef();
            }
          }



          if (index > -1) {
            if (result[index].timesheet.length === 1) {
              openRow[index] = result[index].timesheet[0]
            }
          }
          this.setState({
            dayDetails: result,
            forceapproval: result[0].timesheet[0].forceapproval,
            managedispute: result[0].timesheet[0].managedispute,
          }, () => {
            setTimeout(() => {
              this.scrollIntoViewDate()
            }, 0);
          });
          if (this.state.isDeepLinking)
            this.setState({
              shiftID: this.getUrlParameterValue(window.location.href, "S"),
            });
          $("#deepToTop").scrollTop(100);
          this.setState({ pageLoader: false });
        }
        resolve();
      })
      .catch((err) => {
        this.setState({ pageLoader: false });
        Utils.toastError(`Server Error: ${err}`);
        reject();
      });
  };

  getUrlParameterValue = (fullURL, keyName) => {
    keyName = keyName.replace(/[\\[]/, "\\[").replace(/[\]]/, "\\]");
    let regex = new RegExp("[\\?&]" + keyName + "=([^&#]*)");
    let results = regex.exec(fullURL);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  handleComparisonClick = (index: any, filteredBy: any) => {
    let condition = "";
    this.setState({ activeClass: index });
    let tableData: any[] = [];
    if (filteredBy === "All") {
      tableData = _.cloneDeep(this.state.allData);
      condition = "All";
    } else if (filteredBy === "Adjustments") {
      condition = "Adjustments";
    } else if (filteredBy === "Dispute") {
      condition = "Dispute";
    } else {
      tableData = _.cloneDeep(this.state.positionWiseList);
      tableData = tableData.filter((item) => item.position === filteredBy);
      condition = "All";
    }
    this.setState({ data: tableData, condition: condition });
  };

  headerFormatter = (column: any, colIndex: any) => {
    let header = this.state.data;
    if (header.length > 0) {
      let header1 = "";
      let header2 = "";
      switch (column.text) {
        case "total":
          header1 = "Total";
          header2 = "";
          break;
        case "days1":
          header1 = header[0].days1.dayname;
          header2 = header[0].days1.date;
          break;
        case "days2":
          header1 = header[0].days2.dayname;
          header2 = header[0].days2.date;
          break;
        case "days3":
          header1 = header[0].days3.dayname;
          header2 = header[0].days3.date;
          break;
        case "days4":
          header1 = header[0].days4.dayname;
          header2 = header[0].days4.date;
          break;
        case "days5":
          header1 = header[0].days5.dayname;
          header2 = header[0].days5.date;
          break;
        case "days6":
          header1 = header[0].days6.dayname;
          header2 = header[0].days6.date;
          break;
        case "days7":
          header1 = header[0].days7.dayname;
          header2 = header[0].days7.date;
          break;
      }
      return (
        <>
          {column.text !== "" && (
            <div
              onClick={() => this.onParentGridCellClick(column.text)}
              className="column-header"
            >
              <div className="header-data">
                <span className="header-day">{header1}</span>
              </div>
              <div className="header-date">{header2}</div>
            </div>
          )}
          {column.text === "" && <div className="vertical-line-th"></div>}
        </>
      );
    }
  };

  onParentGridCellClick = (cell) => {

    let header = this.state.data;
    let compDate = "";
    if (header.length > 0) {
      compDate =
        header[0][cell].date +
        "/" +
        new Date(this.state.startdate).getFullYear().toString().substr(2, 2);
      const ref = this.state.dayDetails.find((r) =>
        r.timesheet
          .map((x) => {
            let date = new Date(x.date);
            const _date =
              (date.getMonth() + 1).toString().padStart(2, "0") +
              "/" +
              date.getDate().toString().padStart(2, "0") +
              "/" +
              date.getFullYear().toString().substr(2, 2);
            return _date;
          })
          .includes(compDate)
      )?.ref?.current;
      if (ref) {
        ref.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    }
  };

  FormatHoursMinutesDuration = (HoursMints: number, totalBreak: number) => {
    HoursMints = HoursMints - Number(totalBreak);
    if (HoursMints < 0) {
      HoursMints = HoursMints * -1;
      let numHours = Math.floor(Number(HoursMints) / 60);
      let numMinutes = Math.round(Number(HoursMints) % 60);
      return `${"-"}${numHours}:${numMinutes < 10 ? "0" : ""}${numMinutes}`;
    } else {
      let numHours = Math.floor(Number(HoursMints) / 60);
      let numMinutes = Math.round(Number(HoursMints) % 60);
      return `${numHours}:${numMinutes < 10 ? "0" : ""}${numMinutes}`;
    }
  };

  getlaborPositionDeptByemployee = () => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let userName = storage === null ? 0 : (storage.userName as any);
    LaborDeptPosition.getlaborPositionDeptByemployee(
      this.state.hotelID,
      userName
    )
      .then(async (result: any[] | null) => {
        if (result != null) {
          this.setState({ alldepartmentlist: result });
        } else {
          this.setState({ alldepartmentlist: [] });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error: ${err}`);
        reject();
      });
  };

  departmentDropdownChange = (e: any, item: any, index: any) => {
    let data = _.cloneDeep(this.state.addEntry);
    data.newDepartmentId = item.departmentID;
    data.newDepartment = item.departmentName;
    data.newPositionId = 0;
    data.newPosition = "Select";
    this.setState({ addEntry: data, allpositionlist: item.laborPositionlist });
    $("#ddladdentrydept" + index).click();
  };

  positionDropdownChange = (e: any, item: any, index: any) => {
    let data = _.cloneDeep(this.state.addEntry);
    data.newPositionId = item.positionID;
    data.newPosition = item.positionName;
    this.setState({ addEntry: data });
    $("#ddladdentryposition" + index).click();
  };

  positionDropdownChangeBreak = (e: any, item: any) => {
    let data = _.cloneDeep(this.state.addEntry);
    data.newPositionId = item.positionID;
    data.newPosition = item.positionName;
    this.setState({ addEntry: data });
    this.breakpositionRef.current.classList.remove("show");
  };

  addNewEntryNotesChange = (e: any) => {
    let data = _.cloneDeep(this.state.addEntry);
    data.newNotes = e.target.value;
    this.setState({ addEntry: data });
  };

  newInTimeChange = (e: any) => {
    let data = _.cloneDeep(this.state.addEntry);
    data.newInTime = e.target.value;
    this.setState({ addEntry: data });
  };

  newOutTimeChange = (e: any) => {
    // 
    let data = _.cloneDeep(this.state.addEntry);
    data.newOutTime = e.target.value;
    this.setState({ addEntry: data });
  };

  saveNewEntry = (index: any, callType: string,confirmType :any, type:any) => {
   
      let dataArr: any[] = _.cloneDeep(this.state.dayDetails[index].timesheet);
      let data = _.cloneDeep(this.state.addEntry);
      if (callType !== "addEntry" && data.newDepartmentId === 0) {
        Utils.toastError("Please select department.");
        return false;
      }
      if (callType !== "addEntry" && data.newPositionId === 0) {
        Utils.toastError("Please select position.");
        return false;
      }
      if (data.newNotes.trim().length === 0) {
        Utils.toastError("Please enter explanation.");
        return false;
      }
      if (data.newNotes.trim().length > 200) {
        Utils.toastError("Notes length can not exceed 200 characters.");
        return false;
      }

      let saveActualDetail: any = [];
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID as any);

      let newItem: any = {};

      newItem.tenantID = tenantID;
      newItem.hotelID = this.state.hotelID;
      newItem.positionID =
        callType === "addEntry" ? openRow[index].positionID : data.newPositionId;
      newItem.selectDate = dataArr[0].date;
      newItem.employeeName = dataArr[0].employee;
      newItem.departmentID =
        callType === "addEntry"
          ? openRow[index].departmentID
          : Number(data.newDepartmentId);
      newItem.inTime = this.formatDate(data.newInTime);
      newItem.outTime = this.formatDate(data.newOutTime);
      newItem.actualId = 0;
      newItem.notes = data.newNotes;
      newItem.flag = dataArr[0].flag;
      newItem.scheduleId = callType === "addEntry" ? openRow[index].scheduleNo : 0;
      newItem.scheduleBreaktime = "";
      newItem.entryType = callType === "addEntry" ? openRow[index].entryType : "Actual";
      newItem.inDate = dataArr[0].date;
      newItem.outDate = dataArr[0].date;
      newItem.inUniqueNo = 0;
      newItem.outUniqueNo = 0;
      newItem.actualChildId = 0;
      newItem.punchtype = "";
      newItem.moduleName = "TimeSheet";
      newItem.actionType = "AddNewEntry";
      newItem.pageSource = "TimeSheet";
      newItem.IsRemoveDNS = this.state.isRemoveDNS;
      saveActualDetail.push(newItem);
      let laborActualmain: any = {};
      laborActualmain.laborActualDetails = saveActualDetail;
      if(this.props.parentState.exportedDate !== "" && confirmType === "Yes"){
        if(this.props.parentState.exportFormat === "NETCHEX" && this.props.parentState.isPayrollIntegration === "Yes")
          {
            this.NetChexWarning("","","","",index,confirmType,type,callType);
            this.setState({netchexWarningPopup : true});
          }
          else{
        this.ConfirmPayrollExport("","","","",index,confirmType,type,callType);
        this.setState({payrollExportPopup : true});
          }
       
      }
      else{
      this.saveActualDetails(saveActualDetail, callType, index);
      }
  };

  saveActualDetails = (saveActualDetail: any, callType: string, index: any) => {
    if( this.btnSaveNewEntry.current !== null){
    this.btnSaveNewEntry.current.disabled = true;}
    LaborPerformance.SaveLaborActual(saveActualDetail)
      .then(async (result: any | null) => {
        console.log("result.result", result.result);

        if (result != null) {
          if (result.result.messageCode === "Success") {
            if( this.btnSaveNewEntry.current !== null){
            this.btnSaveNewEntry.current.disabled = false;}
            this.setState({ isDataChange: false });
            let toastMessage = "Manual entry successfully added.";
            if (callType === "addShift") {
              toastMessage = "New shift successfully added.";
            }
            $("#add-shift-ddn").removeClass("show");
            $("#add-shift-ddn .dropdown-menu").removeClass("show");
            $("#add-entry-ddn").removeClass("show");
            $("#add-entry-ddn .dropdown-menu").removeClass("show");
            toast.success(toastMessage);
            this.loadLaborTimesheetDetails();
            this.loadTimesheetDateWise("saveActualDetails", index);
            this.props.slideOutRefresh();
            this.hideExportedDate();
          } else {
            if( this.btnSaveNewEntry.current !== null){
            this.btnSaveNewEntry.current.disabled = false;}
            if (result.result.messageCode === "Scheduleoverlapped" && result.result.entryType === "8") {
              this.DNSConfirmation(saveActualDetail[0].employeeName, index, callType)
            }
            else {
              Utils.toastError(result.result.message, {
                // position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          }
        } else {
          if( this.btnSaveNewEntry.current !== null){
          this.btnSaveNewEntry.current.disabled = false;}
        }

        resolve();
      })
      .catch((err: any) => {
        if( this.btnSaveNewEntry.current !== null){
        this.btnSaveNewEntry.current.disabled = false;}
        Utils.toastError(`Server Error: ${err}`);
        reject();
      });

      if (this.closeModal) {
        this.closeModal();
      }
  };

  // Add Break
  breakHHChange = (e: any, row, rowIndex, index: number) => {
    if (Number(e.target.value) < 60) {
      let data = _.cloneDeep(this.state.dayDetails);

      let childIndex = this.getCurrentChildRow(row, index);

      data[index].timesheet[childIndex].breakHH = e.target.value;
      if (
        (+data[index].timesheet[childIndex].breakMM <= 0 ||
          !data[index].timesheet[childIndex].breakMM) &&
        e.target.value > 0
      ) {
        data[index].timesheet[childIndex].breakMM = "00";
      }
      this.setState({ dayDetails: data });
    }
  };

  breakHHBlur = (e: any, row, rowIndex, index: number) => {
    if (Number(e.target.value) < 60) {
      let data = _.cloneDeep(this.state.dayDetails);

      let childIndex = this.getCurrentChildRow(row, index);

      data[index].timesheet[childIndex].breakHH = e.target.value
        .toString()
        .padStart(2, "0");
      this.setState({ dayDetails: data });
    }
  };

  breakMMChange = (e: any, row, rowIndex, index: number) => {
    if (Number(e.target.value) < 60) {
      let data = _.cloneDeep(this.state.dayDetails);

      let childIndex = this.getCurrentChildRow(row, index);

      data[index].timesheet[childIndex].breakMM = e.target.value;
      if (
        (+data[index].timesheet[childIndex].breakHH <= 0 ||
          !data[index].timesheet[childIndex].breakHH) &&
        e.target.value > 0
      ) {
        data[index].timesheet[childIndex].breakHH = "00";
      }
      if (!data[index].timesheet[childIndex].breakHH) {
        data[index].timesheet[childIndex].breakHH = "00";
      }
      this.setState({ dayDetails: data });
    }
  };

  breakMMBlur = (e: any, row, rowIndex, index: number) => {
    if (Number(e.target.value) < 60) {
      let data = _.cloneDeep(this.state.dayDetails);
      let childIndex = this.getCurrentChildRow(row, index);
      if (!data[index].timesheet[childIndex].breakHH) {
        data[index].timesheet[childIndex].breakHH = "00";
      }
      data[index].timesheet[childIndex].breakMM = e.target.value
        .toString()
        .padStart(2, "0");
      this.setState({ dayDetails: data });
    }
  };

  saveBreak = (row: any, rowIndex: any, index: number,confirmType:any,type:any) => {
   
    let data = _.cloneDeep(this.state.dayDetails);

    let dataArr = data[index].timesheet.filter(
      (a: any) => a.scheduleNo === row.scheduleNo
    );

    if (row.breakHH !== "" && row.breakMM !== "") {
      if (Number(row.breakHH) <= 0 && Number(row.breakMM) <= 0) {
        Utils.toastError("Please enter Break duration in HH:MM format");
        return false;
      }
      if (row.breakMM.length !== 2) {
        Utils.toastError("Please enter Break duration in HH:MM format");
        return false;
      }
      if (Number(row.breakMM) > 60) {
        Utils.toastError("Minutes should be less than 60");
        return false;
      }
    } else {
      Utils.toastError("Please enter Break duration in HH:MM format");
      return false;
    }
    if (row.breakNotes.trim().length === 0) {
      Utils.toastError("Please enter Break details.");
      return false;
    }
    if (row.breakNotes.trim().length > 200) {
      Utils.toastError("Break details length can not exceed 200 characters");
      return false;
    }

    let totalbreakDetail = dataArr;
    let total = 0;
    totalbreakDetail.map((arr: any) => {
      if (arr.total.length > 0) {
        let subTotal = 0;
        if (arr.total.includes(":")) {
          const dataArray = arr.total.split(":");
          let HH = Number(dataArray[0]);
          let MM = Number(dataArray[1]);
          if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
            HH = HH < 0 ? HH * -1 : HH;
            MM = MM < 0 ? MM * -1 : MM;
            subTotal = (HH * 60 + MM) * -1;
          } else {
            subTotal = HH * 60 + MM;
          }
          total = total + subTotal;
        }
      }
      return false;
    });
    if (Number(total) <= Number(row.breakHH) * 60 + Number(row.breakMM)) {
      Utils.toastError("Break duration should be less than Actual hours.");
      return false;
    }

    let breakTime = row.breakHH + "." + row.breakMM;
    let saveActualDetail: any[] = [];
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let newItem: any = {};
    newItem.tenantID = tenantID;
    newItem.hotelID = this.state.hotelID;
    newItem.positionID = Number(row.positionID);
    newItem.selectDate = row.date;
    newItem.employeeName = row.employee;
    newItem.departmentID = Number(row.departmentID);
    newItem.inTime = "";
    newItem.outTime = "";
    newItem.actualId = row.actualUniqueno;
    newItem.notes = row.breakNotes;
    newItem.flag = "";
    newItem.scheduleId = row.scheduleNo;
    newItem.scheduleBreaktime = breakTime;
    newItem.entryType = "Break";
    newItem.inDate = "";
    newItem.outDate = "";
    newItem.inUniqueNo = 0;
    newItem.outUniqueNo = 0;
    newItem.actualChildId = 0;
    newItem.punchtype = "";
    newItem.moduleName = "TimeSheet";
    if (row.actions === "addBreak") {
      newItem.PageSource = "AddBreakTimeSheet";
    }
    saveActualDetail.push(newItem);
    let laborActualmain: any = {};
    laborActualmain.laborActualDetails = saveActualDetail;
    if(this.props.parentState.exportedDate !== "" && confirmType === "Yes"){
      if(this.props.parentState.exportFormat === "NETCHEX" && this.props.parentState.isPayrollIntegration === "Yes")
        {
          this.NetChexWarning("",row,rowIndex,"",index,confirmType,type,"") 
          this.setState({netchexWarningPopup : true});
        }
        else{
      this.ConfirmPayrollExport("",row,rowIndex,"",index,confirmType,type,"") 
      this.setState({payrollExportPopup : true});
        }
    }
    else{
    this.saveBreakDetails(saveActualDetail);
    }
  
  };

  saveBreakDetails = (saveActualDetail: any) => {
    if(this.btnSaveBreak.current !== null)
    {
    this.btnSaveBreak.current.disabled = true;}
    LaborPerformance.AddManualBreak(saveActualDetail)
      .then(async (result: any | null) => {
        if(this.btnSaveBreak.current !== null)
    {
        this.btnSaveBreak.current.disabled = false;}
        if (result != null) {
          if (result.result.messageCode === "Success") {
            this.setState({ isDataChange: false });
            toast.success("Manual Break successfully added.");
            this.loadLaborTimesheetDetails();
            this.loadTimesheetDateWise("saveBreakDetails");
            this.props.slideOutRefresh();
            this.hideExportedDate();
          } else {
            Utils.toastError(result.result.message);
          }
        }

        resolve();
      })
      .catch((err: any) => {
        if(this.btnSaveBreak.current !== null)
    {
        this.btnSaveBreak.current.disabled = false;}
        Utils.toastError(`Server Error: ${err}`);
        reject();
      });
      if (this.closeModal) {
        this.closeModal();
      }
  };

  changebreakHH = (e: any, row: any, rowIndex: any, index: number) => {
    if (Number(e.target.value) < 60) {
      let data = _.cloneDeep(this.state.dayDetails);

      let childIndex = this.getCurrentChildRow(row, index);

      data[index].timesheet[childIndex].breakHH = e.target.value;
      this.setState({ dayDetails: data });
    }
  };

  blurbreakHH = (e: any, row: any, rowIndex: any, index: number) => {
    if (Number(e.target.value) < 60) {
      let data = _.cloneDeep(this.state.dayDetails);

      let childIndex = this.getCurrentChildRow(row, index);

      data[index].timesheet[childIndex].breakHH = e.target.value
        .toString()
        .padStart(2, "0");
      this.setState({ dayDetails: data });
    }
  };

  changebreakMM = (e: any, row: any, rowIndex: any, index: number) => {
    if (Number(e.target.value) < 60) {
      let data = _.cloneDeep(this.state.dayDetails);

      let childIndex = this.getCurrentChildRow(row, index);

      if (!data[index].timesheet[childIndex].breakHH) {
        data[index].timesheet[childIndex].breakHH = "00";
      }
      data[index].timesheet[childIndex].breakMM = e.target.value;
      this.setState({ dayDetails: data });
    }
  };

  blurbreakMM = (e: any, row: any, rowIndex: any, index: number) => {
    if (Number(e.target.value) < 60) {
      let data = _.cloneDeep(this.state.dayDetails);

      let childIndex = this.getCurrentChildRow(row, index);

      if (!data[index].timesheet[childIndex].breakHH) {
        data[index].timesheet[childIndex].breakHH = "00";
      }
      data[index].timesheet[childIndex].breakMM = e.target.value
        .toString()
        .padStart(2, "0");

      this.setState({ dayDetails: data });
    }
  };

  updateBreak = (e: any, row: any, rowIndex: any, index) => {
    if (row.breakHH !== "" && row.breakMM !== "") {
      if (Number(row.breakHH) <= 0 && Number(row.breakMM) <= 0) {
        Utils.toastError("Please enter Break duration in HH:MM format");
        return false;
      }
      if (row.breakMM.length !== 2) {
        Utils.toastError("Please enter Break duration in HH:MM format");
        return false;
      }
      if (Number(row.breakMM) > 60) {
        Utils.toastError("Minutes should be less than 60");
        return false;
      }
    } else {
      Utils.toastError("Please enter Break duration in HH:MM format");
      return false;
    }

    if (+row.breakHH <= 0 && +row.breakMM <= 0) {
      Utils.toastError("Please enter valid Break");
      return false;
    }

    if (row.lastOutNotesdup.trim().length === 0) {
      Utils.toastError("Please enter Break details.");
      return false;
    }
    if (row.lastOutNotesdup.trim().length > 200) {
      Utils.toastError("Break details length can not exceed 200 characters");
      return false;
    }

    let data = _.cloneDeep(this.state.dayDetails);

    let totalbreakDetail = data[index].timesheet.filter(
      (elem: any) => elem.scheduleNo === row.scheduleNo
    );

    // let data = _.cloneDeep(this.state.dayDetails);

    // let childIndex = this.getCurrentChildRow(row, index);

    // let dataArr = data[index].timesheet;

    // let dataArr = _.cloneDeep(this.state.dayDetails[rowIndex].timesheet);
    // let totalbreakDetail = dataArr.filter(
    //   (element: { scheduleNo: any }) => element.scheduleNo === row.scheduleNo
    // );
    let total = 0;
    totalbreakDetail.map((arr: any) => {
      if (arr.total.length > 0) {
        let subTotal = 0;
        if (arr.total.includes(":")) {
          const dataArray = arr.total.split(":");
          let HH = Number(dataArray[0]);
          let MM = Number(dataArray[1]);
          if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
            HH = HH < 0 ? HH * -1 : HH;
            MM = MM < 0 ? MM * -1 : MM;
            subTotal = (HH * 60 + MM) * -1;
          } else {
            subTotal = HH * 60 + MM;
          }
          total = total + subTotal;
        }
      }
      return false;
    });
    if (Number(total) <= Number(row.breakHH) * 60 + Number(row.breakMM)) {
      Utils.toastError("Break duration should be less than Actual hours.");
      return false;
    }

    let breakTime = row.breakHH + "." + row.breakMM;
    let saveActualDetail: any[] = [];
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let newItem: any = {};
    newItem.tenantID = tenantID;
    newItem.hotelID = this.state.hotelID;
    newItem.positionID = Number(row.positionID);
    newItem.selectDate = row.date;
    newItem.employeeName = row.employee;
    newItem.departmentID = Number(row.departmentID);
    newItem.inTime = "";
    newItem.outTime = "";
    newItem.actualId = row.actualUniqueno;
    newItem.notes = row.lastOutNotesdup;
    newItem.flag = row.flag;
    newItem.scheduleId = row.scheduleNo;
    newItem.scheduleBreaktime = breakTime;
    newItem.entryType = "Break";
    newItem.inDate = "";
    newItem.outDate = "";
    newItem.inUniqueNo = 0;
    newItem.outUniqueNo = 0;
    newItem.actualChildId = row.actualUniqueno;
    newItem.punchtype = "";
    newItem.moduleName = "TimeSheet";
    newItem.scheduleId = row.scheduleNo;
    newItem.PageSource = "EditBreakTimeSheet";
    saveActualDetail.push(newItem);
    let laborActualmain: any = {};
    laborActualmain.laborActualDetails = saveActualDetail;
    this.updateBreakDetails(saveActualDetail);
  };

  updateBreakDetails = (saveActualDetail: any) => {
    this.btnUpdateBreak.current.disabled = true;
    LaborPerformance.AddManualBreak(saveActualDetail)
      .then(async (result: any | null) => {
        this.btnUpdateBreak.current.disabled = false;
        if (result != null) {
          if (result.result.messageCode === "Success") {
            this.setState({ isDataChange: false });
            toast.success("Manual Break successfully edited.", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.loadLaborTimesheetDetails();
            this.loadTimesheetDateWise("updateBreakDetails");
            this.props.slideOutRefresh();
            this.hideExportedDate();
          } else {
            Utils.toastError(result.result.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }

        resolve();
      })
      .catch((err: any) => {
        this.btnUpdateBreak.current.disabled = false;
        Utils.toastError(`Server Error: ${err}`, {
          // position: toast.POSITION.BOTTOM_RIGHT,
          // containerId: "laborTimeSheetMain",
        });
        reject();
      });
  };

  actionButton = (row: any, rowIndex: number, mode: any, index: number) => {
    debugger;
    let data = _.cloneDeep(this.state.dayDetails);

    let childIndex = this.getCurrentChildRow(row, index);

    data[index].timesheet[childIndex].actions = mode;
    data[index].timesheet[childIndex].splitDepartment = "Select";
    data[index].timesheet[childIndex].splitDepartmentId = 0;
    data[index].timesheet[childIndex].splitNotes = "";
    data[index].timesheet[childIndex].splitPosition = "Select";
    data[index].timesheet[childIndex].splitPositionId = 0;
    data[index].timesheet[childIndex].splitTime = "00:00";
    data[index].timesheet[childIndex].breakHH = "";
    data[index].timesheet[childIndex].breakMM = "";
    data[index].timesheet[childIndex].breakNotes = "";



    this.setState({ dayDetails: data });
  };

  checkAllowBreak = (row: any, rowIndex: number, index: number) => {
    let data = _.cloneDeep(this.state.dayDetails);
    var breakarr = data[index].timesheet.filter(
      (x: any) => x.entryType === "Break" && x.scheduleNo === row.scheduleNo
    );
    if (breakarr?.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  // Split Entry
  splitTimeChange = (e: any, row: any, rowIndex: any, index: number) => {
    let data = _.cloneDeep(this.state.dayDetails);

    let childIndex = this.getCurrentChildRow(row, index);

    data[index].timesheet[childIndex].splitTime = e.target.value;
    this.setState({ dayDetails: data });
  };

  departmentSplitChange = (
    e: any,
    row: any,
    item: any,
    rowIndex: any,
    index: number
  ) => {
    let data = _.cloneDeep(this.state.dayDetails);

    let childIndex = this.getCurrentChildRow(row, index);

    data[index].timesheet[childIndex].splitDepartmentId = item.departmentID;
    data[index].timesheet[childIndex].splitDepartment = item.departmentName;
    data[index].timesheet[childIndex].splitPosition = "Select";
    data[index].timesheet[childIndex].splitPositionId = 0;
    this.setState({
      dayDetails: data,
      allpositionlist: item.laborPositionlist,
    });
  };

  assocaiteDateChange = (row: any, item: any, index: number) => {
    let data = _.cloneDeep(this.state.dayDetails);

    let childIndex = this.getCurrentChildRow(row, index);

    data[index].timesheet[childIndex].assocaiteedDate = item;

    this.setState({
      dayDetails: data,
    });
  };

  assocaitedNotesChange = (e: any, row: any, index: any) => {
    let data = _.cloneDeep(this.state.dayDetails);

    let childIndex = this.getCurrentChildRow(row, index);

    data[index].timesheet[childIndex].assocaiteedDateNotes = e.target.value;

    this.setState({
      dayDetails: data,
    });
  };

  saveAssocatedDateChange = (row: any, index: any,confirmType:any,type:any) => {

      if(this.btnAssociateSave.current !== null){
      this.btnAssociateSave.current.disabled = true;}
      let data = _.cloneDeep(this.state.dayDetails);

      let childIndex = this.getCurrentChildRow(row, index);
      if (data[index].timesheet[childIndex].assocaiteedDateNotes === "") {
        Utils.toastError("Please enter explanation");
        return;
      }

      // 
      let saveActualDetail: any[] = [];
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID as any);
      let newItem: any = {};
      newItem.tenantID = tenantID;
      newItem.hotelID = this.state.hotelID;
      newItem.positionID = Number(row.positionID);
      newItem.selectDate = row.assocaiteedDate;
      newItem.employeeName = row.employee;
      newItem.departmentID = Number(row.departmentID);
      newItem.inTime = this.formatDate(row.inTimedup);
      newItem.outTime = this.formatDate(row.outTimedup);
      newItem.inDate = row.ddlInDate;
      newItem.outDate = row.ddlOutDate;
      newItem.actualId = row.actualUniqueno;
      newItem.notes = row.assocaiteedDateNotes;
      newItem.actionType = "ChangeAssociateDate";
      newItem.entryType = row.entryType;
      newItem.flag = row.flag;
      newItem.scheduleId = row.scheduleNo;
      newItem.scheduleBreaktime =
        row.outTime !== ""
          ? "0.00"
          : row.breakTime === ""
            ? "0.00"
            : row.breakTime.toString().replace(":", ".");
      newItem.entryType = row.entryType;
      newItem.inUniqueNo = row.inUniqueno === null ? 0 : row.inUniqueno;
      newItem.outUniqueNo = row.outUniqueno === null ? 0 : row.outUniqueno;
      newItem.actualChildId = row.actualUniqueno;
      newItem.moduleName = "TimeSheet";
      newItem.pageSource = "TimeSheet";
      saveActualDetail.push(newItem);
      debugger;
    
       if(this.props.parentState.exportedDate !== "" && confirmType === "Yes")
        {
            // if(row.assocaiteedDate > this.props.parentState.payPeriodDates[1])
            // {
            //   if(this.props.parentState.associateExportedNext === "")
            //     {
            //       this.SaveAssociateDate(saveActualDetail)
            //     }
            //     else
            //     {
            //       this.ConfirmPayrollExport("",row,"","",index,confirmType,type,"");
            //     }
            // }
            // else if(row.assocaiteedDate < this.props.parentState.payPeriodDates[0])
            // {
            //       if(this.props.parentState.associateExportedPre === "")
            //       {
            //         this.SaveAssociateDate(saveActualDetail)
            //       }
            //       else
            //       {
            //         this.ConfirmPayrollExport("",row,"","",index,confirmType,type,"");
            //       }
            // }
            // else{
            //   this.ConfirmPayrollExport("",row,"","",index,confirmType,type,"");
            // }
            this.ConditionCheckIfExported(saveActualDetail,row,index,confirmType,type)
        }
        else
        {
          this.ConditionCheckIfUnExported(saveActualDetail,row,index,confirmType,type)
          // this.SaveAssociateDate(saveActualDetail)
         
        }

  };

  ConditionCheckIfExported = (saveActualDetail,row,index,confirmType,type) => {
    if(row.assocaiteedDate > this.props.parentState.payPeriodDates[1])
      {
        if(this.props.parentState.associateExportedNext === "")
          {
            this.SaveAssociateDate(saveActualDetail)
          }
          else
          {
            if(this.props.parentState.exportFormat === "NETCHEX" && this.props.parentState.isPayrollIntegration === "Yes")
              {
                this.NetChexWarning("",row,"","",index,confirmType,type,"");
              }
              else{
            this.ConfirmPayrollExport("",row,"","",index,confirmType,type,"");
              }
          }
      }
      else if(row.assocaiteedDate < this.props.parentState.payPeriodDates[0])
      {
            if(this.props.parentState.associateExportedPre === "")
            {
              this.SaveAssociateDate(saveActualDetail)
            }
            else
            {
              if(this.props.parentState.exportFormat === "NETCHEX" && this.props.parentState.isPayrollIntegration === "Yes")
                {
                  this.NetChexWarning("",row,"","",index,confirmType,type,"");
                }
                else{
              this.ConfirmPayrollExport("",row,"","",index,confirmType,type,"");
                }
            }
      }
      else{
        if(this.props.parentState.exportFormat === "NETCHEX" && this.props.parentState.isPayrollIntegration === "Yes")
          {
            this.NetChexWarning("",row,"","",index,confirmType,type,"");
          }
          else{
        this.ConfirmPayrollExport("",row,"","",index,confirmType,type,"");
          }
      }
  }

  ConditionCheckIfUnExported = (saveActualDetail,row,index,confirmType,type) => {
    if(row.assocaiteedDate > this.props.parentState.payPeriodDates[1])
      {
        if(this.props.parentState.associateExportedNext === "")
          {
            this.SaveAssociateDate(saveActualDetail)
          }
          else
          {
            if(this.props.parentState.exportFormat === "NETCHEX" && this.props.parentState.isPayrollIntegration === "Yes")
              {
                this.NetChexWarning("",row,"","",index,confirmType,type,"");
              }
              else{
            this.ConfirmPayrollExport("",row,"","",index,confirmType,type,"");
              }
          }
      }
      else if(row.assocaiteedDate < this.props.parentState.payPeriodDates[0])
      {
            if(this.props.parentState.associateExportedPre === "")
            {
              this.SaveAssociateDate(saveActualDetail)
            }
            else
            {
              if(this.props.parentState.exportFormat === "NETCHEX" && this.props.parentState.isPayrollIntegration === "Yes")
                {
                  this.NetChexWarning("",row,"","",index,confirmType,type,"");
                }
                else{
              this.ConfirmPayrollExport("",row,"","",index,confirmType,type,"");
                }
            }
      }
      else{
        this.SaveAssociateDate(saveActualDetail)
      }
  }

  SaveAssociateDate = (saveActualDetail) => {
    LaborPerformance.SaveLaborAssociateShift(saveActualDetail)
    .then(async (result: any | null) => {
      if (result != null) {
        if (result.result.messageCode === "Success") {
          if(this.btnAssociateSave.current !== null){
          this.btnAssociateSave.current.disabled = false;}
          this.setState({ isDataChange: false });
          toast.success("Associate date changed successfully.");
          this.loadLaborTimesheetDetails();
          this.loadTimesheetDateWise("updateActualDetails");
          this.props.slideOutRefresh();
          this.hideExportedDate();
        } else {
          if(this.btnAssociateSave.current !== null){
          this.btnAssociateSave.current.disabled = false;}
          Utils.toastError(result.result.message);
        }
      }
      resolve();
    })
    .catch((err) => {
      if(this.btnAssociateSave.current !== null){
      this.btnAssociateSave.current.disabled = false;}
      Utils.toastError(`Server Error: ${err}`);
      reject();
    });

    if (this.closeModal) {
      this.closeModal();
    }
  }

  positionSplitChange = (
    e: any,
    row: any,
    item: any,
    rowIndex: any,
    index: number
  ) => {
    let data = _.cloneDeep(this.state.dayDetails);

    let childIndex = this.getCurrentChildRow(row, index);

    data[index].timesheet[childIndex].splitPosition = item.positionName;
    data[index].timesheet[childIndex].splitPositionId = item.positionID;
    this.setState({ dayDetails: data });
  };

  noteSplitChange = (e: any, row: any, rowIndex: any, index: number) => {
    let data = _.cloneDeep(this.state.dayDetails);

    let childIndex = this.getCurrentChildRow(row, index);

    data[index].timesheet[childIndex].splitNotes = e.target.value;
    this.setState({ dayDetails: data });
  };

  breakNoteChange = (e: any, row: any, rowIndex: any, index: number) => {
    let data = _.cloneDeep(this.state.dayDetails);

    let childIndex = this.getCurrentChildRow(row, index);

    data[index].timesheet[childIndex].breakNotes = e.target.value;
    this.setState({ dayDetails: data });
  };

  hoursMinutesToMinutes = (data: any) => {
    let total = 0;
    if (data.trim().length > 0) {
      if (data.includes(":")) {
        const dataArray = data.split(":");
        let HH = Number(dataArray[0]);
        let MM = Number(dataArray[1]);
        if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
          HH = HH < 0 ? HH * -1 : HH;
          MM = MM < 0 ? MM * -1 : MM;
          total = (HH * 60 + MM) * -1;
        } else {
          total = HH * 60 + MM;
        }
      } else {
        total = 0;
      }
    } else {
      total = 0;
    }
    return total;
  };

  isSplitTimeValid = (inTime: any, splitTime: any, outTime: any) => {
    let isNextDayShift = false;
    isNextDayShift =
      this.hoursMinutesToMinutes(inTime) > this.hoursMinutesToMinutes(outTime)
        ? true
        : false;
    if (isNextDayShift) {
      if (
        this.hoursMinutesToMinutes(inTime) <
        this.hoursMinutesToMinutes(splitTime) &&
        this.hoursMinutesToMinutes(splitTime) <=
        this.hoursMinutesToMinutes("24:00")
      ) {
        return true;
      } else if (
        this.hoursMinutesToMinutes("00:00") <=
        this.hoursMinutesToMinutes(splitTime) &&
        this.hoursMinutesToMinutes(splitTime) <
        this.hoursMinutesToMinutes(outTime)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        this.hoursMinutesToMinutes(inTime) <
        this.hoursMinutesToMinutes(splitTime) &&
        this.hoursMinutesToMinutes(splitTime) <
        this.hoursMinutesToMinutes(outTime)
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  saveSplitEntry = (row: any, index: any,confirmType : any, type:any) => {
  
    if (row.splitTime.length === 0) {
      Utils.toastError("Please enter Split Time.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    if (row.splitDepartmentId === 0) {
      Utils.toastError("Please select department.", {
      });
      return false;
    }
    if (row.splitPositionId === 0) {
      Utils.toastError("Please select position.", {
      });
      return false;
    }
    if (row.splitNotes.trim().length === 0) {
      Utils.toastError("Please enter notes.", {
      });
      return false;
    }
    if (row.splitNotes.trim().length > 200) {
      Utils.toastError("Notes length can not exceed 200 characters.", {
      });
      return false;
    }
    let dataArr: any[] = _.cloneDeep(
      this.state.dayDetails[index].timesheet.filter(
        (a: any) => a.entryType !== "TimeOff" && a.scheduleNo === row.scheduleNo
      )
    );

    let totalbreakDetail = dataArr;
    let total = 0;
    totalbreakDetail.map((arr: any) => {
      if (arr.total.length > 0) {
        let subTotal = 0;
        if (arr.total.includes(":")) {
          const dataArray = arr.total.split(":");
          let HH = Number(dataArray[0]);
          let MM = Number(dataArray[1]);
          if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
            HH = HH < 0 ? HH * -1 : HH;
            MM = MM < 0 ? MM * -1 : MM;
            subTotal = (HH * 60 + MM) * -1;
          } else {
            subTotal = HH * 60 + MM;
          }
          total = total + subTotal;
        }
      }
      return false;
    });

    let saveActualDetail: any = [];
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    for (let i = 0; i < dataArr.length; i++) {
      if (row.actualUniqueno === dataArr[i].actualUniqueno) {
        if (
          this.isSplitTimeValid(
            dataArr[i].inTime,
            dataArr[i].splitTime,
            dataArr[i].outTime
          )
        ) {
          // Existing Split Row
          let newItem: any = {};
          newItem.actualId = dataArr[i].actualUniqueno;
          newItem.actualChildId = dataArr[i].actualUniqueno;
          newItem.tenantID = tenantID;
          newItem.hotelID = this.state.hotelID;
          newItem.positionID = Number(dataArr[i].positionID);
          newItem.selectDate = dataArr[i].date;
          newItem.employeeName = dataArr[i].employee;
          newItem.departmentID = Number(dataArr[i].departmentID);
          newItem.inTime = this.formatDate(dataArr[i].inTime);
          newItem.outTime = this.formatDate(dataArr[i].splitTime);
          newItem.notes = dataArr[i].notes;
          newItem.flag = dataArr[i].flag;
          newItem.scheduleId = dataArr[i].scheduleNo;
          newItem.scheduleBreaktime = "";
          newItem.entryType = "split";
          newItem.inDate = dataArr[i].inDate;
          newItem.outDate = dataArr[i].outDate;
          newItem.inUniqueNo = dataArr[i].inUniqueno;
          newItem.outUniqueNo = 0;
          newItem.punchtype = "out";
          newItem.moduleName = "TimeSheet";
          newItem.actionType = "SplitEntry";
          newItem.pageSource = "TimeSheet";
          saveActualDetail.push(newItem);

          // New Split Row
          let newSplitItem: any = {};
          newSplitItem.actualId = 0;
          newSplitItem.actualChildId = 0;
          newSplitItem.tenantID = tenantID;
          newSplitItem.hotelID = this.state.hotelID;
          newSplitItem.positionID = Number(dataArr[i].splitPositionId);
          newSplitItem.selectDate = dataArr[i].date;
          newSplitItem.employeeName = dataArr[i].employee;
          newSplitItem.departmentID = Number(dataArr[i].splitDepartmentId);
          newSplitItem.inTime = this.formatDate(dataArr[i].splitTime);
          newSplitItem.outTime = this.formatDate(dataArr[i].outTime);
          newSplitItem.notes = dataArr[i].splitNotes;
          newSplitItem.flag = dataArr[i].flag;
          newSplitItem.scheduleId = 0;
          newSplitItem.scheduleBreaktime = "";
          newSplitItem.entryType = "split";
          newSplitItem.inDate = dataArr[i].inDate;
          newSplitItem.outDate = dataArr[i].outDate;
          newSplitItem.inUniqueNo = 0;
          newSplitItem.outUniqueNo = dataArr[i].outUniqueno;
          newSplitItem.punchtype = "";
          newSplitItem.moduleName = "TimeSheet";
          newSplitItem.actionType = "SplitEntry";
          newSplitItem.pageSource = "TimeSheet";
          saveActualDetail.push(newSplitItem);
        } else {
          Utils.toastError(
            "Please enter split time between Punch In and Punch Out time.",
            {
            }
          );
          return false;
        }
      }
    }

    let breakArr = dataArr.filter(
      (x) => x.entryType === "Break" && x.scheduleNo === row.scheduleNo
    );
    if (breakArr?.length > 0) {
      let breakMinutes = this.hoursMinutesToMinutes(breakArr?.[0].breakTime);
      let shift1 = saveActualDetail?.length > 0 ? saveActualDetail[0] : {};
      let shift2 = saveActualDetail?.length > 1 ? saveActualDetail[1] : {};
      if (shift1 == null || shift1 === undefined) return;
      if (shift2 == null || shift2 === undefined) return;
      let shift2InTime = shift2.inDate + " " + shift2.inTime;
      let shift2OutTime = shift2.outDate + " " + shift2.outTime;
      let shift2Minutes = Utils.getMinutesBetweenInOutDatetime(
        shift2InTime,
        shift2OutTime
      );

      total = total - shift2Minutes;
      if (breakMinutes < total) {
        let newItem: any = {};
        newItem.tenantID = tenantID;
        newItem.hotelID = this.state.hotelID;
        newItem.positionID = Number(shift1.positionID);
        newItem.selectDate = row.date;
        newItem.employeeName = row.employee;
        newItem.departmentID = Number(shift1.departmentID);
        newItem.inTime = "";
        newItem.outTime = "";
        newItem.actualId = shift1.actualId;
        newItem.notes = breakArr?.[0].notes;
        newItem.flag = breakArr?.[0].flag;
        newItem.scheduleId = shift1.scheduleId;
        newItem.scheduleBreaktime =
          breakArr?.[0].breakHH + "." + breakArr?.[0].breakMM;
        newItem.entryType = "Break";
        newItem.inDate = "";
        newItem.outDate = "";
        newItem.inUniqueNo = 0;
        newItem.outUniqueNo = 0;
        newItem.actualChildId = shift1.actualId;
        newItem.punchtype = "";
        newItem.moduleName = "TimeSheet";
        saveActualDetail.push(newItem);
      } else if (breakMinutes < shift2Minutes) {
        let newItem: any = {};
        newItem.tenantID = tenantID;
        newItem.hotelID = this.state.hotelID;
        newItem.positionID = Number(shift2.positionID);
        newItem.selectDate = row.date;
        newItem.employeeName = row.employee;
        newItem.departmentID = Number(shift2.departmentID);
        newItem.inTime = "";
        newItem.outTime = "";
        newItem.actualId = shift2.actualId;
        newItem.notes = breakArr?.[0].notes;
        newItem.flag = breakArr?.[0].flag;
        newItem.scheduleId = shift2.scheduleId;
        newItem.scheduleBreaktime =
          breakArr?.[0].breakHH + "." + breakArr?.[0].breakMM;
        newItem.entryType = "Break";
        newItem.inDate = "";
        newItem.outDate = "";
        newItem.inUniqueNo = 0;
        newItem.outUniqueNo = 0;
        newItem.actualChildId = shift2.actualId;
        newItem.punchtype = "";
        newItem.moduleName = "TimeSheet";
        saveActualDetail.push(newItem);
      } else {
        Utils.toastError("One shift must be at least as long as the manual break");
        return false;
      }
    }

    if (Number(total) <= Number(row.breakHH) * 60 + Number(row.breakMM)) {
      Utils.toastError("Break duration should be less than Actual hours.", {
      });
      return false;
    }
    if(this.props.parentState.exportedDate !== "" && confirmType === "Yes")
    {
      if(this.props.parentState.exportFormat === "NETCHEX" && this.props.parentState.isPayrollIntegration === "Yes")
        {
          this.NetChexWarning("",row,"","",index,confirmType,type,"");
          this.setState({netchexWarningPopup : true});
        }
        else{
      this.ConfirmPayrollExport("",row,"","",index,confirmType,type,"");
      this.setState({payrollExportPopup : true});
        }
    }
    else{
    this.splitEntaryDetails(saveActualDetail);}
  };

  splitEntaryDetails = (saveActualDetail: any) => {
    if(this.btnSplitShift.current !== null){
    this.btnSplitShift.current.disabled = true;}
    LaborPerformance.SaveLaborActual(saveActualDetail)
      .then(async (result: any | null) => {
        if(this.btnSplitShift.current !== null){
        this.btnSplitShift.current.disabled = false;}
        if (result != null) {
          if (result.result.messageCode === "Success") {
            this.setState({ isDataChange: false });
            toast.success("Shift successfully split.");
            this.loadLaborTimesheetDetails();
            this.loadTimesheetDateWise("splitEntry");
            this.props.slideOutRefresh();
            this.hideExportedDate();
          } else {
            Utils.toastError(result.result.message);
          }
        }
        resolve();
      })
      .catch((err: any) => {
        this.btnSplitShift.current.disabled = false;
        Utils.toastError(`Server Error: ${err}`);
        reject();
      });
    if (this.closeModal) {
      this.closeModal();
    }
  };

  saveChangePosition = (row: any, index: any,confirmType:any,type:any) => {
   
      if (row.splitDepartmentId === 0) {
        Utils.toastError("Please select department.");
        return false;
      }
      if (row.splitPositionId === 0) {
        Utils.toastError("Please select position.");
        return false;
      }
      if (row.splitNotes.trim().length === 0) {
        Utils.toastError("Please enter notes.");
        return false;
      }
      if (row.splitNotes.trim().length > 200) {
        Utils.toastError("Notes length can not exceed 200 characters.");
        return false;
      }
      let dataArr: any[] = _.cloneDeep(this.state.dayDetails[index].timesheet);
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID as any);
      let saveActualDetail: any[] = [];
      for (let i = 0; i < dataArr.length; i++) {
        if (row.actualUniqueno === dataArr[i].actualUniqueno) {
          let newItem: any = {};
          newItem.actualId = row.actualUniqueno;
          newItem.actualChildId = row.actualUniqueno;
          newItem.tenantID = tenantID;
          newItem.hotelID = this.state.hotelID;
          newItem.positionID = Number(row.splitPositionId);
          newItem.selectDate = row.date;
          newItem.employeeName = row.employee;
          newItem.departmentID = Number(row.splitDepartmentId);
          newItem.inTime = this.formatDate(row.inTime);
          newItem.outTime = this.formatDate(row.outTime);
          newItem.notes = row.splitNotes;
          newItem.flag = row.flag;
          newItem.scheduleId = row.scheduleNo;
          newItem.scheduleBreaktime = "";
          newItem.entryType = "changeposition";
          newItem.inDate = row.inDate;
          newItem.outDate = row.outDate;
          newItem.inUniqueNo = row.inUniqueNo;
          newItem.outUniqueNo = row.outUniqueNo;
          newItem.punchtype = row.punchtype;
          newItem.moduleName = "TimeSheet";
          newItem.actionType = "ChangePosition";
          newItem.pageSource = "TimeSheet";
          saveActualDetail.push(newItem);
        }
       
      }
    if(this.props.parentState.exportedDate !== "" && confirmType === "Yes")
    {
      if(this.props.parentState.exportFormat === "NETCHEX" && this.props.parentState.isPayrollIntegration === "Yes")
        {
          this.NetChexWarning("",row,"","",index,confirmType,type,"");
          this.setState({netchexWarningPopup : true});
        }
        else{
      this.ConfirmPayrollExport("",row,"","",index,confirmType,type,"");
      this.setState({payrollExportPopup : true});
        }
    }
    else{
      this.saveChangePositionDetails(saveActualDetail);
    }
  };

  saveChangePositionDetails = (saveActualDetail: any) => {
    if(this.btnChangeShift.current !== null){
    this.btnChangeShift.current.disabled = true;}
    LaborPerformance.ShiftPositionMove(saveActualDetail)
      .then(async (result: any | null) => {
        if(this.btnChangeShift.current !== null){
        this.btnChangeShift.current.disabled = false;}
        if (result != null) {
          if (result.result.messageCode === "Success") {
            this.setState({ isDataChange: false });
            toast.success("Shift successfully reassigned.");
            this.loadLaborTimesheetDetails();
            this.loadTimesheetDateWise("changePosition");
            this.props.slideOutRefresh();
            this.hideExportedDate();
          } else {
            Utils.toastError(result.result.message);
          }
        }
        resolve();
      })
      .catch((err: any) => {
        if(this.btnChangeShift.current !== null){
        this.btnChangeShift.current.disabled = false;}
        Utils.toastError(`Server Error: ${err}`);
        reject();
      });

      if (this.closeModal) {
        this.closeModal();
      }
  };

  handleOutsideClick = () => {
    debugger;
    if(this.state.payrollExportPopup === false || this.state.netchexWarningPopup === false){
    let dayDetails: any[] = _.cloneDeep(this.state.dayDetails);
    for (let i = 0; i < dayDetails.length; i++) {
      for (let j = 0; j < dayDetails[i].timesheet.length; j++) {
        dayDetails[i].timesheet[j].actions = "";
      }
    }
    this.setState({ dayDetails: dayDetails });
  }
  };

  dotsSVG = () => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
      </svg>
    );
  };

  forseApprovalValidate = (value) => {
    if (value === "") {
      this.setState({ forseApprovalError: "Please provide an explanation." });
    } else if (value.length > 500) {
      this.setState({ forseApprovalError: "Maximum 500 characters allowed." });
    } else {
      return value;
    }
  };

  forseApprovalClose = () => {
    this.setState({
      forseApprovalModal: false,
      forseApprovalValue: "",
      forseApprovalError: "",
    });
  };

  forseApprovalShow = () => {
    this.setState({
      forseApprovalModal: true,
      forseApprovalValue: "",
      forseApprovalError: "",
    });
  };

  forseApprovalChange = (e: any) => {
    let { value } = e.target;
    this.setState({ forseApprovalValue: value.trimStart() });
  };

  forseApprovalSubmit = (e: any) => {
    let { forseApprovalValue } = this.state;
    e.preventDefault();
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let request = {} as any;
    request.HotelID = this.props.data.hotelID;
    request.StartDate = this.props.data.fullDate[0];
    request.EndDate = this.props.data.fullDate[1];
    request.UserName = this.props.data.userName;
    request.ApprovalComment = forseApprovalValue;
    request.ModifyedBy = storage.userName;
    if (this.forseApprovalValidate(forseApprovalValue)) {
      this.setState({ forseApprovalError: "" });
      LaborPerformance.saveForceApproval(request)
        .then(async (result: any | null) => {
          if (result.success) {
            this.loadLaborTimesheetDetails();
            this.loadTimesheetDateWise("forceApprovalChange");
            this.props.slideOutRefresh();
            this.setState({ forseApprovalModal: false });
            this.props.hideSlideOut();
            this.setState({
              forseApprovalModal: true,
              forseApprovalValue: "",
              forseApprovalError: "",
            });
            this.hideExportedDate();
            toast.success("Dispute approved successfully");
          }
          resolve();
        })
        .catch((err) => {
          Utils.toastError(`Server Error: ${err}`);
          reject();
        });
    }
  };

  formatColumn = (cell: any, row: any, rowIndex: any) => {
    let confirmModalContent = [
      {
        title: "Delete Time Off",
        desc: "Are you sure you want to delete Time Off ?",
        cancleAction: "Cancel",
        ActionTrue: "Delete Time Off",
      },
    ];

    return (
      <OutsideClickHandler onOutsideClick={this.handleOutsideClick}>
        <div className="delete-Confirm">
          <ConfirmationModal
            confirmModalContent={confirmModalContent}
            ishandleDeletePosition={this.ishandleDeletePosition.bind(this, row)}
          />
        </div>
      </OutsideClickHandler>
    );
  };

  ishandleDeletePosition = (row: any, mode: any,confirmType:any="") => {
    debugger;
    if (mode) {
      if(this.props.parentState.exportedDate !== "" && confirmType === "")
        {
          if(this.props.parentState.exportFormat === "NETCHEX" && this.props.parentState.isPayrollIntegration === "Yes")
            {
              this.NetChexWarning("",row,"","","",confirmType,"DeletePTO","");
            }
            else{
          this.ConfirmPayrollExport("",row,"","","",confirmType,"DeletePTO","");
            }
        }
        else{
          this.handleDeletePosition(row);
        }
      
    } else {
      this.handleOutsideClick();
    }
  };

  handleDeletePosition = (row: any) => {
    let deleteRequest: any = {};
    deleteRequest.scheduleID = row.scheduleNo;
    deleteRequest.hotelID = this.state.hotelID;
    deleteRequest.date = row.date;
    deleteRequest.username = this.state.userName;

    LaborPerformance.DeleteSchedulePtoUto(deleteRequest)
      .then(async (result: any | null) => {
        if (result.success) {
          toast.success("Time Off deleted successfully");
          this.loadLaborTimesheetDetails();
          this.loadTimesheetDateWise("timeOffDelete");
          this.props.slideOutRefresh();
          this.hideExportedDate();
        } else {
          Utils.toastError(result.message);
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error: ${err}`);
        reject();
      });

      if (this.closeModal) {
        this.closeModal();
      }
  };

  getAssociateDateList = (rowData: any) => {
    let newDateList: Array<string> = [];
    // 
    let previousDate = moment(rowData.inDate)
      .subtract(1, "days")
      .format("MM/DD/YY");
    let currentDate = moment(rowData.inDate).format("MM/DD/YY");
    let nextdate = moment(rowData.inDate).add(1, "days").format("MM/DD/YY");

    let todayActualDate = moment().format("MM/DD/YY");
    if (todayActualDate === currentDate) {
      newDateList.push(previousDate);
      newDateList.push(currentDate);
    } else {
      newDateList.push(previousDate);
      newDateList.push(currentDate);
      newDateList.push(nextdate);
    }

    return newDateList;

  };
  hideDropdown = () => {
    $("#comments-dropdown .dropdown-menu, #child-table-comment-dropdown .dropdown-menu").hide();
    $(".notes-btn").removeClass("show");
  }
  formatColumnManualDelete = (cell: any, row: any, rowIndex: any, index: any) => {
    let confirmModalContent = [
      {
        title: "Delete Shift",
        desc: "Are you sure you want to delete the Manual Shift?",
        cancleAction: "Cancel",
        ActionTrue: "Delete",
      },
    ];

    return (
      <OutsideClickHandler onOutsideClick={this.handleOutsideClick}>
        <div className="delete-Confirm">
          <ConfirmationModal
            confirmModalContent={confirmModalContent}
            ishandleDeletePosition={this.IshandleManualDeleteShift.bind(
              this,
              row,
              rowIndex,
              index
            )}
          />
        </div>
      </OutsideClickHandler>
    );
  };

  IshandleManualDeleteShift = (row: any, rowIndex: any, index: any, mode: any) => {
    this.handleOutsideClick();
    if (mode) {
      this.IshandleManualDeleteEntry(row, rowIndex, index,"Yes","DeleteManualBreak");
    }
  };
  showHideChangeLogModal = (isSlatChangeLog = false, row: any = {}) => {
    if (!isSlatChangeLog || !this.state.showChangeLogModal === false) {
      if (+this.state.scheduleNo > 0) {
        this.setState(
          {
            scheduleNo: 0,
            isSlatChangeLog,
          },
          () => {
            this.setState({
              showChangeLogModal: !this.state.showChangeLogModal,
            });
          }
        );
      } else {
        this.setState({
          showChangeLogModal: !this.state.showChangeLogModal,
        });
      }
    } else {
      this.setState(
        {
          scheduleNo: row?.parentData[0]?.scheduleNo,
          isSlatChangeLog,
        },
        () => {
          this.setState({
            showChangeLogModal: !this.state.showChangeLogModal,
          });
        }
      );
    }
  };

  IshandleManualDeleteEntry = (row: any, rowIndex: any, index: any,confirmType:any,type:any) => {
debugger
    let data = _.cloneDeep(this.state.dayDetails);
      if(this.props.parentState.exportedDate !== "" && confirmType === "Yes")
    {
      if(this.props.parentState.exportFormat === "NETCHEX" && this.props.parentState.isPayrollIntegration === "Yes")
        {
          this.NetChexWarning("",row,rowIndex,"",index,confirmType,type,"");
        }
        else{
      this.ConfirmPayrollExport("",row,rowIndex,"",index,confirmType,type,"");
        }
    }
    else
    { 
      if (rowIndex === 0) {
        if (data[index].timesheet.length > 1) {
          let dataArr = data[index].timesheet;
          let deleteEleIndex = dataArr.findIndex(ele => ele.actualUniqueno === row.actualUniqueno)
          let newObj = dataArr[deleteEleIndex + 1]

          if (newObj !== undefined) {
            openRow[index] = newObj;
          } else {
            openRow[index] = dataArr[deleteEleIndex - 1]
          }
        }
        // openRow[index] = { ...data[index].timesheet[childIndex + 1] };
      }
      // }
      let deleteManualRequest: any = {} as any;
      deleteManualRequest.actualID = row.actualUniqueno;
      deleteManualRequest.hotelID = this.state.hotelID;
      deleteManualRequest.date = row.date;
      deleteManualRequest.type = "deleteManualShift";
      deleteManualRequest.username = this.state.userName;

      LaborPerformance.DeleteManualActualShift(deleteManualRequest)
        .then(async (result: any | null) => {
          if (result.success) {
            toast.success("Shift deleted successfully");
            this.loadLaborTimesheetDetails();
            this.loadTimesheetDateWise("shiftDelete");
            this.props.slideOutRefresh();
            this.hideExportedDate();
          } else {
            Utils.toastError(result.message);
          }
          resolve();
        })
        .catch((err) => {
          Utils.toastError(`Server Error: ${err}`);
          reject();
        });
        if (this.closeModal) {
          this.closeModal();
        }
    }

  };

  openOverView = () => {
    this.setState({ isRedirect: true });
    localStorage.setItem("mainMenu", "Labor");
    localStorage.setItem("subMenu", "Payroll");
  };

  deleteBreak = (row: any,confirmType:any,type:any) => {
    this.props.parentState.isPayrollExported = true
    if(this.props.parentState.exportedDate !== "" && confirmType === "Yes")
    {
      if(this.props.parentState.exportFormat === "NETCHEX" && this.props.parentState.isPayrollIntegration === "Yes")
        {
          this.NetChexWarning("",row,"","","",confirmType,type,"");
        }
        else{
      this.ConfirmPayrollExport("",row,"","","",confirmType,type,"");
        }
    }
    else{
    let deleteRequest: any = {};
    deleteRequest.scheduleID = row.scheduleNo;
    deleteRequest.hotelID = this.state.hotelID;
    deleteRequest.date = row.date;
    deleteRequest.username = this.state.userName;
    deleteRequest.PageSource = "DeleteManualBreakTimesheet";
    LaborPerformance.DeleteManualBreak(deleteRequest)
      .then(async (result: any | null) => {
        if (result.success) {
          toast.success("Manual Break deleted successfully");
          this.loadLaborTimesheetDetails();
          this.loadTimesheetDateWise("manualBreakDelete");
          this.props.slideOutRefresh();
        } else {
          Utils.toastError(result.message);
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error: ${err}`);
        reject();
      });
      if (this.closeModal) {
        this.closeModal();
      }
    }


  };

  getParentTable = (index: any) => {

    let nonBreakData = this.state.dayDetails[index].timesheet.filter(
      (data: any) => data.entryType !== "Break"
    );
    let parentTableData: any = [];
    for (let i = 0; i < nonBreakData.length; i++) {
      let pushObject: any = {};
      let existingIndex: number = parentTableData.findIndex(
        (data: any) =>
          data?.parentData[0]?.scheduleNo === nonBreakData[i].scheduleNo
      );

      if (existingIndex >= 0) {
        parentTableData[existingIndex].parentData.push(nonBreakData[i]);
      } else {
        let tableTempData: any = [];
        tableTempData.push(nonBreakData[i]);
        pushObject.tableKey = i;
        pushObject.parentTableIndex = index;
        pushObject.parentData = tableTempData;
        parentTableData.push(pushObject);
      }

    }
    return (
      <BootstrapTable
        id={"timesheetParentDetails"}
        keyField="tableKey"
        data={parentTableData}
        columns={this.parentDayWiseTableColumns(index)}
        hover
        expandRow={this.parentRowExpender}
      />
    );
  };

  parentDayWiseTableColumns = (index: any) => [
    {
      dataField: "position",
      text: "Position",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div className="position-wrapper">
          <EllipsisWithTooltip>
            <div className="positon-name">{row?.parentData[0]?.position}</div>
          </EllipsisWithTooltip>
          <div className="schedule-status">Scheduled</div>
        </div>
      ),
      footer: "Totals",
    },
    {
      dataField: "",
      text: "Shift",
      formatter: (cell: any, row: any, rowIndex: any) => {
        const actualStartTimeAMPM = moment(
          `${row?.parentData[0]?.inDate} ${row?.parentData[0]?.inTime}`
        ).format("h:mm A");
        const actualEndTimeAMPM = moment(
          `${row?.parentData[row?.parentData.length - 1]?.outDate} ${row?.parentData[row?.parentData.length - 1]?.outTime
          }`
        ).format("h:mm A");
        const scheduleStartTimeAMPM = moment(
          `${row?.parentData[0]?.scheduleInDate} ${row?.parentData[0]?.scheduleInTime}`
        ).format("h:mm A");
        const scheduleEndTimeAMPM = moment(
          `${row?.parentData[row?.parentData.length - 1]?.scheduleOutDate} ${row?.parentData[row?.parentData.length - 1]?.scheduleOutTime
          }`
        ).format("h:mm A");
        return (
          <div className="shift-wrapper">
            {row?.parentData[0]?.noShow === 1 &&
              row?.parentData[0]?.inDate === "" &&
              row?.parentData[0]?.outDate === "" ? (

              <OutsideClickHandler onOutsideClick={(e: any) => this.noShowOutSideClick(row?.parentData[0].ddlNoShowRef)}>
                <div>
                  <Dropdown
                    className={"more-action add-note-dropdown "}
                    alignRight
                    onClick={(e: any) => e.stopPropagation()}
                  >
                    <Dropdown.Toggle id="dropdown-more" className="p-0" ref={row?.parentData[0].ddlNoShowRef}>
                      <div className="red-badges red-bg">No Show</div>
                    </Dropdown.Toggle >
                    <Dropdown.Menu   >
                      <div className="dropdown-header">
                        <div className="dropdown-ttl">Add No Show comments</div>
                        <Dropdown.Item className="cross-btn closeShowNotes" href=""    >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                              fill="#84888C"
                            />
                          </svg>
                        </Dropdown.Item>
                      </div>
                      <div className="dropdown-body">
                        <Form.Group>
                          <Form.Label className="dropdown-ttl">Notes</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={2}
                            placeholder="Add comments here..."
                            maxLength={(200)}
                            // value={row.assocaiteedDateNotes}
                            className={"txtNoShowNotes"}
                            onBlur={(e: any) =>
                              this.NoShowNotesChange(e, row, index)
                            }
                          />
                        </Form.Group>
                      </div>
                      <div className="action-link dropdown-footer">
                        <Button
                          className="btn btn-primary"
                          //ref={this.btnAssociateSave}
                          // id={"btnchange" + rowIndex}
                          onClick={() =>
                            this.saveNoShowComment(row, index)
                          }
                        >
                          Submit
                        </Button>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </OutsideClickHandler>

            ) : (
              <div className="shift-actual">
                {row?.parentData[0].opens === "Open"
                  ? `${actualStartTimeAMPM} - `
                  : `${actualStartTimeAMPM} - ${actualEndTimeAMPM}`}
              </div>
            )}
            {row?.parentData[0].manuals.length > 0 ? (
              row?.parentData[0].manuals !== "Manual" ? (
                <div className="red-badges blue-bg">
                  {row?.parentData[0].manuals}
                </div>
              ) : scheduleStartTimeAMPM === scheduleEndTimeAMPM ? (
                <div className="red-badges blue-bg">
                  {row?.parentData[0].manuals}
                </div>
              ) : (
                <div className="shift-scheduled">
                  {`${scheduleStartTimeAMPM} - ${scheduleEndTimeAMPM}`}
                </div>
              )
            ) : (
              <div className="shift-scheduled">
                {`${scheduleStartTimeAMPM} - ${scheduleEndTimeAMPM}`}
              </div>
            )}
          </div>
        );
      },
      footer: "",
    },
    {
      dataField: "",
      text: "Totals",
      formatter: (cell: any, row: any, rowIndex: any) => {
        let rowScheduleNo = row.parentData[0].scheduleNo;
        let totalActual = 0;
        let totalSchedule = 0;
        let timesheetData = this.state.dayDetails[index].timesheet;

        for (let i = 0; i < timesheetData.length; i++) {
          if (timesheetData[i].scheduleNo === rowScheduleNo) {
            if (
              timesheetData[i].entryType === "Break" &&
              timesheetData[i].noShow !== 1 &&
              timesheetData[i].inDate !== "" &&
              timesheetData[i].outDate !== "" &&
              timesheetData[i].opens !== "Open"
            ) {
              let breakTimeInMinutes = schConversions.convertHourToMInute(
                timesheetData[i].breakTime
              );
              totalActual = totalActual - breakTimeInMinutes;
            } else {
              if (
                timesheetData[i].noShow !== 1 &&
                timesheetData[i].inDate !== "" &&
                timesheetData[i].outDate !== "" &&
                timesheetData[i].opens !== "Open"
              ) {
                let actualInTime = moment(
                  `${timesheetData[i].inDate} ${timesheetData[i].inTime}`
                );
                let actualOutTime = moment(
                  `${timesheetData[i].outDate} ${timesheetData[i].outTime}`
                );
                let diffInActual = actualOutTime.diff(actualInTime, "minutes");
                totalActual = totalActual + diffInActual;
              }

              let scheduleInTime = moment(
                `${timesheetData[i].scheduleInDate} ${timesheetData[i].scheduleInTime}`
              );
              let scheduleOutTime = moment(
                `${timesheetData[i].scheduleOutDate} ${timesheetData[i].scheduleOutTime}`
              );

              let diffInSchedue = scheduleOutTime.diff(
                scheduleInTime,
                "minutes"
              );
              if (totalSchedule === 0) {
                totalSchedule = totalSchedule + diffInSchedue;
              }
            }
          }
        }
        return (
          <div className="total-wrapper">
            <div className={`total-actual ${Number(totalActual) > Number(totalSchedule) ? "red" : ""}`}>
              {schConversions.convertMinuteToHour(totalActual)}
            </div>
            <div className="total-scheduled">
              {schConversions.convertMinuteToHour(totalSchedule)}
            </div>
          </div>
        );
      },
      footer: (columnData: any) => {
        const timesheetData = this.state.dayDetails[index].timesheet;
        let totalActual: number = 0;
        for (let i = 0; i < timesheetData.length; i++) {
          if (
            timesheetData[i].noShow !== 1 &&
            timesheetData[i].inDate !== "" &&
            timesheetData[i].outDate !== "" &&
            timesheetData[i].opens !== "Open"
          ) {
            if (timesheetData[i].entryType === "Break") {
              let breakTimeInMinutes = schConversions.convertHourToMInute(
                timesheetData[i].breakTime
              );
              totalActual = totalActual - breakTimeInMinutes;
            } else {
              let actualInTime = moment(
                `${timesheetData[i].inDate} ${timesheetData[i].inTime}`
              );
              let actualOutTime = moment(
                `${timesheetData[i].outDate} ${timesheetData[i].outTime}`
              );
              let diffInActual = actualOutTime.diff(actualInTime, "minutes");
              totalActual = totalActual + diffInActual;
            }
          }
        }
        return (
          <div className="footer-total-actual">
            {schConversions.convertMinuteToHour(totalActual)}
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Variance",
      formatter: (cell: any, row: any, rowIndex: any) => {
        let rowScheduleNo = row.parentData[0].scheduleNo;
        let totalActual = 0;
        let totalSchedule = 0;
        let timesheetData = this.state.dayDetails[index].timesheet;

        for (let i = 0; i < timesheetData.length; i++) {
          if (timesheetData[i].scheduleNo === rowScheduleNo) {
            if (
              timesheetData[i].entryType === "Break" &&
              timesheetData[i].noShow !== 1 &&
              timesheetData[i].inDate !== "" &&
              timesheetData[i].outDate !== "" &&
              timesheetData[i].opens !== "Open"
            ) {
              let breakTimeInMinutes = schConversions.convertHourToMInute(
                timesheetData[i].breakTime
              );
              totalActual = totalActual - breakTimeInMinutes;
            } else {
              if (
                timesheetData[i].noShow !== 1 &&
                timesheetData[i].inDate !== "" &&
                timesheetData[i].outDate !== "" &&
                timesheetData[i].opens !== "Open"
              ) {
                let actualInTime = moment(
                  `${timesheetData[i].inDate} ${timesheetData[i].inTime}`
                );
                let actualOutTime = moment(
                  `${timesheetData[i].outDate} ${timesheetData[i].outTime}`
                );
                let diffInActual = actualOutTime.diff(actualInTime, "minutes");
                totalActual = totalActual + diffInActual;
              }

              let scheduleInTime = moment(
                `${timesheetData[i].scheduleInDate} ${timesheetData[i].scheduleInTime}`
              );
              let scheduleOutTime = moment(
                `${timesheetData[i].scheduleOutDate} ${timesheetData[i].scheduleOutTime}`
              );

              let diffInSchedue = scheduleOutTime.diff(
                scheduleInTime,
                "minutes"
              );
              if (totalSchedule === 0) {
                totalSchedule = totalSchedule + diffInSchedue;
              }
            }
          }
        }

        let valueInDiff = Number(totalSchedule - totalActual);
        let modValueInDiff = valueInDiff;
        if (modValueInDiff < 0) {
          modValueInDiff = modValueInDiff * -1;
        }
        return (
          <div className="varience-wrapper text-center">
            <div
              className={
                valueInDiff < 0 ? "varience-actual red" : "varience-actual"
              }
            >
              {schConversions.convertMinuteToHour(modValueInDiff)}
            </div>
          </div>
        );
      },
      footer: "",
    },
    {
      dataField: "ot",
      text: "Overtime",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div className="overtime-wrapper text-center">
          <div
            className={
              row?.parentData[0]?.ot !== "0:00"
                ? "overtime-actual red"
                : "overtime-actual"
            }
          >
            {row?.parentData[0]?.ot}
          </div>
        </div>
      ),
      footer: (columnData: any) => "",
    },
    {
      dataField: "dot",
      text: "DOT",
      hidden: this.props.data.ruleType === "Standard Rule" ? true : false,
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div className="overtime-wrapper text-center">
          <div
            className={
              row?.parentData[0]?.ot !== "0:00"
                ? "overtime-actual red"
                : "overtime-actual"
            }
          >
            {row?.parentData[0]?.dot}
          </div>
        </div>
      ),
      footer: (columnData: any) => "",
    },


    {
      dataField: "",
      text: "",
      // hidden: this.props.data.ruleType === "Standard Rule" ? true : false,
      formatter: (cell: any, row: any, rowIndex: any) => (
        <OutsideClickHandler onOutsideClick={(e: any) => this.noShowComentOutSideClick(row?.parentData[0].ddlShowCommentRef)}>
          <div id="comments-dropdown"
            onClick={(e: any) => e.stopPropagation()}
          >
            {row?.parentData[0]?.laborActualCommentNoShow.length > 0 && (
              <Dropdown className="more-action notes-btn" alignRight
                // onMouseLeave={() => $(".dropdown-menu").hide()}
                onMouseLeave={this.hideDropdown}
              >
                <Dropdown.Toggle
                  className="btn-outline-primary  more"
                  id="dropdown-more"
                  ref={row?.parentData[0].ddlShowCommentRef}
                >
                  <div className="d-flex">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M13.5556 2.4H11.3889V1H9.94444V2.4H7.05556V1H5.61111V2.4H3.44444C2.64783 2.4 2 3.0279 2 3.8V13.6C2 14.3721 2.64783 15 3.44444 15H13.5556C14.3522 15 15 14.3721 15 13.6V3.8C15 3.0279 14.3522 2.4 13.5556 2.4ZM3.44444 13.6V4.5H5.61111H7.05556H9.94444H11.3889H13.5556V3.8L13.557 13.6H3.44444Z" />
                      <path d="M4.6665 6H11.3332V7.33333H4.6665V6ZM4.6665 8.66667H7.99984V10H4.6665V8.66667Z" />
                    </svg>
                    <span>{row?.parentData[0]?.laborActualCommentNoShow.length}</span>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu renderOnMount show={this.state.commentsDropdown}>
                  <div className="notes-sec">
                    <div className="notes-list">
                      {row?.parentData[0]?.laborActualCommentNoShow.map((item: any, idx: any) => (
                        <div className="comment-list">
                          <div className="d-flex profile-details">
                            <div className="profile-img">
                              <img
                                src={
                                  item.imagepath === null
                                    ? profile
                                    : item.imagepath === ""
                                      ? profile
                                      : item.imagepath
                                }
                                className={
                                  item.imagepath === null
                                    ? "default"
                                    : item.imagepath === ""
                                      ? "default"
                                      : ""
                                }
                                width="40"
                                height="40"
                                alt={item.username}
                                onError={(e) => (e.currentTarget.src = profile)}
                              />
                            </div>
                            <div>
                              <div className="main-ttl">{item.username}</div>
                              <div className="sub-ttl">
                                {Utils.getDateTimeMddyyFromUtc(item.commentdate)}
                              </div>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <div className="d-flex">
                                <div className="detail-sec">
                                  <div className="comments">{item.comments}</div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>

        </OutsideClickHandler>


      ),
      footer: (columnData: any) => "",
    },



    {
      dataField: "",
      text: "",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <OutsideClickHandler onOutsideClick={(e: any) => this.ChangeLogOusidSideClick(row?.parentData[0].ddlChangeLogRef)}>
          <Dropdown
            className={"more-action"}
            alignRight
            onClick={(e: any) => e.stopPropagation()}
            ref={this.ddlParentLog}
          >
            <Dropdown.Toggle
              className="btn-outline-primary  more"
              id="dropdown-more"
              ref={row?.parentData[0].ddlChangeLogRef}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  this.showHideChangeLogModal(true, row);
                }}
              >
                Change Log
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </OutsideClickHandler>
      ),
      footer: (columnData: any) => {
        return this.addShiftFooter(index);
      },
    },
  ];

  parentRowExpender = {
    renderer: (row: any, rowIndex: any) => {
      let dataIndex: number = -1;
      this.rowScheduleID = row?.parentData[0]?.scheduleNo;
      for (let i = 0; i < this.state.dayDetails.length; i++) {
        if (
          row?.parentData[0]?.dayName ===
          this.state.dayDetails[i].timesheet[0].dayName
        ) {
          dataIndex = i;
          break;
        }
      }
      let childTableAllData = this.state.dayDetails[dataIndex].timesheet.filter(
        (data: any) =>
          data.scheduleNo === row?.parentData[0]?.scheduleNo &&
          data.noShow !== 1
      );
      let childTableNonBreakData = childTableAllData.filter(
        (data: any) => data.entryType !== "Break"
      );
      let childTableBreakData = childTableAllData.filter(
        (data: any) => data.entryType === "Break"
      );
      let childTableData: any = [
        ...childTableNonBreakData,
        ...childTableBreakData,
      ];
      return (
        <div>
          <BootstrapTable
            id={"timesheetDetails"}
            keyField="titlee"
            // data={this.state.dayDetails[dataIndex].timesheet.filter(
            //   (data: any) => data.scheduleNo === row?.parentData[0]?.scheduleNo
            // )}
            data={childTableData}
            columns={this.columnsFields(dataIndex)}
            hover
          />
        </div>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: false,
    onlyOneExpanding: true,
    onExpand: (row: any, isExpand: any, rowIndex: any, e: any) => {
      // 
      if (isExpand) {
        openRow[row?.parentTableIndex] = row?.parentData[0];
        console.log(openRow);

        let dataIndex: number = -1;
        for (let i = 0; i < this.state.dayDetails.length; i++) {
          if (
            row?.parentData[0]?.dayName ===
            this.state.dayDetails[i].timesheet[0].dayName
          ) {
            dataIndex = i;
            break;
          }
        }
        let data = _.cloneDeep(this.state.dayDetails);
        let timesheetRef = data[dataIndex].ref?.current;

        scrollIntoView(timesheetRef, {
          align: {
            top: 0,
            left: 0,
            topOffset: 200,
            leftOffset: 0,
          },
        });
      }
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <span className="chevronExpended">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      }
      return (
        <span className="chevronCollpsed">
          <img src={chevronRight} alt="chevron Right" />
        </span>
      );
    },
  };

  isTabShow = (row: any) => {
    return row.entryType.toLowerCase() === "timeoff"
      ? row.managetimeoff.toString().toLowerCase() === "yes" &&
        row.isedit.toString().toLowerCase() === "yes"
        ? true
        : false
      : row.isedit.toString().toLowerCase() === "yes"
        ? true
        : false;
  };

  isActualExist = (index: any) => {
    let isBreak = false;
    let dataArr: any[] = _.cloneDeep(
      this.state.dayDetails[index].timesheet.filter(
        (a: any) => a.entryType !== "TimeOff"
      )
    );
    if (dataArr.length > 0) {
      for (let x = 0; x < dataArr.length; x++) {
        isBreak =
          dataArr.filter(
            (item) =>
              item.entryType === "Break" &&
              item.positionID === dataArr[x].positionID
          ).length > 0
            ? true
            : false;
        if (!isBreak) {
          break;
        }
      }
    }
  };

  columnsFields = (index: any) => [
    {
      dataField: "inTime",
      text: "In",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <>
          {row.isActualentry !== "No" &&
            row.entryType.toLowerCase() !== "break" && (
              <div className="d-flex align-items-center">
                {row.maxCountin > 1 && <div className="dot margin-right"></div>}
                {((this.isTabShow(row) && row.opens !== "Open") ||
                  row.lastInNotes.length > 0 ||
                  row.inImage.length > 0) && (
                    <Dropdown className="more-action grey" alignRight>
                      <Dropdown.Toggle
                        className="btn-outline-primary inner-sec-btn more pos-abs"
                        id="dropdown-more"
                        ref={this.refIn}
                      >
                        <div
                          className="d-flex align-items-center"
                          onClick={() =>
                            this.resetPopupData(row, rowIndex, "In", index)
                          }
                        >
                          {this.formatDate(row.inTime)}
                          {row.inImage.length > 0 && (
                            <div>
                              {row.inimagestatus === "Pending" && (
                                <div className="orange-bg">
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M7.86669 4.00039H4.66669V2.40039H7.86669V4.00039Z"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M11.2 7.04043H4.8V8.32043C4.8 10.0877 6.23269 11.5204 8 11.5204C9.76731 11.5204 11.2 10.0877 11.2 8.32043V7.04043ZM3.2 5.44043V8.32043C3.2 10.9714 5.34903 13.1204 8 13.1204C10.651 13.1204 12.8 10.9714 12.8 8.32043V5.44043H3.2Z"
                                    />
                                    <path d="M11.0667 3.20039C11.0667 3.64222 10.7085 4.00039 10.2667 4.00039C9.82487 4.00039 9.4667 3.64222 9.4667 3.20039C9.4667 2.75856 9.82487 2.40039 10.2667 2.40039C10.7085 2.40039 11.0667 2.75856 11.0667 3.20039Z" />
                                  </svg>
                                </div>
                              )}
                              {row.inimagestatus === "Pass" && (
                                <div className="grey-bg">
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M7.86669 4.00039H4.66669V2.40039H7.86669V4.00039Z"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M11.2 7.04043H4.8V8.32043C4.8 10.0877 6.23269 11.5204 8 11.5204C9.76731 11.5204 11.2 10.0877 11.2 8.32043V7.04043ZM3.2 5.44043V8.32043C3.2 10.9714 5.34903 13.1204 8 13.1204C10.651 13.1204 12.8 10.9714 12.8 8.32043V5.44043H3.2Z"
                                    />
                                    <path d="M11.0667 3.20039C11.0667 3.64222 10.7085 4.00039 10.2667 4.00039C9.82487 4.00039 9.4667 3.64222 9.4667 3.20039C9.4667 2.75856 9.82487 2.40039 10.2667 2.40039C10.7085 2.40039 11.0667 2.75856 11.0667 3.20039Z" />
                                  </svg>
                                </div>
                              )}
                              {row.inimagestatus === "Fail" && (
                                <div className="red-badges">
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M7.86669 4.00039H4.66669V2.40039H7.86669V4.00039Z"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M11.2 7.04043H4.8V8.32043C4.8 10.0877 6.23269 11.5204 8 11.5204C9.76731 11.5204 11.2 10.0877 11.2 8.32043V7.04043ZM3.2 5.44043V8.32043C3.2 10.9714 5.34903 13.1204 8 13.1204C10.651 13.1204 12.8 10.9714 12.8 8.32043V5.44043H3.2Z"
                                    />
                                    <path d="M11.0667 3.20039C11.0667 3.64222 10.7085 4.00039 10.2667 4.00039C9.82487 4.00039 9.4667 3.64222 9.4667 3.20039C9.4667 2.75856 9.82487 2.40039 10.2667 2.40039C10.7085 2.40039 11.0667 2.75856 11.0667 3.20039Z" />
                                  </svg>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div className="pos-name">
                          <Dropdown.Item className="cross-btn" href="">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                                fill="#84888C"
                              />
                            </svg>
                          </Dropdown.Item>
                        </div>
                        <Tabs
                          id={"punchIn-tab-" + rowIndex}
                          onSelect={(selectedtab) =>
                            this.resetActiveTab(
                              rowIndex,
                              selectedtab,
                              index,
                              "In",
                              row
                            )
                          }
                        >
                          {row.lastInNotes.length > 0 && (
                            <Tab
                              eventKey={"details" + rowIndex + index}
                              title="Details"
                            >
                              <div className="section">
                                <div className="pading">
                                  <div className="d-flex start">
                                    <div className="profile-img">
                                      <img
                                        src={
                                          row.lastInImage === null
                                            ? profile
                                            : row.lastInImage.trim() === ""
                                              ? profile
                                              : row.lastInImage
                                        }
                                        className={
                                          row.lastInImage === null
                                            ? "default"
                                            : row.lastInImage.trim() === ""
                                              ? "default"
                                              : ""
                                        }
                                        width="40"
                                        height="40"
                                        alt={row.lastInUsername}
                                        onError={(e) =>
                                          (e.currentTarget.src = profile)
                                        }
                                      />
                                    </div>
                                    <div className="details">
                                      <div className="name">
                                        {row.lastInUsername}
                                      </div>
                                      <div className="date">{row.lastInDate}</div>
                                    </div>
                                  </div>
                                  <div className="notes">{row.lastInNotes}</div>
                                  <div className="time d-flex start">
                                    <div className="current-status">
                                      <div className="ttl">Original</div>
                                      <div className="time">
                                        {row.firstPunchinTime}
                                      </div>
                                    </div>
                                    <div className="last-status">
                                      <div className="ttl">Updated</div>
                                      <div className="time">
                                        {this.formatDate(row.inTime)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {row.intimeDispute !== "" && (
                                  <div className="related-link">
                                    <div className="title">Related</div>
                                    <ul>
                                      <li>
                                        <span
                                          onClick={this.handleComparisonClick.bind(
                                            this,
                                            1002,
                                            "Dispute"
                                          )}
                                        >
                                          Punch In Dispute
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </Tab>
                          )}
                          {this.isTabShow(row) && row.opens !== "Open" && (
                            <Tab
                              eventKey={"edit-punch" + rowIndex + index}
                              title={
                                row.entryType !== "TimeOff"
                                  ? "Edit Punch"
                                  : "Edit Time Off"
                              }
                            >
                              <div className="section">
                                <div className="pading">
                                  <div className="list-wrapper d-flex justify-content-between">
                                    <div className="list half-width">
                                      <div className="ttl">Punch In</div>
                                      <div className="control">
                                        <input
                                          type="time"
                                          id="inTimedupappt"
                                          name="inTimedupappt"
                                          className="html-time-picker"
                                          value={row.inTimedup}
                                          onChange={(e: any) =>
                                            this.intimeChange(
                                              e,
                                              row,
                                              rowIndex,
                                              index
                                            )
                                          }
                                          onBlur={(e: any) =>
                                            this.intimeBlur(
                                              e,
                                              row,
                                              rowIndex,
                                              index
                                            )
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="list half-width">
                                      <div className="ttl">Date</div>
                                      <Form.Group controlId="date-selection">
                                        <Dropdown>
                                          <Dropdown.Toggle id="dropdown-importFrom">
                                            <span className="selected">
                                              {row.ddlInDate}
                                            </span>
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            {this.getDateList(
                                              row,
                                              rowIndex,
                                              index
                                            )}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </Form.Group>
                                    </div>
                                  </div>

                                  <div className="list">
                                    <div className="ttl">Notes</div>
                                    <div className="control">
                                      <Form.Control
                                        className="input-notes"
                                        as="textarea"
                                        value={row.lastInNotesdup}
                                        placeholder="Explanation required..."
                                        onChange={(e: any) =>
                                          this.noteChange(e, row, rowIndex, index)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="footer d-flex justify-content-end">
                                  <button
                                    type="button"
                                    ref={this.btnhHandleSave}
                                    className="btn btn-primary"
                                    onClick={(e: any) =>
                                      this.handleSave(e, row, rowIndex, "In", index,"Yes","In")
                                    }
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </Tab>
                          )}
                          {row.inImage.length > 0 && (
                            <Tab
                              eventKey={"smile-id" + rowIndex + index}
                              edit-punch
                              title="Smile iD"
                            >
                              <div className="section">
                                <div className="pading edit-pad">
                                  <div className="currentimg">
                                    <MyImage
                                      src={row.inImage}
                                      fallbackSrc={profile}
                                      alt="User SmileId"
                                      id={row.inUniqueno}
                                    ></MyImage>
                                    {row.inimagestatus === "Pending" && (
                                      <div className="badges red-badges orange-bg">
                                        {row.inimagelevel} %
                                      </div>
                                    )}
                                    {row.inimagestatus === "Pass" && (
                                      <div className="badges red-badges green-bg">
                                        {row.inimagelevel} %
                                      </div>
                                    )}
                                    {row.inimagestatus === "Fail" && (
                                      <div className="badges red-badges red-badges">
                                        {row.inimagelevel} %
                                      </div>
                                    )}
                                  </div>
                                  <div className="profile-details">
                                    {row.inimagestatus === "Pending" && (
                                      <div className="q-details">
                                        Is this {row.employee}?
                                      </div>
                                    )}
                                    {row.inimagestatus !== "Pending" && (
                                      <div className="q-details">
                                        {row.employee}
                                      </div>
                                    )}
                                    <div className="punch-details">
                                      Punched in at {row.inImageTime} on{" "}
                                      {row.inDate}
                                    </div>
                                  </div>
                                </div>
                                {row.inimagestatus === "Pending" &&
                                  row.managesmileid.toLowerCase() === "yes" && (
                                    <div className="footer d-flex justify-content-end">
                                      <button
                                        type="button"
                                        className="btn-danger btn btn-primary"
                                        onClick={(e: any) =>
                                          this.updateSmileIdStatus("No", row, 1)
                                        }
                                      >
                                        No
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-primary btn-green"
                                        onClick={(e: any) =>
                                          this.updateSmileIdStatus("Yes", row, 1)
                                        }
                                      >
                                        Yes
                                      </button>
                                    </div>
                                  )}
                              </div>
                            </Tab>
                          )}
                        </Tabs>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                {row.lastInNotes.length === 0 && row.inImage.length === 0 && (
                  <div className="rm-bold margin-top">
                    {this.formatDate(row.inTime)}
                  </div>
                )}
              </div>
            )}
          {row.entryType.toLowerCase() === "break" && (
            <div className="d-flex align-items-center">Manual Break</div>
          )}
        </>
      ),
      footer: "Totals",
    },
    {
      dataField: "outTime",
      text: "Out",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <>
          {row.isActualentry !== "No" &&
            row.entryType.toLowerCase() !== "break" && (
              <div className="d-flex align-items-center">
                {row.maxCountout > 1 && (
                  <div className="dot margin-right"></div>
                )}
                {(this.isTabShow(row) ||
                  row.lastOutNotes.length > 0 ||
                  row.outImage.length > 0) && (
                    <>
                      <>
                        {row.opens === "Open" && (
                          <div className="rm-bold margin-top"></div>
                        )}
                      </>
                      {row.opens !== "Open" && (
                        <Dropdown className="more-action grey" alignRight>
                          <Dropdown.Toggle
                            className="btn-outline-primary inner-sec-btn more pos-abs"
                            id="dropdown-more"
                            ref={this.refOut}
                          >
                            <div
                              className="d-flex align-items-center"
                              onClick={() =>
                                this.resetPopupData(row, rowIndex, "Out", index)
                              }
                            >
                              {this.formatOutDate(row.outTime)}
                              {row.outImage.length > 0 && (
                                <div>
                                  {row.outImageStatus === "Pending" && (
                                    <div className="orange-bg">
                                      <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M7.86669 4.00039H4.66669V2.40039H7.86669V4.00039Z"
                                        />
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M11.2 7.04043H4.8V8.32043C4.8 10.0877 6.23269 11.5204 8 11.5204C9.76731 11.5204 11.2 10.0877 11.2 8.32043V7.04043ZM3.2 5.44043V8.32043C3.2 10.9714 5.34903 13.1204 8 13.1204C10.651 13.1204 12.8 10.9714 12.8 8.32043V5.44043H3.2Z"
                                        />
                                        <path d="M11.0667 3.20039C11.0667 3.64222 10.7085 4.00039 10.2667 4.00039C9.82487 4.00039 9.4667 3.64222 9.4667 3.20039C9.4667 2.75856 9.82487 2.40039 10.2667 2.40039C10.7085 2.40039 11.0667 2.75856 11.0667 3.20039Z" />
                                      </svg>
                                    </div>
                                  )}
                                  {row.outImageStatus === "Pass" && (
                                    <div className="grey-bg">
                                      <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M7.86669 4.00039H4.66669V2.40039H7.86669V4.00039Z"
                                        />
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M11.2 7.04043H4.8V8.32043C4.8 10.0877 6.23269 11.5204 8 11.5204C9.76731 11.5204 11.2 10.0877 11.2 8.32043V7.04043ZM3.2 5.44043V8.32043C3.2 10.9714 5.34903 13.1204 8 13.1204C10.651 13.1204 12.8 10.9714 12.8 8.32043V5.44043H3.2Z"
                                        />
                                        <path d="M11.0667 3.20039C11.0667 3.64222 10.7085 4.00039 10.2667 4.00039C9.82487 4.00039 9.4667 3.64222 9.4667 3.20039C9.4667 2.75856 9.82487 2.40039 10.2667 2.40039C10.7085 2.40039 11.0667 2.75856 11.0667 3.20039Z" />
                                      </svg>
                                    </div>
                                  )}
                                  {row.outImageStatus === "Fail" && (
                                    <div className="red-badges">
                                      <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M7.86669 4.00039H4.66669V2.40039H7.86669V4.00039Z"
                                        />
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M11.2 7.04043H4.8V8.32043C4.8 10.0877 6.23269 11.5204 8 11.5204C9.76731 11.5204 11.2 10.0877 11.2 8.32043V7.04043ZM3.2 5.44043V8.32043C3.2 10.9714 5.34903 13.1204 8 13.1204C10.651 13.1204 12.8 10.9714 12.8 8.32043V5.44043H3.2Z"
                                        />
                                        <path d="M11.0667 3.20039C11.0667 3.64222 10.7085 4.00039 10.2667 4.00039C9.82487 4.00039 9.4667 3.64222 9.4667 3.20039C9.4667 2.75856 9.82487 2.40039 10.2667 2.40039C10.7085 2.40039 11.0667 2.75856 11.0667 3.20039Z" />
                                      </svg>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <div className="pos-name">
                              <Dropdown.Item className="cross-btn" href="">
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                                    fill="#84888C"
                                  />
                                </svg>
                              </Dropdown.Item>
                            </div>
                            <Tabs
                              id={"punchOut-tab-" + rowIndex}
                              onSelect={(selectedtab) =>
                                this.resetActiveTab(
                                  rowIndex,
                                  selectedtab,
                                  index,
                                  "Out",
                                  row
                                )
                              }
                            >
                              {row.lastOutNotes.length > 0 && (
                                <Tab
                                  eventKey={"details" + rowIndex + index}
                                  title="Details"
                                >
                                  <div className="section">
                                    <div className="pading">
                                      <div className="d-flex start">
                                        <div className="profile-img">
                                          <img
                                            src={
                                              row.lastOutImage === null
                                                ? profile
                                                : row.lastOutImage.trim() === ""
                                                  ? profile
                                                  : row.lastOutImage
                                            }
                                            className={
                                              row.lastOutImage === null
                                                ? "default"
                                                : row.lastOutImage.trim() === ""
                                                  ? "default"
                                                  : ""
                                            }
                                            width="40"
                                            height="40"
                                            alt={row.lastOutUsername}
                                            onError={(e) =>
                                              (e.currentTarget.src = profile)
                                            }
                                          />
                                        </div>
                                        <div className="details">
                                          <div className="name">
                                            {row.lastOutUsername}
                                          </div>
                                          <div className="date">
                                            {row.lastOutDate}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="notes">
                                        {row.lastOutNotes}
                                      </div>
                                      <div className="time d-flex start">
                                        <div className="current-status">
                                          <div className="ttl">Original</div>
                                          <div className="time">
                                            {row.firstPunchoutTime}
                                          </div>
                                        </div>
                                        <div className="last-status">
                                          <div className="ttl">Updated</div>
                                          <div className="time">
                                            {this.formatOutDate(row.outTime)}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {row.outtimeDispute !== "" && (
                                      <div className="related-link">
                                        <div className="title">Related</div>
                                        <ul>
                                          <li>
                                            <span
                                              onClick={this.handleComparisonClick.bind(
                                                this,
                                                1002,
                                                "Dispute"
                                              )}
                                            >
                                              Punch Out Dispute
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    )}
                                  </div>
                                </Tab>
                              )}
                              {this.isTabShow(row) && row.opens !== "Open" && (
                                <Tab
                                  eventKey={"edit-punch" + rowIndex + index}
                                  title={
                                    row.entryType !== "TimeOff"
                                      ? "Edit Punch"
                                      : "Edit Time Off"
                                  }
                                >
                                  <div className="section">
                                    <div className="pading">
                                      <div className="list-wrapper d-flex justify-content-between">
                                        <div className="list half-width">
                                          <div className="ttl">Punch Out</div>
                                          <div className="control">
                                            <input
                                              type="time"
                                              id="outTimedupappt"
                                              name="outTimedupappt"
                                              className="html-time-picker"
                                              value={row.outTimedup}
                                              onChange={(e: any) =>
                                                this.outtimeChange(
                                                  e,
                                                  row,
                                                  rowIndex,
                                                  index
                                                )
                                              }
                                              onBlur={(e: any) =>
                                                this.outtimeBlur(
                                                  e,
                                                  row,
                                                  rowIndex,
                                                  index
                                                )
                                              }
                                            />
                                          </div>
                                        </div>

                                        <div className="list half-width">
                                          <div className="ttl">Date</div>
                                          <Form.Group controlId="date-selection">
                                            <Form.Control
                                              disabled
                                              type="text"
                                              value={row.ddlOutDate}
                                            ></Form.Control>
                                          </Form.Group>
                                        </div>
                                      </div>

                                      <div className="list">
                                        <div className="ttl">Notes</div>
                                        <div className="control">
                                          <Form.Control
                                            className="input-notes"
                                            as="textarea"
                                            value={row.lastOutNotesdup}
                                            placeholder="Explanation required..."
                                            onChange={(e: any) =>
                                              this.noteOutChange(
                                                e,
                                                row,
                                                rowIndex,
                                                index
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="footer d-flex justify-content-end">
                                      <button
                                        type="button"
                                        ref={this.btnhHandleSave}
                                        className="btn btn-primary"
                                        onClick={(e: any) =>
                                          this.handleSave(e, row, rowIndex, "Out", index,"Yes","Out")
                                        }
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </Tab>
                              )}
                              {row.outImage.length > 0 && (
                                <Tab
                                  eventKey={"smile-id" + rowIndex + index}
                                  title="Smile iD"
                                >
                                  <div className="section">
                                    <div className="pading edit-pad">
                                      <div className="currentimg">
                                        <MyImage
                                          src={row.outImage}
                                          fallbackSrc={profile}
                                          alt="User SmileId"
                                          id={row.outUniqueno}
                                        ></MyImage>
                                        {row.outImageStatus === "Pending" && (
                                          <div className="badges red-badges orange-bg">
                                            {row.outImageLevel} %
                                          </div>
                                        )}
                                        {row.outImageStatus === "Pass" && (
                                          <div className="badges red-badges green-bg">
                                            {row.outImageLevel} %
                                          </div>
                                        )}
                                        {row.outImageStatus === "Fail" && (
                                          <div className="badges red-badges red-badges">
                                            {row.outImageLevel} %
                                          </div>
                                        )}
                                      </div>
                                      <div className="profile-details">
                                        {row.outImageStatus === "Pending" && (
                                          <div className="q-details">
                                            Is this {row.employee}?
                                          </div>
                                        )}
                                        {row.outImageStatus !== "Pending" && (
                                          <div className="q-details">
                                            {row.employee}
                                          </div>
                                        )}
                                        <div className="punch-details">
                                          Punched out at {row.outImageTime} on{" "}
                                          {row.outDate}
                                        </div>
                                      </div>
                                    </div>
                                    {row.outImageStatus === "Pending" &&
                                      row.managesmileid.toLowerCase() ===
                                      "yes" && (
                                        <div className="footer d-flex justify-content-end">
                                          <button
                                            type="button"
                                            className="btn-danger btn btn-primary"
                                            onClick={(e: any) =>
                                              this.updateSmileIdStatus(
                                                "No",
                                                row,
                                                2
                                              )
                                            }
                                          >
                                            No
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={(e: any) =>
                                              this.updateSmileIdStatus(
                                                "Yes",
                                                row,
                                                2
                                              )
                                            }
                                          >
                                            Yes
                                          </button>
                                        </div>
                                      )}
                                  </div>
                                </Tab>
                              )}
                            </Tabs>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </>
                  )}

                {row.lastOutNotes.length === 0 &&
                  row.outImage.length === 0 &&
                  row.opens !== "Open" && (
                    <div className="rm-bold margin-top">
                      {this.formatDate(row.outTime)}
                    </div>
                  )}
              </div>
            )}
        </>
      ),
      footer: "",
    },
    {
      dataField: "breakTime",
      text: "Break",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <>
          {row.entryType.toLowerCase() === "break" && (
            <div className="d-flex align-items-center">
              {row.maxBreakcount > 1 && (
                <div className="dot margin-right"></div>
              )}
              {(row.isedit.toString().toLowerCase() === "yes" ||
                row.lastBreakNotes.length > 0) && (
                  <Dropdown className="more-action grey" alignRight>
                    <Dropdown.Toggle
                      className="btn-outline-primary inner-sec-btn more pos-abs"
                      id="dropdown-more"
                    >
                      <div
                        className="d-flex align-items-center"
                        onClick={() =>
                          this.resetPopupData(row, rowIndex, "Break", index)
                        }
                      >
                        {row.breakTime}
                        {row.breakTime?.includes("-") && (
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip id={"tooltip-Assign"}>
                                A negative break indicates that time has been
                                added to an employee's payable hours. Entering a
                                positive break deducts time from the total HH:MM
                                paid.
                              </Tooltip>
                            }
                          >
                            <span style={{ paddingLeft: 5 }}>
                              <InfoTooltip />{" "}
                            </span>
                          </OverlayTrigger>
                        )}
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className="pos-name">
                        <Dropdown.Item className="cross-btn" href="">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                              fill="#84888C"
                            />
                          </svg>
                        </Dropdown.Item>
                      </div>
                      <Tabs
                        id={"punchBreak-tab-" + rowIndex}
                        onSelect={(selectedtab) =>
                          this.resetActiveTab(
                            rowIndex,
                            selectedtab,
                            index,
                            "Break",
                            row
                          )
                        }
                      >
                        {row.lastBreakNotes.length > 0 && (
                          <Tab
                            eventKey={"details" + rowIndex + index}
                            title="Details"
                          >
                            <div className="section">
                              <div className="pading">
                                <div className="d-flex start">
                                  <div className="profile-img">
                                    <img
                                      src={
                                        row.breakUserImage === null
                                          ? profile
                                          : row.breakUserImage.trim() === ""
                                            ? profile
                                            : row.breakUserImage
                                      }
                                      className={
                                        row.breakUserImage === null
                                          ? "default"
                                          : row.breakUserImage.trim() === ""
                                            ? "default"
                                            : ""
                                      }
                                      width="40"
                                      height="40"
                                      alt={row.lastBreakUser}
                                      onError={(e) =>
                                        (e.currentTarget.src = profile)
                                      }
                                    />
                                  </div>
                                  <div className="details">
                                    <div className="name">
                                      {row.lastBreakUser}
                                    </div>
                                    <div className="date">
                                      {row.lastBreakDate}
                                    </div>
                                  </div>
                                </div>
                                <div className="notes">{row.lastBreakNotes}</div>
                                <div className="time d-flex start">
                                  <div className="current-status">
                                    <div className="ttl">Original</div>
                                    <div className="time">
                                      {row.firstBreakHrs
                                        .toString()
                                        .replace(".", ":")}
                                    </div>
                                  </div>
                                  <div className="last-status">
                                    <div className="ttl">Updated</div>
                                    <div className="time">
                                      {row.breakTime.toString().replace(".", ":")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab>
                        )}
                        {row.isedit.toString().toLowerCase() === "yes" && (
                          <Tab
                            eventKey={"edit-punch" + rowIndex + index}
                            title="Edit Break"
                          >
                            <div className="section">
                              <div className="pading">
                                <div className="list">
                                  <div className="ttl">Break Duration</div>
                                  <div className="d-flex modf-input justify-content-start align-items-center">
                                    <Form.Control
                                      type="text"
                                      pattern="\d*"
                                      maxLength={2}
                                      placeholder="HH"
                                      id="HH"
                                      value={row.breakHH}
                                      onBlur={(e: any) =>
                                        this.blurbreakHH(e, row, rowIndex, index)
                                      }
                                      onChange={(e: any) =>
                                        this.changebreakHH(
                                          e,
                                          row,
                                          rowIndex,
                                          index
                                        )
                                      }
                                    />
                                    <span>:</span>
                                    <Form.Control
                                      type="text"
                                      pattern="\d*"
                                      maxLength={2}
                                      placeholder="MM"
                                      id="MM"
                                      value={row.breakMM}
                                      onBlur={(e: any) =>
                                        this.blurbreakMM(e, row, rowIndex, index)
                                      }
                                      onChange={(e: any) =>
                                        this.changebreakMM(
                                          e,
                                          row,
                                          rowIndex,
                                          index
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="list">
                                  <div className="ttl">Break Details</div>
                                  <div className="control">
                                    <Form.Control
                                      className="input-notes"
                                      as="textarea"
                                      value={row.lastOutNotesdup}
                                      placeholder="Explanation required..."
                                      onChange={(e: any) =>
                                        this.noteOutChange(
                                          e,
                                          row,
                                          rowIndex,
                                          index
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="footer d-flex justify-content-end">
                                {row.isedit.toString().toLowerCase() ===
                                  "yes" && (
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      ref={this.btnUpdateBreak}
                                      onClick={(e: any) =>
                                        this.updateBreak(e, row, rowIndex, index)
                                      }
                                    >
                                      Save
                                    </button>
                                  )}
                              </div>
                            </div>
                          </Tab>
                        )}
                      </Tabs>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              {row.isedit.toString().toLowerCase() !== "yes" &&
                row.lastBreakNotes.length === 0 && (
                  <div className="rm-bold margin-top">
                    {row.breakTime}
                    {row.breakTime?.includes("-") && (
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id={"tooltip-Assign"}>
                            Negative break indicates that time have been added
                            to an employee's payable hours.
                          </Tooltip>
                        }
                      >
                        <span style={{ paddingLeft: 5 }}>
                          <InfoTooltip />{" "}
                        </span>
                      </OverlayTrigger>
                    )}
                  </div>
                )}
            </div>
          )}
          {row.entryType.toLowerCase() !== "break" && (
            <div className="rm-bold">{row.breakTime}</div>
          )}
        </>
      ),
      footer: (columnData: any) => {
        let totalBreak = 0;
        for (let i = 0; i < columnData.length; i++) {
          totalBreak =
            totalBreak +
            schConversions.convertHourToMInute(
              columnData[i] === "" ? "0:00" : columnData[i]
            );
        }
        return schConversions.convertMinuteToHour(totalBreak);
      },
    },
    {
      dataField: "total",
      text: "Total",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div className="total-row-value">
          {row.entryType.toLowerCase() !== "break" && <>{row.total}</>}
        </div>
      ),
    
      footer: (columnData: any) => {
        let rowScheduleNo = openRow[index].scheduleNo;
        let totalActual = 0;
        let totalSchedule = 0;
        let timesheetData = this.state.dayDetails[index].timesheet;

        for (let i = 0; i < timesheetData.length; i++) {
          if (timesheetData[i].scheduleNo === rowScheduleNo) {
            if (
              timesheetData[i].entryType === "Break" &&
              timesheetData[i].noShow !== 1 &&
              timesheetData[i].inDate !== "" &&
              timesheetData[i].outDate !== "" &&
              timesheetData[i].opens !== "Open"
            ) {
              let breakTimeInMinutes = schConversions.convertHourToMInute(
                timesheetData[i].breakTime
              );
              totalActual = totalActual - breakTimeInMinutes;
            } else {
              if (
                timesheetData[i].noShow !== 1 &&
                timesheetData[i].inDate !== "" &&
                timesheetData[i].outDate !== "" &&
                timesheetData[i].opens !== "Open"
              ) {
                let actualInTime = moment(
                  `${timesheetData[i].inDate} ${timesheetData[i].inTime}`
                );
                let actualOutTime = moment(
                  `${timesheetData[i].outDate} ${timesheetData[i].outTime}`
                );
                let diffInActual = actualOutTime.diff(actualInTime, "minutes");
                totalActual = totalActual + diffInActual;
              }

              let scheduleInTime = moment(
                `${timesheetData[i].scheduleInDate} ${timesheetData[i].scheduleInTime}`
              );
              let scheduleOutTime = moment(
                `${timesheetData[i].scheduleOutDate} ${timesheetData[i].scheduleOutTime}`
              );

              let diffInSchedue = scheduleOutTime.diff(
                scheduleInTime,
                "minutes"
              );
              if (totalSchedule === 0) {
                totalSchedule = totalSchedule + diffInSchedue;
              }
            }
          }
        }
        return (
          <div className="total-wrapper">
            <div className="total-actual">
              {schConversions.convertMinuteToHour(totalActual)}
            </div>
          </div>
        );
      },
    },
    // },
    {
      dataField: "position",
      text: "Position",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <EllipsisWithTooltip placement="top">
          <div className="row-position">{row.position}</div>
        </EllipsisWithTooltip>
      ),
      footer: "",
    },
    {
      dataField: "",
      text: "",
      footer: "",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div className="d-flex flex-wrap">
          {row.apo.length > 0 && <div className="red-badges">{row.apo}</div>}
          {row.manuals.length > 0 && (
            <div className="red-badges blue-bg">{row.manuals}</div>
          )}
          {row.opens !== "" && row.opens !== null && (
            <div className="red-badges">{row.opens}</div>
          )}
          {row.otf !== "" && row.otf !== null && (
            <div className="red-badges blue-bg">{row.otf}</div>
          )}
        </div>
      ),
    },
    {
      dataField: "",
      text: "",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div className="d-flex">
          {row.laborActualCommentRes.length > 0 && (
            <OutsideClickHandler onOutsideClick={(e: any) => this.chieldShowOutSideClick(row.ddlChieldommentRef)}>
              <div id="child-table-comment-dropdown"
                onClick={(e: any) => e.stopPropagation()}
              >
                <Dropdown className="more-action notes-btn" alignRight
                  onMouseLeave={this.hideDropdown}
                >
                  <Dropdown.Toggle
                    className="btn-outline-primary  more"
                    id="dropdown-more"

                    ref={row.ddlChieldommentRef}
                  >
                    <div className="d-flex">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M13.5556 2.4H11.3889V1H9.94444V2.4H7.05556V1H5.61111V2.4H3.44444C2.64783 2.4 2 3.0279 2 3.8V13.6C2 14.3721 2.64783 15 3.44444 15H13.5556C14.3522 15 15 14.3721 15 13.6V3.8C15 3.0279 14.3522 2.4 13.5556 2.4ZM3.44444 13.6V4.5H5.61111H7.05556H9.94444H11.3889H13.5556V3.8L13.557 13.6H3.44444Z" />
                        <path d="M4.6665 6H11.3332V7.33333H4.6665V6ZM4.6665 8.66667H7.99984V10H4.6665V8.66667Z" />
                      </svg>
                      <span>{row.laborActualCommentRes.length}</span>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu renderOnMount>
                    <div className="notes-sec">
                      <div className="notes-list">
                        {row.laborActualCommentRes.map((item: any, idx: any) => (
                          <div className="comment-list">
                            <div className="d-flex profile-details">
                              <div className="profile-img">
                                <img
                                  src={
                                    item.imagepath === null
                                      ? profile
                                      : item.imagepath.trim() === ""
                                        ? profile
                                        : item.imagepath
                                  }
                                  className={
                                    item.imagepath === null
                                      ? "default"
                                      : item.imagepath.trim() === ""
                                        ? "default"
                                        : ""
                                  }
                                  width="40"
                                  height="40"
                                  alt={item.username}
                                  onError={(e) => (e.currentTarget.src = profile)}
                                />
                              </div>
                              <div>
                                <div className="main-ttl">{item.username}</div>
                                <div className="sub-ttl">
                                  {Utils.getDateTimeMddyyFromUtc(item.commentdate)}
                                </div>
                              </div>
                            </div>
                            <ul>
                              <li>
                                <div className="d-flex">
                                  <div className="detail-sec">
                                    <div className="comments">{item.comments}</div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </OutsideClickHandler>
          )}
        </div>
      ),
      footer: "",
    },
    {
      dataField: "",
      text: "",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <>
          {row.isActualentry !== "No" && row.opens !== "Open" && (
            <div className="d-flex justify-content-end">
              <div className="custom-split">
                {this.isTabShow(row) && row.entryType !== "Break" && (
                  <Dropdown
                    className={
                      row.actions === "split" ||
                        row.actions === "change" ||
                        row.actions === "addBreak" ||
                        row.actions === "deleteManualshift" ||
                        row.actions === "delete"
                        ? "more-action show"
                        : "more-action"
                    }
                    alignRight
                  >
                    <Dropdown.Toggle
                      className="btn-outline-primary  more"
                      id="dropdown-more"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {row.isedit.toString().toLowerCase() === "yes" &&
                        row.entryType !== "Break" &&
                        row.manuals !== "UTO" &&
                        row.manuals !== "PTO" && (
                          <>
                            <Dropdown.Item
                              onClick={() => {
                                this.actionButton(
                                  row,
                                  rowIndex,
                                  "split",
                                  index
                                );
                              }}
                            >
                              Split{" "}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                this.actionButton(
                                  row,
                                  rowIndex,
                                  "change",
                                  index
                                );
                              }}
                            >
                              Change Position{" "}
                            </Dropdown.Item>

                            {row.shiftStatus === "Manual" && (
                              <Dropdown.Item
                                onClick={() => {
                                  this.actionButton(
                                    row,
                                    rowIndex,
                                    "deleteManualshift",
                                    index
                                  );
                                }}
                              >
                                Delete
                              </Dropdown.Item>
                            )}
                            {this.checkAllowBreak(row, rowIndex, index) && (
                              <Dropdown.Item
                                onClick={() => {
                                  this.actionButton(
                                    row,
                                    rowIndex,
                                    "addBreak",
                                    index
                                  );
                                }}
                              >
                                Add Break
                              </Dropdown.Item>
                            )}
                            <Dropdown.Item
                              onClick={() => {
                                this.actionButton(
                                  row,
                                  rowIndex,
                                  "Associate",
                                  index
                                );
                              }}
                            >
                              Associate{" "}
                            </Dropdown.Item>
                          </>
                        )}
                      {(row.manuals === "UTO" || row.manuals === "PTO") &&
                        row.managetimeoff.toLowerCase() === "yes" &&
                        row.isedit.toString().toLowerCase() === "yes" && (
                          <Dropdown.Item
                            onClick={() => {
                              this.actionButton(row, rowIndex, "delete", index);
                            }}
                          >
                            Delete
                          </Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                {row.actions === "addBreak" && (
                  <OutsideClickHandler onOutsideClick={this.handleOutsideClick}>
                    <div className="d-flex justify-content-end drop-menu">
                      <div className="add-entry ">
                        <div className="re-assign-modal">
                          <div className="">
                            <div className="title">Add Manual Break</div>
                            <div className="entry-body">
                              <Form.Group as={Row} controlId="time-picker">
                                <Col sm="6">
                                  <Form.Label>Break Duration</Form.Label>
                                  <div className="d-flex modf-input align-items-center">
                                    <Form.Control
                                      type="text"
                                      pattern="\d*"
                                      maxLength={2}
                                      placeholder="HH"
                                      id="HH"
                                      value={row.breakHH}
                                      onBlur={(e: any) =>
                                        this.breakHHBlur(
                                          e,
                                          row,
                                          rowIndex,
                                          index
                                        )
                                      }
                                      onChange={(e: any) =>
                                        this.breakHHChange(
                                          e,
                                          row,
                                          rowIndex,
                                          index
                                        )
                                      }
                                    />
                                    <span>:</span>
                                    <Form.Control
                                      type="text"
                                      pattern="\d*"
                                      maxLength={2}
                                      placeholder="MM"
                                      id="MM"
                                      value={row.breakMM}
                                      onBlur={(e: any) =>
                                        this.breakMMBlur(
                                          e,
                                          row,
                                          rowIndex,
                                          index
                                        )
                                      }
                                      onChange={(e: any) =>
                                        this.breakMMChange(
                                          e,
                                          row,
                                          rowIndex,
                                          index
                                        )
                                      }
                                    />
                                  </div>
                                </Col>
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>Break Details</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={2}
                                  placeholder="Explanation required..."
                                  value={row.breakNotes}
                                  onChange={(e: any) =>
                                    this.breakNoteChange(
                                      e,
                                      row,
                                      rowIndex,
                                      index
                                    )
                                  }
                                />
                              </Form.Group>
                            </div>
                            <div className="action-link">
                              <Button
                                className="btn btn-primary"
                                ref={this.btnSaveBreak}
                                onClick={() =>
                                  this.saveBreak(row, rowIndex, index,"Yes","AddBreak")
                                }
                              >
                                Save
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </OutsideClickHandler>
                )}
                {row.actions === "split" && (
                  <OutsideClickHandler onOutsideClick={this.handleOutsideClick}>
                    <div className="d-flex justify-content-end drop-menu">
                      <div className="add-entry ">
                        <div className="re-assign-modal">
                          <div className="">
                            <div className="title height-50 d-flex align-items-center">
                              <div className="mr-auto">Split Shift</div>
                              <Dropdown.Item
                                className="cross-btn"
                                href=""
                                onClick={() => {
                                  this.actionButton(row, rowIndex, "", index);
                                }}
                              >
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                                    fill="#84888C"
                                  />
                                </svg>
                              </Dropdown.Item>
                            </div>
                            <div className="entry-body">
                              <Form.Group as={Row} controlId="time-picker">
                                <Col sm="6">
                                  <Form.Label>Split Time</Form.Label>
                                  <input
                                    type="time"
                                    id="appt"
                                    name="appt"
                                    className="html-time-picker"
                                    onChange={(e: any) =>
                                      this.splitTimeChange(
                                        e,
                                        row,
                                        rowIndex,
                                        index
                                      )
                                    }
                                  />
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} controlId="statistic">
                                <Col sm="6">
                                  <Form.Label>Department</Form.Label>
                                  <Dropdown>
                                    <Dropdown.Toggle id="dropdown-department1">
                                      <EllipsisWithTooltip placement="bottom">
                                        {row.splitDepartment}
                                      </EllipsisWithTooltip>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {this.state.alldepartmentlist.map(
                                        (item: any, idx: any) => (
                                          <Dropdown.Item
                                            key={item.departmentName}
                                            eventKey={item.departmentName}
                                            onClick={(e: any) =>
                                              this.departmentSplitChange(
                                                e,
                                                row,
                                                item,
                                                rowIndex,
                                                index
                                              )
                                            }
                                          >
                                            {item.departmentName}
                                          </Dropdown.Item>
                                        )
                                      )}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </Col>
                                <Col sm="6">
                                  <Form.Label>Position</Form.Label>
                                  <Dropdown>
                                    <Dropdown.Toggle id="dropdown-position1">
                                      <EllipsisWithTooltip placement="bottom">
                                        {row.splitPosition}
                                      </EllipsisWithTooltip>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {this.state.allpositionlist.map(
                                        (item: any, idx: any) => (
                                          <Dropdown.Item
                                            key={item.positionName}
                                            eventKey={item.positionName}
                                            onClick={(e: any) =>
                                              this.positionSplitChange(
                                                e,
                                                row,
                                                item,
                                                rowIndex,
                                                index
                                              )
                                            }
                                          >
                                            {item.positionName}
                                          </Dropdown.Item>
                                        )
                                      )}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </Col>
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>Notes</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={2}
                                  placeholder="Explanation required..."
                                  value={row.splitNotes}
                                  id={"txtsplitnotes" + rowIndex}
                                  onChange={(e: any) =>
                                    this.noteSplitChange(
                                      e,
                                      row,
                                      rowIndex,
                                      index
                                    )
                                  }
                                />
                              </Form.Group>
                            </div>
                            <div className="action-link">
                              <Button
                                className="btn btn-primary"
                                ref={this.btnSplitShift}
                                id={"btnsplit" + rowIndex}
                                onClick={(e: any) =>
                                  this.saveSplitEntry(row, index,"Yes","Split")
                                }
                              >
                                Split Shift
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </OutsideClickHandler>
                )}

                {row.actions === "change" && (
                  <OutsideClickHandler onOutsideClick={this.handleOutsideClick}>
                    <div className="d-flex justify-content-end drop-menu">
                      <div className="add-entry ">
                        <div className="re-assign-modal">
                          <div className="">
                            <div className="title height-50 d-flex align-items-center">
                              <div className="mr-auto">Change Position</div>
                              <Dropdown.Item
                                className="cross-btn"
                                href=""
                                onClick={(e: any) => {
                                  this.actionButton(e, row, rowIndex, index);
                                }}
                              >
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                                    fill="#84888C"
                                  />
                                </svg>
                              </Dropdown.Item>
                            </div>
                            <div className="entry-body">
                              <Form.Group as={Row} controlId="statistic">
                                <Col sm="6">
                                  <Form.Label>Department</Form.Label>
                                  <Dropdown>
                                    <Dropdown.Toggle id="dropdown-department1">
                                      <EllipsisWithTooltip placement="bottom">
                                        {row.splitDepartment}
                                      </EllipsisWithTooltip>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {this.state.alldepartmentlist.map(
                                        (item: any, idx: any) => (
                                          <Dropdown.Item
                                            key={item.departmentName}
                                            eventKey={item.departmentName}
                                            onClick={(e: any) =>
                                              this.departmentSplitChange(
                                                e,
                                                row,
                                                item,
                                                rowIndex,
                                                index
                                              )
                                            }
                                          >
                                            {item.departmentName}
                                          </Dropdown.Item>
                                        )
                                      )}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </Col>
                                <Col sm="6">
                                  <Form.Label>Position</Form.Label>
                                  <Dropdown>
                                    <Dropdown.Toggle id="dropdown-position2">
                                      <EllipsisWithTooltip placement="bottom">
                                        {row.splitPosition}
                                      </EllipsisWithTooltip>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {this.state.allpositionlist.map(
                                        (item: any, idx: any) => (
                                          <Dropdown.Item
                                            key={item.positionName}
                                            eventKey={item.positionName}
                                            onClick={(e: any) =>
                                              this.positionSplitChange(
                                                e,
                                                row,
                                                item,
                                                rowIndex,
                                                index
                                              )
                                            }
                                          >
                                            {item.positionName}
                                          </Dropdown.Item>
                                        )
                                      )}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </Col>
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>Notes</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={2}
                                  placeholder="Explanation required..."
                                  value={row.splitNotes}
                                  id={"txtchangenotes" + rowIndex}
                                  onChange={(e: any) =>
                                    this.noteSplitChange(
                                      e,
                                      row,
                                      rowIndex,
                                      index
                                    )
                                  }
                                />
                              </Form.Group>
                            </div>
                            <div className="action-link">
                              <Button
                                className="btn btn-primary"
                                ref={this.btnChangeShift}
                                id={"btnchange" + rowIndex}
                                onClick={(e: any) =>
                                  this.saveChangePosition(row, index,"Yes","ChangePosition")
                                }
                              >
                                Submit
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </OutsideClickHandler>
                )}

                {row.actions === "Associate" && (
                  <OutsideClickHandler onOutsideClick={this.handleOutsideClick}>
                    <div className="d-flex justify-content-end drop-menu">
                      <div className="add-entry ">
                        <div className="re-assign-modal">
                          <div className="">
                            <div className="title height-50 d-flex align-items-center">
                              <div className="mr-auto">Associate Day</div>
                              <Dropdown.Item
                                className="cross-btn"
                                href=""
                                onClick={(e: any) => {
                                  this.actionButton(e, row, rowIndex, index);
                                }}
                              >
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                                    fill="#84888C"
                                  />
                                </svg>
                              </Dropdown.Item>
                            </div>
                            <div className="entry-body">
                              <Form.Group as={Row} controlId="statistic">
                                <Col sm="6">
                                  <Dropdown>
                                    <Dropdown.Toggle id="dropdown-department1">
                                      <EllipsisWithTooltip placement="bottom">
                                        {row.assocaiteedDate}
                                      </EllipsisWithTooltip>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {this.getAssociateDateList(row).map(
                                        (item: any, idx: any) => (
                                          <Dropdown.Item
                                            key={item}
                                            eventKey={item}
                                            onClick={(e: any) =>
                                              this.assocaiteDateChange(
                                                row,
                                                item,
                                                index
                                              )
                                            }
                                          >
                                            {item}
                                          </Dropdown.Item>
                                        )
                                      )}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </Col>
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>Notes</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={2}
                                  placeholder="Explanation required..."
                                  value={row.assocaiteedDateNotes}
                                  id={"txtchangenotes" + rowIndex}
                                  onChange={(e: any) =>
                                    this.assocaitedNotesChange(e, row, index)
                                  }
                                />
                              </Form.Group>
                            </div>
                            <div className="action-link">
                              <Button
                                className="btn btn-primary"
                                ref={this.btnAssociateSave}
                                id={"btnchange" + rowIndex}
                                onClick={() =>
                                  this.saveAssocatedDateChange(row, index,"Yes","Associate")
                                }
                              >
                                Save
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </OutsideClickHandler>
                )}

                {row.actions === "deleteManualshift" &&
                  this.formatColumnManualDelete(cell, row, rowIndex, index)}

                {row.isedit === "Yes" && row.entryType === "Break" && (
                  <Dropdown className="more-action" alignRight>
                    <Dropdown.Toggle
                      className="btn-outline-primary  more"
                      id="dropdown-more"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          this.deleteBreak(row,"Yes","DeleteBreak");
                        }}
                      >
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                {row.actions === "delete" &&
                  this.formatColumn(cell, row, rowIndex)}
              </div>
            </div>
          )}
        </>
      ),
      footer: (columnData: any[]) => { },
      footerFormatter: (column: any, colIndex: any) => {
        let showMoonIcon = this.getMoonIcon(
          this.state.dayDetails[index].timesheet[0].date,
          this.state.addEntry.newInTime,
          this.state.addEntry.newOutTime
        );
        return (
          <>
            {this.isActualExist(index)}
            <div className="d-flex justify-content-end">
              {openRow[index].manuals === "PTO" || openRow[index].manuals === "UTO" ? (
                <></>
              ) : (
                <Dropdown
                  className="more-action add-entry"
                  alignRight
                  id="add-entry-ddn"
                >
                  <Dropdown.Toggle
                    className="btn-outline-primary inner-sec-btn more"
                    id="dropdown-more"
                    ref={this.state.dayDetails[index].refAddEntry}
                    
                  >
                    {this.state.dayDetails[index].timesheet[0].isedit
                      .toString()
                      .toLowerCase() === "yes" && (
                        <div  onClick={(e: any) => this.handleAddEntry(e, index)}>
                          Add Entry
                        </div>
                      )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="pos-name">
                      <Dropdown.Item className="cross-btn" href="">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                            fill="#84888C"
                          />
                        </svg>
                      </Dropdown.Item>
                    </div>
                    <div className="re-assign-modal">
                      <div className="">
                        <div className="title">Add Entry</div>
                        <div className="entry-body">
                          {showMoonIcon && (
                            <span className="moon-icon">
                              <MoonIcon parentComponent={"Timehsheet"} />
                            </span>
                          )}
                          <Form.Group as={Row} controlId="time-picker">
                            <Col sm={showMoonIcon ? "6" : "6"}>
                              <Form.Label>Punch In</Form.Label>
                              <input
                                type="time"
                                id="newInTimeappt"
                                name="newInTimeappt"
                                className="html-time-picker"
                                value={this.state.addEntry.newInTime}
                                onChange={(e: any) => this.newInTimeChange(e)}
                              />
                            </Col>

                            <Col sm={showMoonIcon ? "6" : "6"}>
                              <Form.Label>Punch Out</Form.Label>
                              <input
                                type="time"
                                id="newOutTimeappt"
                                name="newOutTimeappt"
                                className="html-time-picker"
                                value={this.state.addEntry.newOutTime}
                                onChange={(e: any) => this.newOutTimeChange(e)}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} controlId="statistic">
                            <Col sm="6">
                              <Form.Label>Department</Form.Label>
                              <EllipsisWithTooltip>
                                <div className="disabled-department">
                                  {openRow[index].departmentName}
                                </div>
                              </EllipsisWithTooltip>
                            </Col>
                            <Col sm="6">
                              <Form.Label>Position</Form.Label>
                              <EllipsisWithTooltip>
                                <div className="disabled-position">
                                  {openRow[index].positionName}
                                </div>
                              </EllipsisWithTooltip>
                            </Col>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Notes</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={2}
                              placeholder="Explanation required..."
                              value={this.state.addEntry.newNotes}
                              onChange={(e: any) =>
                                this.addNewEntryNotesChange(e)
                              }
                            />
                          </Form.Group>
                        </div>
                        <div className="action-link">
                          <Button
                            className="btn btn-primary"
                            ref={this.btnSaveNewEntry}
                            onClick={() => this.saveNewEntry(index, "addEntry","Yes","AddEntry")}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          </>
        );
      },
    },
  ];

  addShiftFooter = (index: any) => {
    const addShiftFooterData = this.state.dayDetails[index].timesheet;

    let showMoonIcon = this.getMoonIcon(
      addShiftFooterData[0].date,
      this.state.addEntry.newInTime,
      this.state.addEntry.newOutTime
    );

    return (

      <Dropdown
        className="more-action add-entry text-center"
        alignRight
        id="add-shift-ddn"
      >
        {addShiftFooterData.length > 0 &&
          addShiftFooterData[0].isedit.toString().toLowerCase() === "yes" && (
            <Dropdown.Toggle
              className="btn-outline-primary inner-sec-btn more"
              id="dropdown-more"
              ref={this.state.dayDetails[index].refAddShift}
            >
              <div onClick={(e: any) => this.handleAddEntry(e, index, "shift")}>
                Add Shift
              </div>
            </Dropdown.Toggle>
          )}
        <Dropdown.Menu>
          <div className="pos-name">
            <Dropdown.Item className="cross-btn" href="">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                  fill="#84888C"
                />
              </svg>
            </Dropdown.Item>
          </div>
          <div className="re-assign-modal">
            <div className="">
              <div className="title">Add Shift</div>
              <div className="entry-body">
                {showMoonIcon && (
                  <span className="moon-icon">
                    <MoonIcon parentComponent={"Timehsheet"} />
                  </span>
                )}
                <Form.Group as={Row} controlId="time-picker">
                  <Col sm={showMoonIcon ? "6" : "6"}>
                    <Form.Label>Punch In</Form.Label>
                    <input
                      type="time"
                      id="newInTimeappt"
                      name="newInTimeappt"
                      className="html-time-picker"
                      value={this.state.addEntry.newInTime}
                      onChange={(e: any) => this.newInTimeChange(e)}
                    />
                  </Col>

                  <Col sm={showMoonIcon ? "6" : "6"}>
                    <Form.Label>Punch Out</Form.Label>
                    <input
                      type="time"
                      id="newOutTimeappt"
                      name="newOutTimeappt"
                      className="html-time-picker"
                      value={this.state.addEntry.newOutTime}
                      onChange={(e: any) => this.newOutTimeChange(e)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="statistic">
                  <Col sm="6">
                    <Form.Label>Department</Form.Label>
                    <Dropdown>
                      <Dropdown.Toggle id={"ddladdentrydept" + index}>
                        <EllipsisWithTooltip placement="bottom">
                          {this.state.addEntry.newDepartment}
                        </EllipsisWithTooltip>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <ul>
                          {this.state.alldepartmentlist.map(
                            (item: any, idx: any) => (
                              <li
                                key={item.departmentName}
                                onClick={(e: any) =>
                                  this.departmentDropdownChange(e, item, index)
                                }
                              >
                                <EllipsisWithTooltip placement="bottom">
                                  {item.departmentName}
                                </EllipsisWithTooltip>
                              </li>
                            )
                          )}
                        </ul>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                  <Col sm="6">
                    <Form.Label>Position</Form.Label>
                    <Dropdown>
                      <Dropdown.Toggle id={"ddladdentryposition" + index}>
                        <EllipsisWithTooltip placement="bottom">
                          {this.state.addEntry.newPosition}
                        </EllipsisWithTooltip>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <ul>
                          {this.state.allpositionlist.map(
                            (item: any, idx: any) => (
                              <li
                                key={item.positionName}
                                onClick={(e: any) =>
                                  this.positionDropdownChange(e, item, index)
                                }
                              >
                                <EllipsisWithTooltip placement="bottom">
                                  {item.positionName}
                                </EllipsisWithTooltip>
                              </li>
                            )
                          )}
                        </ul>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Notes</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    placeholder="Explanation required..."
                    value={this.state.addEntry.newNotes}
                    onChange={(e: any) => this.addNewEntryNotesChange(e)}
                  />
                </Form.Group>
              </div>
              <div className="action-link">
                <Button
                  className="btn btn-primary"
                  ref={this.btnSaveNewEntry}
                  onClick={(e: any) => this.saveNewEntry(index, "addShift","Yes","AddShift")}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>

    );
  };

  getMoonIcon = (date: string, inTime: string, outTime: string) => {
    const shiftDate = moment(date).format("MM/DD/YY");
    let inMomentTime = moment(`${shiftDate} ${inTime}`);
    let outMomentTime = moment(`${shiftDate} ${outTime}`);
    let differenceInTime = outMomentTime.diff(inMomentTime);

    let showMoonIcon = false;

    if (differenceInTime < 0) {
      showMoonIcon = true;
    }
    return showMoonIcon;
  };

  addDays = (date, days) => {
    date.setDate(date.getDate() + days);
    return date;
  }
  minusDays = (date, days) => {
    date.setDate(date.getDate() - days);
    return date;
  }


  saveNoShowComment = (row: any, index: any) => {
    debugger;

    if (row?.parentData[0].ddlNoShowRef.current !== null) {
      row?.parentData[0].ddlNoShowRef.current.click();
    }
    if (this.state.noShowomment !== "") {
      this.getNowShowCount(row, index, "");
    }

    
  }

  NoShowNotesChange = (e: any, row: any, index: any) => {
    let txtValue = e.target.value;
    this.setState({
      noShowomment: txtValue,
    });
  };
  noShowOutSideClick = (ref) => {
    if (ref.current?.ariaExpanded == "true") {
      ref.current.click();
    }
  }

  noShowComentOutSideClick = (ref) => {
    if (ref.current?.ariaExpanded == "true") {
      ref.current.click();
    }
  }

  ChangeLogOusidSideClick = (ref) => {
    if (ref.current?.ariaExpanded == "true") {
      ref.current.click();
    }
  }

  chieldShowOutSideClick = (ref) => {
    if (ref.current?.ariaExpanded == "true") {
      ref.current.click();
    }
  }

  isCancusativesaved = (row: any, index: any) => {
    this.SaveNoShowCommentDetails(row, index, "Yes");
  }
  ClosePoup = (row: any, index: any) => {
    this.SaveNoShowCommentDetails(row, index, "No");
    reject();
  }

  isValidateModelPoupConsecutiveDays(row: any, index: any, NumberOfNoShowcount: any) {
    confirmAlert({
      title: "Unsaved confirmation",
      message:
        "This employee has been a No Show for " + NumberOfNoShowcount + " consecutive days. Would you like to apply this comment for the additional days?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.isCancusativesaved(row, index),
        },
        {
          label: "No",
          onClick: () => this.ClosePoup(row, index),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  getNowShowCount = (row: any, index: any, Type: any) => {


    LaborPerformance.CheckNoShowComment(row?.parentData[0].employee, row?.parentData[0].date, this.state.hotelID)
      .then(async (result: any[] | null) => {
        if (result !== null) {
          debugger;
          if (result.length > 0) {
            this.setState({ noShowCountList: result });
            if (result[0].noshowCount > 1) {
              this.isValidateModelPoupConsecutiveDays(row, index, result[0].noshowCount);
            } else {
              this.SaveNoShowCommentDetails(row, index, "No");
            }
          }

        } else {
        }


        resolve();
      })
      .catch((err) => {
        //this.btnhHandleSave.current.disabled = false;
        Utils.toastError(`Server Error: ${err}`);
        reject();
      });
  }


  SaveNoShowCommentDetails = (row: any, index: any, Type: any) => {
    debugger;
    let saveNoShowComment: any[] = [];
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);

    let data = [...this.state.noShowCountList];

    if (Type === "Yes") {

      for (let i = 0; i < this.state.noShowCountList.length; i++) {
        //let nodhowDate = this.state.dayDetails[i].timesheet[0];
        //let nodhowDate = this.state.dayDetails[i];
        // for(let y =0;y< nodhowDate.timesheet.length;y++ ){       

        let newItem: any = {};
        newItem.tenantID = tenantID;
        newItem.hotelID = this.state.hotelID;
        newItem.employeeName = data[i].employee;
        newItem.selectDate = data[i].date;
        newItem.scheduleId = data[i].scheduleID;
        newItem.entryType = "TimeSheet";
        newItem.notes = this.state.noShowomment;
        saveNoShowComment.push(newItem);

        // }
      }
    }
    if (Type === "No") {
      let newItem: any = {};
      newItem.tenantID = tenantID;
      newItem.hotelID = this.state.hotelID;
      newItem.employeeName = row?.parentData[0].employee;
      newItem.selectDate = row?.parentData[0].date;
      newItem.scheduleId = row?.parentData[0].scheduleNo;
      newItem.entryType = "TimeSheet";
      newItem.notes = this.state.noShowomment;
      saveNoShowComment.push(newItem);

     

    }




    LaborPerformance.SaveLaborNoShowComments(saveNoShowComment)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.result.messageCode === "Success") {
            $(".txtNoShowNotes").val('');
            this.setState({ isDataChange: false, noShowomment: "" });
            this.loadLaborTimesheetDetails();
            this.loadTimesheetDateWise("updateActualDetails");
            this.props.slideOutRefresh();
            this.hideExportedDate();

          } else {
            Utils.toastError(result.result.message);
          }
        } else {
        }

        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error: ${err}`);
        reject();
      });

  }

  AddManualShift = (index, callType) => {
    this.setState({ isRemoveDNS: "Yes" }, () => {
      this.saveNewEntry(index, callType,"No","AddShift")
    });
  }

  AddManualShiftbyUpdate = (e, row, rowIndex, punchtype, index) => {
    this.setState({ isRemoveDNS: "Yes" }, () => {
      this.handleSave(e, row, rowIndex, punchtype, index,"Yes","");
    });
  }

  DNSConfirmation(e, index, callType) {
    confirmAlert({
      message:
        // `Adding a manual shift will remove the DNS shift for ${e}. Are you sure you want to continue?`,
        `Adding a manual shift will remove the DNS shift for this employee. Are you sure you want to continue?`,
      buttons: [
        {
          label: "No",
          onClick: () => reject(),
        },
        {
          label: "Yes",
          onClick: () => this.AddManualShift(index, callType),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  DNSConfirmationforUpdate(e, row, rowIndex, punchtype, index) {
    confirmAlert({
      message:
        `Adding a manual shift will remove the DNS shift for this employee. Are you sure you want to continue?`,
      // `Adding a manual shift will remove the DNS shift for ${e.employeeName}. Are you sure you want to continue?`,
      buttons: [
        {
          label: "No",
          onClick: () => reject(),
        },
        {
          label: "Yes",
          onClick: () => this.AddManualShiftbyUpdate(e, row, rowIndex, punchtype, index),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  NoClick(index:any,type:any) {    debugger;
   
    this.setState({payrollExportPopup: false});
    if(type==="AddEntry"){
     
      setTimeout(() => {
        this.state.dayDetails[index].refAddEntry.current.click();
      }, 0);
    }
    if(type==="AddShift"){
      setTimeout(() => {
        this.state.dayDetails[index].refAddShift.current.click();
      }, 0);
    }
    if(type==="In"){
    setTimeout(() => {
      this.refIn.current.click();
    }, 0);
  }
  if(type==="Out"){
    setTimeout(() => {
      this.refOut.current.click();
    }, 0);
  }
   
  }

  ConfirmPayrollExport(e: any, row: any, rowIndex: any, punchtype: string, index: any,confirmType:any,type:any,callType:any) {
    confirmAlert({
     // title: "Unsaved confirmation",
      message:
        "Payroll for this period has already been exported. Continuing will require payroll to be re-exported to prevent any disruptions. Would you like to continue?",
      buttons: [
        {
          label: "Yes",
          onClick: () => type==="In" ? this.handleSave(e,row,rowIndex,punchtype,index,"No",type) 
          : type==="Out" ? this.handleSave(e,row,rowIndex,punchtype,index,"No",type) 
          : type === "Split" ? this.saveSplitEntry(row,index,"No",type) 
          : type === "ChangePosition" ? this.saveChangePosition(row,index,"No",type) 
          : type === "DeleteBreak" ? this.deleteBreak(row,"No",type) 
          : type === "AddBreak" ? this.saveBreak(row,rowIndex,index,"No",type)
          : type === "Associate" ? this.saveAssocatedDateChange(row,index,"No",type)
          : type === "AddEntry" ? this.saveNewEntry(index,callType,"No",type)
          : type === "AddShift" ? this.saveNewEntry(index,callType,"No",type)
          : type === "DeleteManualBreak" ? this.IshandleManualDeleteEntry(row,rowIndex,index,"No",type) 
          : type === "DeletePTO" ? this.ishandleDeletePosition(row,true,"No")
          : <></>
        },
        {
          label: "No",
          onClick: () => this.NoClick(index,type),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  ClickCancel(index:any,type:any) {    debugger;
   
    this.setState({netchexWarningPopup: false});
    if(type==="AddEntry"){
     
      setTimeout(() => {
        this.state.dayDetails[index].refAddEntry.current.click();
      }, 0);
    }
    if(type==="AddShift"){
      setTimeout(() => {
        this.state.dayDetails[index].refAddShift.current.click();
      }, 0);
    }
    if(type==="In"){
    setTimeout(() => {
      this.refIn.current.click();
    }, 0);
  }
  if(type==="Out"){
    setTimeout(() => {
      this.refOut.current.click();
    }, 0);
  }
   
  }

  NetChexWarning(e: any, row: any, rowIndex: any, punchtype: string, index: any,confirmType:any,type:any,callType:any) {
    // confirmAlert({
    //   title: "Your timesheets have already been exported",
    //   message:
    //     "Time for these roles has already been exported to Netchex.If you edit any timesheets, you must 1) reapprove the timesheet and then 2) export payroll again to update Netchex to ensure your hours and payroll are in sync.",
    //   buttons: [
    //     {
    //       label: "Yes",
    //       onClick: () => type==="In" ? this.handleSave(e,row,rowIndex,punchtype,index,"No",type) 
    //       : type==="Out" ? this.handleSave(e,row,rowIndex,punchtype,index,"No",type) 
    //       : type === "Split" ? this.saveSplitEntry(row,index,"No",type) 
    //       : type === "ChangePosition" ? this.saveChangePosition(row,index,"No",type) 
    //       : type === "DeleteBreak" ? this.deleteBreak(row,"No",type) 
    //       : type === "AddBreak" ? this.saveBreak(row,rowIndex,index,"No",type)
    //       : type === "Associate" ? this.saveAssocatedDateChange(row,index,"No",type)
    //       : type === "AddEntry" ? this.saveNewEntry(index,callType,"No",type)
    //       : type === "AddShift" ? this.saveNewEntry(index,callType,"No",type)
    //       : type === "DeleteManualBreak" ? this.IshandleManualDeleteEntry(row,rowIndex,index,"No",type) 
    //       : type === "DeletePTO" ? this.ishandleDeletePosition(row,true,"No")
    //       : <></>
    //     },
    //     {
    //       label: "No",
    //       onClick: () => this.ClickCancel(index,type),
    //     },
    //   ],
    //   closeOnEscape: false,
    //   closeOnClickOutside: false,
    // });
    confirmAlert({
      customUI: ({ onClose }) => {
        this.closeModal = onClose;
        return (
          <div className='custom-ui warning-export-modal-UI'>
            <div className="wrapper">
              <div className="modal-body">
                <h1 className="modal-title">Your timesheets have already been exported</h1>
                <p>Time for these roles has already been exported to Netchex.</p>
                <p>If you edit any timesheets, you must 1) reapprove the timesheet and then 2) export payroll again to update Netchex to ensure your hours and payroll are in sync.</p>
              </div>
              <div className="modal-footer">
                <button onClick={() => {this.ClickCancel(index,type); onClose(); }}>Cancel</button>
                <button
                  onClick={ () => type==="In" ? this.handleSave(e,row,rowIndex,punchtype,index,"No",type) 
                    : type==="Out" ? this.handleSave(e,row,rowIndex,punchtype,index,"No",type) 
                    : type === "Split" ? this.saveSplitEntry(row,index,"No",type) 
                    : type === "ChangePosition" ? this.saveChangePosition(row,index,"No",type) 
                    : type === "DeleteBreak" ? this.deleteBreak(row,"No",type) 
                    : type === "AddBreak" ? this.saveBreak(row,rowIndex,index,"No",type)
                    : type === "Associate" ? this.saveAssocatedDateChange(row,index,"No",type)
                    : type === "AddEntry" ? this.saveNewEntry(index,callType,"No",type)
                    : type === "AddShift" ? this.saveNewEntry(index,callType,"No",type)
                    : type === "DeleteManualBreak" ? this.IshandleManualDeleteEntry(row,rowIndex,index,"No",type) 
                    : type === "DeletePTO" ? this.ishandleDeletePosition(row,true,"No")
                    : <></>
                  }
                >
                  Save & Continue
                </button>
              </div>
            </div>
          </div>
        );
      },
      closeOnClickOutside: false,
      closeOnEscape: false,
    });
  }


  render() {
    if (this.state.isRedirect) {
      return (
        <Redirect
          to={{
            pathname: "/labor-management/payroll-export",
            state: {
              payPeriod: this.state.payPeriodDates,
              pageTitle: "timesheet",
            },
          }}
        />
      );
    }
    let calanderDate = new Date(this.props.data.fullDate[1]);
    let todayDate: any = new Date();
    if (
      JSON.parse(localStorage.storage).currentUtcTime !== "" &&
      JSON.parse(localStorage.storage).currentUtcTime !== null &&
      JSON.parse(localStorage.storage).currentUtcTime !== undefined
    ) {
      todayDate = new Date(JSON.parse(localStorage.storage).currentUtcTime);
    }
    let lToday = todayDate;
    let weektoDate = calanderDate.setHours(0, 0, 0, 0);
    let currentDate = lToday.setHours(0, 0, 0, 0);

    const columns = [
      {
        dataField: "title",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex flex-wrap">
              <EllipsisWithTooltip placement="bottom">
                <div className="main-ttl">
                  {row.title.toLowerCase() === "regular"
                    ? "Hours"
                    : row.title.toLowerCase() === "ot"
                      ? "Overtime"
                      : row.title.toLowerCase() === "pto"
                        ? "PTO Taken"
                        : row.title.toLowerCase() === "ptopayout"
                          ? "PTO Payout"
                          : row.title}
                </div>
              </EllipsisWithTooltip>
            </div>
          );
        },
      },
      {
        dataField: "",
        text: "total",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex flex-wrap">
              {row.total !== "00:00" && (
                <div
                  className={
                    row.title.toLowerCase() === "ot"
                      ? "main-ttl color-red"
                      : "main-ttl"
                  }
                >
                  {row.total}
                </div>
              )}
              {row.total === "00:00" && <div className="main-ttl">{"-"}</div>}
            </div>
          );
        },
      },
      {
        dataField: "",
        text: "",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return <div className="vertical-line-td"></div>;
        },
      },
      {
        dataField: "",
        text: "days1",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex flex-wrap">
              {row.days1.value !== "0:00" && (
                <div
                  className={
                    row.title.toLowerCase() === "ot"
                      ? "day-wise-value color-red"
                      : "day-wise-value"
                  }
                >
                  {row.days1.value.replace(".", ":")}
                </div>
              )}
              {row.days1.value === "0:00" && (
                <div className="day-wise-value">{"-"}</div>
              )}
            </div>
          );
        },
      },
      {
        dataField: "",
        text: "days2",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex flex-wrap">
              {row.days2.value !== "0:00" && (
                <div
                  className={
                    row.title.toLowerCase() === "ot"
                      ? "day-wise-value color-red"
                      : "day-wise-value"
                  }
                >
                  {row.days2.value.replace(".", ":")}
                </div>
              )}
              {row.days2.value === "0:00" && (
                <div className="day-wise-value">{"-"}</div>
              )}
            </div>
          );
        },
      },
      {
        dataField: "",
        text: "days3",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex flex-wrap">
              {row.days3.value !== "0:00" && (
                <div
                  className={
                    row.title.toLowerCase() === "ot"
                      ? "day-wise-value color-red"
                      : "day-wise-value"
                  }
                >
                  {row.days3.value.replace(".", ":")}
                </div>
              )}
              {row.days3.value === "0:00" && (
                <div className="day-wise-value">{"-"}</div>
              )}
            </div>
          );
        },
      },
      {
        dataField: "",
        text: "days4",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex flex-wrap">
              {row.days4.value !== "0:00" && (
                <div
                  className={
                    row.title.toLowerCase() === "ot"
                      ? "day-wise-value color-red"
                      : "day-wise-value"
                  }
                >
                  {row.days4.value.replace(".", ":")}
                </div>
              )}
              {row.days4.value === "0:00" && (
                <div className="day-wise-value">{"-"}</div>
              )}
            </div>
          );
        },
      },
      {
        dataField: "",
        text: "days5",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex flex-wrap">
              {row.days5.value !== "0:00" && (
                <div
                  className={
                    row.title.toLowerCase() === "ot"
                      ? "day-wise-value color-red"
                      : "day-wise-value"
                  }
                >
                  {row.days5.value.replace(".", ":")}
                </div>
              )}
              {row.days5.value === "0:00" && (
                <div className="day-wise-value">{"-"}</div>
              )}
            </div>
          );
        },
      },
      {
        dataField: "",
        text: "days6",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex flex-wrap">
              {row.days6.value !== "0:00" && (
                <div
                  className={
                    row.title.toLowerCase() === "ot"
                      ? "day-wise-value color-red"
                      : "day-wise-value"
                  }
                >
                  {row.days6.value.replace(".", ":")}
                </div>
              )}
              {row.days6.value === "0:00" && (
                <div className="day-wise-value">{"-"}</div>
              )}
            </div>
          );
        },
      },
      {
        dataField: "",
        text: "days7",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex flex-wrap">
              {row.days7.value !== "0:00" && (
                <div
                  className={
                    row.title.toLowerCase() === "ot"
                      ? "day-wise-value color-red"
                      : "day-wise-value"
                  }
                >
                  {row.days7.value.replace(".", ":")}
                </div>
              )}
              {row.days7.value === "0:00" && (
                <div className="day-wise-value">{"-"}</div>
              )}
            </div>
          );
        },
      },
    ];

    let disputeStatus = this.props.data.disputeStatus;
    let { forseApprovalModal } = this.state;
    return (
      <div id="timeSheetSlideOut" className="pos-fxd index-1000">
        {/* <ToastContainer autoClose={3000} /> */}
        {this.state.showChangeLogModal && (
          <TimesheetLogHistory
            hid={this.state.hotelID}
            employee={this.props.data.userName}
            timeSheetStartDate={this.state.startdate}
            timeSheetEndDate={this.state.enddate}
            showChangeLogModal={this.state.showChangeLogModal}
            showHideChangeLogModal={this.showHideChangeLogModal}
            isStrictChildView={false}
            uniqueId={this.state.isSlatChangeLog ? this.state.scheduleNo : 0}
          />
        )}
        <div className="d-flex">
          <div className="back-drop" onClick={this.closeSlideOut}></div>
          <Container
            fluid
            className="body-sec performance-position timesheet-details timesheet-slideout-container justify-content-end rm-padding sos"
          >
            <div className="stickey">
              <div className="page-heading underline d-flex">
                <div className="hid-set d-flex align-items-center">
                  {this.state.hotelName}
                </div>
                <div className="mr-auto d-flex align-items-center">
                  Timesheet
                </div>
                <div className="action-group d-flex flex-row">
                  <Dropdown className="more-action" alignRight>
                    <Dropdown.Toggle
                      className="btn-outline-primary btn btn-primary more"
                      id="dropdown-more"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => this.showHideChangeLogModal()}
                      >
                        Change Log
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <button
                    type="button"
                    className="btn wht-bg cross"
                    onClick={this.closeSlideOut}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                        fill="#84888C"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            {this.state.pageLoader ? (
              <ReactPageLoader useas="timesheetSlideout" />
            ) : (
              <div className="add-padding" id="deepToTop">
                {this.state.exportedDate.length > 0 &&
                  this.state.exportedDate !== "0" && (
                    <div className="info-badges exported-date">
                      <div className="d-flex">
                        <span>This week was exported as part of the</span>
                        <div>
                          <span
                            className="d-flex"
                            onClick={() => this.openOverView()}
                          >
                            {`${this.state.payPeriodDates[0]} - ${this.state.payPeriodDates[1]} payroll`}
                          </span>
                        </div>
                        <span>on</span>{" "}
                        <div className="bold">{this.state.exportedDate}.</div>
                      </div>
                    </div>
                  )}

                <div className="overFlow-hide">
                  <div className="d-flex details-sec">
                    <div className="flex-fill align-content-center date-range-box">
                      <div className="date-range sos">
                        {weektoDate < currentDate &&
                          this.state.forceapproval.toLowerCase() === "yes" &&
                          (disputeStatus === "Waiting for Review" ||
                            disputeStatus === "Disputed") && (
                            <Dropdown className="more-action hasPrimaryBorder float-right">
                              <Dropdown.Toggle
                                className="btn-outline-primary btn btn-primary more"
                                id="dropdown-more"
                              >
                                {this.dotsSVG()}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item onClick={this.forseApprovalShow}>
                                  Force Approval
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex details-sec overFlow-scroll">
                    <div className="flex-fill align-content-center user-detail-box">
                      <div className="list">
                        <ul>
                          <li>
                            <div className="user-details d-flex">
                              <div className="profile-img">
                                <img
                                  src={
                                    this.props.data.imagePath === null
                                      ? profile
                                      : this.props.data.imagePath.trim() === ""
                                        ? profile
                                        : this.props.data.imagePath
                                  }
                                  className={
                                    this.props.data.imagePath === null
                                      ? "default"
                                      : this.props.data.imagePath.trim() === ""
                                        ? "default"
                                        : ""
                                  }
                                  width="48"
                                  height="48"
                                  alt={this.state.userName}
                                  onError={(e) =>
                                    (e.currentTarget.src = profile)
                                  }
                                />
                              </div>
                              <div className="profile-detail">
                                <div className="user-name">
                                  {this.state.userName}
                                </div>
                                <div className="position">
                                  {this.state.positionList.length}{" "}
                                  {" Positions"}
                                </div>
                              </div>
                            </div>
                          </li>
                          <li
                            className={
                              this.state.activeClass === -1 ? "active" : ""
                            }
                            key={-1}
                            onClick={this.handleComparisonClick.bind(
                              this,
                              -1,
                              "All"
                            )}
                          >
                            <div className="value">
                              {this.state.total.totlhrs}
                            </div>
                            <div className="desc">Total Hours</div>
                          </li>
                          {this.state.positionList.length > 0 && (
                            <li>
                              <div className="separator"></div>
                            </li>
                          )}
                          {this.state.positionList.map(
                            (item: any, index: any) => (
                              <li
                                key={index}
                                className={
                                  this.state.activeClass === index
                                    ? "active"
                                    : ""
                                }
                                onClick={this.handleComparisonClick.bind(
                                  this,
                                  index,
                                  item.position
                                )}
                              >
                                <div className="value">{item.value}</div>
                                <div className="desc">{item.position}</div>
                              </li>
                            )
                          )}
                          {this.state.positionList.length > 0 &&
                            this.state.dispute !== 0 && (
                              <li>
                                <div className="separator"></div>
                              </li>
                            )}
                          {this.state.dispute !== 0 && (
                            <li
                              className={
                                this.state.activeClass === 1002
                                  ? "active dispute"
                                  : "dispute"
                              }
                              key={-1}
                              onClick={this.handleComparisonClick.bind(
                                this,
                                1002,
                                "Dispute"
                              )}
                            >
                              <div className="ttl">
                                <div className="value position-relative">
                                  {this.state.dispute}
                                  {this.state.isDiputeOpen.toLowerCase() ===
                                    "yes" && <span className="dot-abs"></span>}
                                </div>
                              </div>
                              <div className="desc">Dispute</div>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {this.state.condition === "All" && (
                  <div className="scroll-area">
                    <div className="detail-view without-bdr">
                      <BootstrapTable
                        id={"timesheetdtlList"}
                        keyField="title"
                        data={this.state.data}
                        columns={columns}
                        hover
                      />
                    </div>
                  </div>
                )}
                {this.state.dayDetails.length > 0 &&
                  this.state.condition === "All" && (
                    <div className="week-list">
                      <div className="">
                        <div className="ttl">Details by Day</div>
                        <div className="detail-view">
                          {this.state.dayDetails.map(
                            (item: any, index: any) => (
                              <div
                                className="day-list"
                                id={
                                  this.state.dayDetails[index].timesheet[0]
                                    .groupID
                                }
                                style={{
                                  backgroundColor:
                                    Number(
                                      this.state.dayDetails[index].timesheet[0]
                                        .groupID
                                    ) === Number(this.state.shiftID)
                                      ? "ghostwhite"
                                      : "",
                                }}
                              >
                                <div className="day-list-ttl sos">
                                  {
                                    this.state.dayDetails[index].timesheet[0]
                                      .dayName
                                  }
                                  {this.state.dayDetails[
                                    index
                                  ].timesheet[0].lockstatus
                                    .toString()
                                    .toLowerCase() === "locked" && (
                                      <img
                                        className="ml-2"
                                        src={ForecastLock}
                                        alt="ForecastLock"
                                      />
                                    )}
                                </div>

                                <div
                                  ref={this.state.dayDetails[index].ref}
                                  className={
                                    this.state.dayDetails[index].timesheet
                                      .length === 1
                                      ? this.state.dayDetails[index]
                                        .timesheet[0].isActualentry === "No"
                                        ? "hideRow"
                                        : ""
                                      : ""
                                  }
                                >
                                  <div className="mdy-width">
                                    {this.getParentTable(index)}
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                {this.state.condition === "Adjustments" && <div></div>}
                {this.state.condition === "Dispute" && (
                  <>
                    <div className="week-list dispute">
                      <LaborTimesheetDispute
                        hotelID={this.state.hotelID}
                        userName={this.state.userName}
                        date={this.state.date}
                        managedispute={this.state.managedispute}
                        handleComparisonClick={this.handleComparisonClick}
                        alldepartmentlist={this.state.alldepartmentlist}
                        refreshTimesheetDetails={this.refreshTimesheetDetails.bind(
                          this
                        )}
                      />
                    </div>
                    <div className="week-list dispute">
                      <LaborTimesheetResolvedDispute
                        hotelID={this.state.hotelID}
                        userName={this.state.userName}
                        date={this.state.date}
                        handleComparisonClick={this.handleComparisonClick}
                        alldepartmentlist={this.state.alldepartmentlist}
                        refreshTimesheetDetails={this.refreshTimesheetDetails.bind(
                          this
                        )}
                      />
                    </div>
                  </>
                )}
              </div>
            )}

            <Modal
              className="forseApprovalModal"
              show={forseApprovalModal}
              centered
              onHide={this.forseApprovalClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Approval Comments</Modal.Title>
              </Modal.Header>
              <Form
                noValidate
                onSubmit={(e: any) => this.forseApprovalSubmit(e)}
              >
                <Modal.Body>
                  <Form.Group controlId="forseApprovalForm">
                    <Form.Control
                      as="textarea"
                      required={true}
                      rows={3}
                      value={this.state.forseApprovalValue}
                      onChange={this.forseApprovalChange}
                      placeholder="Explanation required..."
                    />
                  </Form.Group>
                  {this.state.forseApprovalError && (
                    <span className="text-danger">
                      {this.state.forseApprovalError}
                    </span>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    type="submit"
                    variant="primary"
                    onClick={(e: any) => this.forseApprovalSubmit(e)}
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </Container>
        </div>
      </div>
    );
  }
}
